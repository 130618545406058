<template>
  <div>
    <div class="tel">
      <div class="banner">
        <div class="teldesc">
          <div class="teld1">慧眼识才</div>
          <div class="teld2">智慧管理·效率倍增</div>
          <div class="teld3">为企业在创新发展中，识别人才的价值与风险</div>
        </div>
      </div>
      <!-- 我们能为你做什么？ -->
      <div class="profuile what-to-do">
        <div class="container">
          <div class="row">
            <h2 class="text-center">我们能为您做什么？</h2>
            <div class="text-desc">
              “慧眼识才”是一款AI智能助手，帮助企业提高效能、优化人才、实现高质量发展
            </div>
            <div class="elTabs">
              <el-tabs v-model="activeName" type="card" @tab-click="tabClick">
                <el-tab-pane v-for="(item, index) in zhaoPin" :key="index" :label="item.title" :name="item.title">
                  <div class="zhaopinBG"><img :src="item.img" /></div>
                  <div class="zhaopinDesc">
                    <div class="zhaoPinTitle">{{ item.title }}</div>
                    <div class="zhaopinDis" v-for="(descs, index) in item.desc" :key="index">
                      <img src="@/assets/home/check-icon.png" />
                      <div>{{ descs.title }}</div>
                    </div>
                    <div class="zhaopinDis infoR" @click="getClickLink(item.valInfoTel, 'tel')">
                      <div>查看详情</div>
                      <img class="zhaopinR" src="@/assets/zhaopinright.png" />
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>
      </div>

      <!-- 为不同行业提供更贴近的解决方案 -->
      <div class="profuile solution">
        <div class="container">
          <div class="row">
            <h2 class="text-center">为不同行业提供更贴近的解决方案</h2>
            <div class="text-desc">
              结合AI技术 | 构建交互平台 | 激发组织活力 | 人力资源数字化
            </div>
            <div class="elTabs">
              <el-tabs v-model="activeSolution" type="card" @tab-click="tabClickSolu">
                <el-tab-pane v-for="(item, index) in solution" :key="index" :label="item.title" :name="item.title">
                  <div>
                    <div class="solutionBG" :style="{
                      background: `url(${item.img}) no-repeat`,
                      'background-size': '100% 100%',
                    }">
                      <div class="title">{{ item.title }}</div>
                      <div class="soluDis">
                        <div class="soluDL" v-for="(d, indexd) in item.desc" :key="indexd">
                          {{ d.title }}
                        </div>
                      </div>
                      <div class="soluDes" v-for="(i, indexI) in item.info" :key="indexI"
                        :class="{ soluBorder: indexI === 1 }">
                        <div class="soluDt">{{ i.title }}</div>
                        <div class="soluDd">{{ i.desc }}</div>
                      </div>
                      <div class="soluF" @click="getClickLinkTel(item.valInfo, 'tel', item.title)">
                        <div>查看详情</div>
                        <img class="soluR" src="@/assets/soluright.png" />
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>
      </div>

      <!-- 可持续发展的合规风险监测服务 -->
      <div class="profuile monitor">
        <div class="container">
          <div class="row">
            <h2 class="text-center">可持续发展的合规风险监测服务</h2>
            <div class="text-desc">
              全面感知与预警，以提高洞察力，降低企业风险
            </div>
            <div class="monBg">
              <img class="po_ab" src="@/assets/home/monBg1.png" alt="" />
              <img class="po_ab2" src="@/assets/home/monBg2.png" alt="" />
              <img class="monImg" src="@/assets/home/monitor-img.png" />
              <div class="part" v-for="(item, index) in monitor" :key="index">
                <div class="bg-wrap bg4">
                  <div class="text">{{ item.title }}</div>
                  <div class="sub-text">{{ item.desc }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 为什么选择慧眼识才 -->
        <div id="shuzi" class="profuile change">
          <div class="container">
            <div class="row">
              <h2 class="text-center">为什么选择慧眼识才</h2>
              <div class="sys-text-center">
                选择慧眼识才即选择了精细化管理的模式，全面赋能实现企业增长
              </div>
              <div class="flex-8">
                <div class="flex-width">
                  <div>
                    <img src="@/assets/home/change_1.png" alt="" srcset="" />
                  </div>
                  <div class="title">中小企业优选</div>
                  <div class="change_desc1">提供价格实惠的套餐方案</div>
                  <div class="change_desc2">
                    <div>
                      先进的技术，减少了人力和时间成本.具有高度的可扩展性，选择所需模块.提供优质的服务，获得最大化回报
                    </div>
                  </div>
                  <div class="change_bg">
                    <div class="number">
                      <div id="zxNumber">100</div>
                      0+
                    </div>
                    <div>服务企业</div>
                  </div>
                </div>
                <div class="flex-width">
                  <div>
                    <img src="@/assets/home/change_2.png" alt="" srcset="" />
                  </div>
                  <div class="title">全流程价值评估</div>
                  <div class="change_desc1">贴合企业创新发展核心使用场景</div>
                  <div class="change_desc2">
                    <div>
                      Saas软件，无需下载即可使用.包括招聘、绩效、人才盘点等多种场景.帮助企业识别人才的价值
                    </div>
                  </div>
                  <div class="change_bg">
                    <div class="number">
                      <div id="qlNumber">10</div>
                      +
                    </div>
                    <div>产品模块</div>
                  </div>
                </div>
                <div class="flex-width">
                  <div>
                    <img src="@/assets/home/change_3.png" alt="" srcset="" />
                  </div>
                  <div class="title">全方位支持</div>
                  <div class="change_desc1">提供针对性的技术支持和咨询服务</div>
                  <div class="change_desc2">
                    <div>
                      7x24小时在线技术支持服务.技术专家为客户提供专业的技术支持.及时解决用户问题、优化功能建议
                    </div>
                  </div>
                  <div class="change_bg">
                    <div class="number">
                      <div id="qfNumber">89</div>
                      %
                    </div>
                    <div>客户口碑</div>
                  </div>
                </div>
                <div class="flex-width">
                  <div>
                    <img src="@/assets/home/change_4.png" alt="" srcset="" />
                  </div>
                  <div class="title">全周期服务</div>
                  <div class="change_desc1">全程专业服务，满足客户需求</div>
                  <div class="change_desc2">
                    <div>
                      软件顾问、实施、售后全流程服务.一体化专业服务，深度赋能客户.为软件使用负责，持续交付新功能
                    </div>
                  </div>
                  <div class="change_bg">
                    <div class="number">
                      <div id="timeNumber">24</div>
                      h
                    </div>
                    <div>客户服务</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 慧眼识才的成绩 -->
        <div class="profuile system achievement">
          <div class="container">
            <div class="row">
              <div class="ach">
                <div class="ach-title">慧眼识才的成绩</div>
                <div class="ach-flex">
                  <div class="flex-width">
                    <div class="flex-color">
                      <div id="counter">50</div>
                      0+
                    </div>
                    <div class="flex-text">大中型企业的选择</div>
                  </div>
                  <div class="flex-width noleft">
                    <div class="flex-color">
                      <div id="counter1">100</div>
                      000+
                    </div>
                    <div class="flex-text">累计服务人数</div>
                  </div>
                  <div class="flex-width isleft2">
                    <div class="flex-color">
                      <div id="counter2">10</div>
                      +
                    </div>
                    <div class="flex-text">多年研发积累</div>
                  </div>
                  <div class="flex-width isleft">
                    <div class="flex-color">
                      <div id="counter3">100</div>
                      +
                    </div>
                    <div class="flex-text">相关知识产权</div>
                  </div>
                  <div class="flex-width">
                    <div class="flex-color">
                      <div id="counter4">26</div>
                      +
                    </div>
                    <div class="flex-text">关键行业场景覆盖</div>
                  </div>
                  <div class="flex-width noleft">
                    <div class="flex-color">人工智能</div>
                    <div class="flex-text">创新变革的数字化技术</div>
                  </div>
                  <div class="flex-width isleft2">
                    <div class="flex-color">专业团队</div>
                    <div class="flex-text">团队成员深耕技术研发</div>
                  </div>
                  <div class="flex-width isleft">
                    <div class="flex-color">
                      <div id="counter5">8</div>
                      +
                    </div>
                    <div class="flex-text">多年资深服务经验</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="need">
              <div class="ach-title">他们都在使用</div>
              <div class="pcNeed">
                <img src="@/assets/home/ach_need.png" alt="" />
              </div>
              <div class="telNeed">
                <img v-for="(item, index) in needImg" :key="index" :src="item.tp" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- pc-------------------- -->
    <div class="pc">
      <el-carousel height="550px">
        <el-carousel-item>
          <div class="fbwh banner_po banner-old">
            <div class="container">
              <img src="@/assets/home/banner_img1_03.png" alt="" />
              <div class="banner-content--old">
                <div class="new-title">
                  <span class="title-name">慧眼识才</span>
                  <div class="title-border"></div>
                  <span class="title-tips">智慧管理 · 效率倍增</span>
                </div>
                <div class="new-litle">为企业在创新发展中，识别人才的价值与风险</div>
                <button class="carousel-free" @click="isDialog('indexBannerFree', 'pc')" id="show2">申请试用</button>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="fbwh banner_po">
            <img src="@/assets/home/newBanner.png" alt="" />
            <div class="banner-content--new container">
              <div class="new-title">慧眼识才v4.0</div>
              <div class="new-dec">新版本发布</div>
              <div class="new-litle">慧眼识才打造卓越团队</div>
              <button class="carousel-free" @click="isDialog('indexBannerFree', 'pc')" id="show2">
                申请试用
              </button>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="carousel banner" data-ride="carousel" v-if="false">
        <div class="banner-content">
          <div class="banner-content--new container">
            <div class="new-title">慧眼识才v4.0</div>
            <div class="new-dec">新版本发布</div>
            <div class="new-litle">慧眼识才打造卓越团队</div>
            <button class="carousel-free" @click="isDialog('indexBannerFree', 'pc')" id="show2">
              申请试用
            </button>
          </div>
          <div class="fbwh ">
            <img src="@/assets/home/newBanner.png" alt="..." />
          </div>
          <!-- <div class="banner-img-wrap">
                <img class="banner-img" src="@/assets/home/banner_img1_03.png" alt="..." />
              </div> -->
          <!-- <div class="carousel-caption">
            <div class="carousel-title">
              <div class="title">慧眼识才</div>
              <div class="sub-title">看见人才的真正价值</div>
            </div>
            <div class="carousel-desc">
              为企业在创新发展中，识别人才的价值与风险
            </div>
            <button class="carousel-free" @click="isDialog('indexBannerFree', 'pc')" id="show2">
              免费试用
            </button>
          </div> -->
        </div>
      </div>
      <!-- 我们能为你做什么？ -->
      <div class="profuile what-to-do">
        <div class="container">
          <div class="row">
            <h2 class="text-center">我们能为您做什么？</h2>
            <div class="text-desc">
              “慧眼识才”是一款AI智能助手，帮助企业提高效能、优化人才、实现高质量发展
            </div>
            <div class="flex-2">
              <div class="left-menu">
                <template v-for="(itemPc, idx) in zhaoPin">
                  <div class="flexSlot" :class="{ 'is-active': itemPc.title === activeNamePc }" :key="idx">
                    <img :src="itemPc.title === activeNamePc ? itemPc.isIcon : itemPc.icon" />
                    <div @mouseenter="getClick(itemPc, 'pc', 'PC')">
                      {{ itemPc.title }}
                    </div>
                  </div>
                </template>
              </div>
              <template v-for="(itemPc, index) in zhaoPin">
                <div class="right-content" :key="index" v-if="activeNamePc == itemPc.title">
                  <div class="content">
                    <div>
                      <div class="title">{{ itemPc.title }}</div>
                      <div class="right-list-item" v-for="(descs, indexD) in itemPc.desc" :key="indexD">
                        <img class="check-icon" src="@/assets/home/check-icon.png" alt="" />
                        <div>{{ descs.title }}</div>
                      </div>
                    </div>
                    <div class="right-content-img"><img :src="itemPc.img" alt="" /></div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <!-- 为不同行业提供更贴近的解决方案 -->
      <div class="profuile solution">
        <div class="container">
          <div class="row">
            <h2 class="text-center">为不同行业提供更贴近的解决方案</h2>
            <div class="text-desc">
              结合AI技术 | 构建交互平台 | 激发组织活力 | 人力资源数字化
            </div>
            <div class="flex-2-0">
              <div class="solution-wrap-body">
                <div v-for="(itemSo, idx) in solution" :key="idx" class="solution-list-item"
                  @mouseenter="getSolution(itemSo.val, 'pc', idx)" :class="{ 'is-active': idx == soluActive }">
                  {{ itemSo.title }}
                </div>
              </div>
              <div class="right-content" v-if="solution[soluActive].title">
                <div class="content">
                  <div class="content-top">
                    <div class="top-item" v-for="(itemI, indexI) in solution[soluActive].info" :key="indexI">
                      <div class="text">{{ itemI.title }}</div>
                      <div class="sub-text">
                        {{ itemI.desc }}
                      </div>
                    </div>
                  </div>
                  <div class="content-bottom">
                    <div class="btns">
                      <button class="carousel-free" @click="isDialog(solution[soluActive].valFree, 'pc')" id="show3">
                        申请试用
                      </button>
                      <button class="carousel-detail"
                        @click="getClickLinkPc(solution[soluActive].valInfo, 'pc', solution[soluActive].title)">
                        查看详情
                      </button>
                    </div>
                    <div class="tips">
                      <div v-for="(itemD, indexd) in solution[soluActive].desc" :key="indexd">{{ itemD.title }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <el-tabs :tab-position="tabPosition">
              <el-tab-pane v-for="(itemSo, indexS) in solution" :key="indexS">
                <div slot="label" class="left-list solution-list">
                  <div @click="getSolution(itemSo.val, 'pc')" class="solution-list-item">
                    {{ itemSo.title }}
                  </div>
                </div>
                <div id="xinxi" class="right-content">
                  <div class="content-wrap">
                    <div class="content">
                      <div class="content-top">
                        <div class="top-item" v-for="(itemI, indexI) in itemSo.info" :key="indexI">
                          <div class="text">{{ itemI.title }}</div>
                          <div class="sub-text">
                            {{ itemI.desc }}
                          </div>
                        </div>
                      </div>
                      <div class="content-bottom">
                        <div class="btns">
                          <button class="carousel-free" @click="isDialog(itemSo.valFree, 'pc')" id="show3">
                            免费试用
                          </button>
                          <button class="carousel-detail" @click="getClickLinkPc(itemSo.valInfo, 'pc')">
                            查看详情
                          </button>
                        </div>
                        <div class="tips">
                          <div v-for="(itemD, indexd) in itemSo.desc" :key="indexd">
                            {{ itemD.title }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              </el-tabs> -->
            </div>
          </div>
        </div>
      </div>
      <!-- 可持续发展的合规风险监测服务 -->
      <div class="profuile monitor">
        <div class="container">
          <div class="row">
            <h2 class="text-center">可持续发展的合规风险监测服务</h2>
            <div class="text-desc">
              全面感知与预警，以提高洞察力，降低企业风险
            </div>
            <div class="flex-2-1">
              <div class="side-part">
                <div class="part">
                  <div class="bg-wrap bg1">
                    <div class="text">{{ monitor[0].title }}</div>
                    <div class="sub-text"> {{ monitor[0].desc }}</div>
                  </div>
                </div>
                <div class="part">
                  <div class="bg-wrap bg2">
                    <div class="text">{{ monitor[2].title }}</div>
                    <div class="sub-text">
                      {{ monitor[2].desc }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="center-img">
                <img src="@/assets/home/monitor-img.png" alt="" />
              </div>
              <div class="side-part">
                <div class="part">
                  <div class="bg-wrap bg3">
                    <div class="text">{{ monitor[1].title }}</div>
                    <div class="sub-text">{{ monitor[1].desc }}</div>
                  </div>
                </div>
                <div class="part">
                  <div class="bg-wrap bg4">
                    <div class="text">{{ monitor[3].title }}</div>
                    <div class="sub-text">{{ monitor[3].desc }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 为什么选择慧眼识才 -->
        <div id="shuzi" class="profuile change">
          <div class="container">
            <div class="row">
              <h2 id="gundong" class="text-center">为什么选择慧眼识才</h2>
              <div class="sys-text-center">
                选择慧眼识才即选择了精细化管理的模式，全面赋能实现企业增长
              </div>
              <div class="flex-8">
                <div class="flex-width">
                  <div>
                    <img :src="why[0].icon" alt="" srcset="" />
                  </div>
                  <div class="title">{{ why[0].title }}</div>
                  <div class="change_desc1">{{ why[0].desc }}</div>
                  <div class="change_desc2">
                    <div>先进的技术，减少了人力和时间成本</div>
                    <div>具有高度的可扩展性，选择所需模块</div>
                    <div>提供优质的服务，获得最大化回报</div>
                  </div>
                  <div class="change_bg">
                    <div class="number">
                      <div id="zxNumber">100</div>
                      0+
                    </div>
                    <div>{{ why[0].numT }}</div>
                  </div>
                </div>
                <div class="flex-width">
                  <img :src="why[1].icon" alt="" srcset="" />
                  <div class="title">{{ why[1].title }}</div>
                  <div class="change_desc1">{{ why[1].desc }}</div>
                  <div class="change_desc2">
                    <div>Saas软件，无需下载即可使用</div>
                    <div>包括招聘、绩效、人才盘点等多种场景</div>
                    <div>帮助企业识别人才的价值</div>
                  </div>
                  <div class="change_bg">
                    <div class="number">
                      <div id="qlNumber">{{ why[1].num }}</div>
                      +
                    </div>
                    <div>{{ why[1].numT }}</div>
                  </div>
                </div>
                <div class="flex-width">
                  <div><img :src="why[2].icon" alt="" srcset="" /></div>
                  <div class="title">{{ why[2].title }}</div>
                  <div class="change_desc1">{{ why[2].desc }}</div>
                  <div class="change_desc2">
                    <div>7x24小时在线技术支持服务</div>
                    <div>技术专家为客户提供专业的技术支持</div>
                    <div>及时解决用户问题、优化功能建议</div>
                  </div>
                  <div class="change_bg">
                    <div class="number">
                      <div id="qfNumber">{{ why[2].num }}</div>
                      %
                    </div>
                    <div>{{ why[2].numT }}</div>
                  </div>
                </div>
                <div class="flex-width">
                  <img :src="why[3].icon" alt="" srcset="" />
                  <div class="title">{{ why[3].title }}</div>
                  <div class="change_desc1">{{ why[3].desc }}</div>
                  <div class="change_desc2">
                    <div>软件顾问、实施、售后全流程服务</div>
                    <div>一体化专业服务，深度赋能客户</div>
                    <div>为软件使用负责，持续交付新功能</div>
                  </div>
                  <div class="change_bg">
                    <div class="number">
                      <div id="timeNumber">{{ why[3].num }}</div>
                      h
                    </div>
                    <div>{{ why[3].numT }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 慧眼识才的成绩 -->
        <div class="profuile system achievement">
          <div class="container">
            <div class="row">
              <div id="indexNum" class="ach">
                <div class="ach-title">慧眼识才的成绩</div>
                <div class="ach-flex">
                  <div class="flex-width">
                    <div class="flex-color">
                      <div class="CountIndexNum1">50</div>
                      0+
                    </div>
                    <div class="flex-text">大中型企业的选择</div>
                  </div>
                  <div class="flex-width noleft">
                    <div class="flex-color">
                      <div class="CountIndexNum2">100</div>
                      000+
                    </div>
                    <div class="flex-text">累计服务人数</div>
                  </div>
                  <div class="flex-width isleft2">
                    <div class="flex-color">
                      <div class="CountIndexNum3">10</div>
                      +
                    </div>
                    <div class="flex-text">多年研发积累</div>
                  </div>
                  <div class="flex-width isleft">
                    <div class="flex-color">
                      <div class="CountIndexNum4">100</div>
                      +
                    </div>
                    <div class="flex-text">相关知识产权</div>
                  </div>
                  <div class="flex-width">
                    <div class="flex-color">
                      <span class="CountIndexNum5">26</span>
                      +
                    </div>
                    <div class="flex-text">关键行业场景覆盖</div>
                  </div>
                  <div class="flex-width noleft">
                    <div class="flex-color">人工智能</div>
                    <div class="flex-text">创新变革的数字化技术</div>
                  </div>
                  <div class="flex-width isleft2">
                    <div class="flex-color">专业团队</div>
                    <div class="flex-text">团队成员深耕技术研发</div>
                  </div>
                  <div class="flex-width isleft">
                    <div class="flex-color">
                      <div class="CountIndexNum6">9</div>
                      +
                    </div>
                    <div class="flex-text">多年资深服务经验</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="need">
              <div class="ach-title">他们都在使用</div>
              <img :src="they" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <hysc-dia v-if="visibleD" :visible.sync="visibleD" @getClickCancel="getClickClose"></hysc-dia>
  </div>
</template>

<script>
import HyscDia from '../Dialog/dialog.vue'
import { website } from '@/api/gw.js'

export default {
  data() {
    return {
      visibleD: false,
      tabPosition: 'left',
      tabPositionTel: 'top',
      activeName: '风险管理',
      activeNamePc: '风险管理',
      soluActive: 0,
      activeSolution: '信息安全',
      zhaoPin: [
        {
          img: require('@/assets/home/fengxian-img.png'),
          icon: require('@/assets/home/what-can-icon/img-5.png'),
          isIcon: require('@/assets/home/what-can-icon/img-5-a.png'),
          title: '风险管理',
          val: 'risking',
          valInfoTel: 'riskingInfo',
          index: 4,
          desc: [
            { title: '风险管理系统多维度筛查，支持企业用工环节合规性', },
            { title: '合规提示功能实时走查，告警风险等级，提供专业建议' },
            { title: '评估机制评定企业法规合规性，建立风险管理机制' },
            { title: '风险合规提示通过随时走查，实时告警风险等级' },
          ],
        },
        {
          img: require('@/assets/home/zhanlue-img.png'),
          icon: require('@/assets/home/what-can-icon/img-7.png'),
          isIcon: require('@/assets/home/what-can-icon/img-7-a.png'),
          title: '战略管理',
          val: 'risking',
          valInfoTel: 'riskingInfo',
          index: 7,
          desc: [
            { title: '自定义商业画布，用最简单的方式梳理商业模式', },
            { title: '发展大事记，记录企业发展历程、关键里程碑' },
            { title: '经营分析，全图形数字化呈现企业经营情况' },
            { title: 'AI解读报告，为企业发展提供不同视角的解读分析' },
          ],
        },
        {
          img: require('@/assets/home/zhaopin-img.png'),
          icon: require('@/assets/home/what-can-icon/img-1.png'),
          isIcon: require('@/assets/home/what-can-icon/img-1-a.png'),
          title: '智能招聘',
          val: 'smarter',
          valInfoTel: 'smarterInfo',
          index: 0,
          desc: [
            { title: '整合主流招聘平台，搭建自有招聘门户' },
            { title: '社招、校招全覆盖，满足各行业招聘特殊场景' },
            { title: '智能算法快速筛选简历，并进行多维度评价' },
            { title: '智能工具帮助参考，面试流程清晰可见' },
          ],
        },
        {
          img: require('@/assets/home/jixiao-img.png'),
          icon: require('@/assets/home/what-can-icon/img-3.png'),
          isIcon: require('@/assets/home/what-can-icon/img-3-a.png'),
          title: '绩效管理',
          val: 'performance',
          valInfoTel: 'performanceInfo',
          index: 2,
          desc: [
            { title: '360全面环评功能结合多维度评价和AI智能模型' },
            { title: '绩效流程追踪确保高效执行和全程监控' },
            { title: '员工高效移动端协同和便捷申诉流程促进沟通' },
            { title: '全面绩效报告通过签名机制确保可信赖的数据展示' },
          ],
        },
        {
          img: require('@/assets/home/pandian-img.png'),
          icon: require('@/assets/home/what-can-icon/img-4.png'),
          isIcon: require('@/assets/home/what-can-icon/img-4-a.png'),
          title: '人才盘点',
          val: 'talents',
          valInfoTel: 'talentsInfo',
          index: 3,
          desc: [
            { title: '人才盘点系统利用AI生成模型和数字化看板提供智能服务' },
            { title: '支持多人模式的人才对比，提供竞聘和选拔解决方案' },
            { title: '一图展示企业人才价值情况，找出最有价值的人才类别' },
            { title: '提供人才报告，综合多维评价和自身变化评定员工价值' },
          ],
        },
        {
          img: require('@/assets/home/zuzhi-img.png'),
          icon: require('@/assets/home/what-can-icon/img-2.png'),
          isIcon: require('@/assets/home/what-can-icon/img-2-a.png'),
          title: '组织管理',
          val: 'management',
          valInfoTel: 'managementInfo',
          index: 1,
          desc: [
            { title: '灵活创建、维护组织结构，企业科学管理人力资源' },
            { title: '智能、便捷、安全的员工信息管理系统' },
            { title: '记录员工的入转调离，实时跟踪信息变动' },
            { title: '灵活配置角色和权限，提高信息安全和管理效率' },
          ],
        },
        {
          img: require('@/assets/home/jiazhi-img.png'),
          icon: require('@/assets/home/what-can-icon/img-8.png'),
          isIcon: require('@/assets/home/what-can-icon/img-8-a.png'),
          title: '价值体系',
          val: 'jiazhi',
          valInfoTel: 'jiazhiInfo',
          index: 8,
          desc: [
            { title: '量化人才能力素质水平及对组织的贡献，找到核心人才' },
            { title: '记录人才价值变化趋势，找到潜力人才' },
            { title: '从运营效能、合规风控等方面做全景分析，量化企业价值' },
            { title: '为企业的战略决策提供有效数据支持' },
          ],
        },
        {
          img: require('@/assets/home/kanban-img.png'),
          icon: require('@/assets/home/what-can-icon/img-6.png'),
          isIcon: require('@/assets/home/what-can-icon/img-6-a.png'),
          title: '数字看板',
          val: 'signage',
          valInfoTel: 'signageInfo',
          index: 5,
          desc: [
            { title: '待办事项提醒功能支持用户添加个性化日程' },
            { title: '人事数据分析报告提供月度、季度和年度绩效报告' },
            { title: '招聘渠道分析不仅提供统计分析，还深入挖掘数据' },
            { title: '任务进度分析展示任务进度，灵活应对招聘任务的变化' },
          ],
        },
      ],
      solution: [
        {
          img: require('@/assets/solutionbg1.png'),
          title: '信息安全',
          val: 'security',
          valFree: 'securityFree',
          valInfo: 'securityInfo',
          desc: [
            { title: '简历画像' },
            { title: '智慧话术' },
            { title: '到岗预测' },
          ],
          info: [
            {
              title: '简历精准筛选',
              desc: '综合匹配度快速、精准地筛选简历',
            },
            {
              title: '提高招聘精度',
              desc: '自智能话术考察，到岗准确预测',
            },
            {
              title: '招聘决策支持',
              desc: '实时数据支持，优化招聘策略',
            },
          ],
        },
        {
          img: require('@/assets/solutionbg2.png'),
          title: '生物医药',
          val: 'creature',
          valFree: 'creatureFree',
          valInfo: 'creatureInfo',
          desc: [
            { title: '招聘管理-校招' },
            { title: '综合匹配度' },
            { title: '测评管理' },
          ],
          info: [
            {
              title: '高体验校招运营',
              desc: '整合先进的技术和优质的服务',
            },
            {
              title: '基于冰山理论测评',
              desc: '能力素质，学习等全方位测评',
            },
            {
              title: '助力人才培养',
              desc: '测评报告提供有效的人才培养建议',
            },
          ],
        },
        {
          img: require('@/assets/solutionbg3.png'),
          title: '零售连锁',
          val: 'chain',
          valFree: 'chainFree',
          valInfo: 'chainInfo',
          desc: [
            { title: '智能招聘' },
            { title: '360度绩效环评' },
            { title: '绩效流程追踪' },
          ],
          info: [
            {
              title: '提高招聘效率',
              desc: '全流程线上化，信息传递更高效',
            },
            {
              title: '降低人力成本',
              desc: '自动化流程及分析，精准高效',
            },
            {
              title: '助力绩效落地',
              desc: '实时查看，追踪节点，利于管理',
            },
          ],
        },
        {
          img: require('@/assets/solutionbg4.png'),
          title: '国央企',
          val: 'chEnterprise',
          valFree: 'chEnterpriseFree',
          valInfo: 'chEnterpriseInfo',
          desc: [
            { title: '人才地图' },
            { title: '人才价值报告' },
            { title: '人才价值对比' },
          ],
          info: [
            {
              title: '价值评估识别人才',
              desc: '价值评估分析人才能力素质与价值',
            },
            {
              title: '辅助人才规划决策',
              desc: '人才报告、人才地图实现数据落地',
            },
            {
              title: '提升效能',
              desc: '促进员工和组织的共同发展',
            },
          ],
        },
        {
          img: require('@/assets/solutionbg5.png'),
          title: '生产制造',
          val: 'manufacturing',
          valFree: 'manufacturingFree',
          valInfo: 'manufacturingInfo',
          desc: [{ title: '员工数字档案' }, { title: '风险检测预警' }],
          info: [
            {
              title: '无纸化存档',
              desc: '自动生成员工数字档案，杜绝风险',
            },
            {
              title: '集中管理提高效率',
              desc: '多账号协同，简化流程，提高效率',
            },
            {
              title: '风险排查和预警',
              desc: '检测用工过程风险，提高用工合规性',
            },
          ],
        },
      ],
      monitorImg: require('@/assets/home/monitor-img.png'),
      monitor: [
        {
          title: '全面风险感知',
          desc: '多维度风险筛查提供全面用工和效能风险感知，提高组织洞察力',
        },
        {
          title: '实时预警与建议',
          desc: '提供实时风险预警和法规建议，快速应对法规变化，降低合规风险',
        },
        {
          title: '合规流程管理',
          desc: '支持全流程的合规评估，帮助企业建立规范、合法的用工流程',
        },
        {
          title: '资源优化与高效管理',
          desc: '企业通过优化和自查，提高资源配置效率，降低潜在用工风险',
        },
      ],
      why: [
        {
          icon: require('@/assets/home/change_1.png'),
          title: '中小企业优选',
          desc: '提供价格实惠的套餐方案',
          posi: '先进的技术，减少了人力和时间成本具有高度的可扩展性，选择所需模块提供优质的服务，获得最大化回报',
          num: '1000+',
          numT: '服务企业',
        },
        {
          icon: require('@/assets/home/change_2.png'),
          title: '全流程价值评估',
          desc: '贴合企业创新发展核心使用场景',
          posi: 'Saas软件，无需下载即可使用包括招聘、绩效、人才盘点等多种场景帮助企业识别人才的价值',
          num: '10',
          numT: '产品模块',
        },
        {
          icon: require('@/assets/home/change_3.png'),
          title: '全方位支持',
          desc: '提供针对性的技术支持和咨询服务',
          posi: '7x24小时在线技术支持服务技术专家为客户提供专业的技术支持及时解决用户问题、优化功能建议',
          num: '89',
          numT: '客户口碑',
        },
        {
          icon: require('@/assets/home/change_4.png'),
          title: '全周期服务',
          desc: '全程专业服务，满足客户需求',
          posi: '软件顾问、实施、售后全流程服务一体化专业服务，深度赋能客户为软件使用负责，持续交付新功能',
          num: '24',
          numT: '客户服务',
        },
      ],
      needImg: [
        { tp: require('@/assets/need1.png') },
        { tp: require('@/assets/need2.png') },
        { tp: require('@/assets/need3.png') },
        { tp: require('@/assets/need4.png') },
        { tp: require('@/assets/need5.png') },
        { tp: require('@/assets/need6.png') },
        { tp: require('@/assets/need7.png') },
        { tp: require('@/assets/need8.png') },
        { tp: require('@/assets/need9.png') },
        { tp: require('@/assets/need10.png') },
        { tp: require('@/assets/need11.png') },
        { tp: require('@/assets/need12.png') },
        { tp: require('@/assets/need13.png') },
        { tp: require('@/assets/need14.png') },
        { tp: require('@/assets/need15.png') },
        { tp: require('@/assets/need16.png') },
        { tp: require('@/assets/need17.png') },
        { tp: require('@/assets/need18.png') },
        { tp: require('@/assets/need19.png') },
        { tp: require('@/assets/need20.png') },
        { tp: require('@/assets/need21.png') },
        { tp: require('@/assets/need22.png') },
        { tp: require('@/assets/need23.png') },
        { tp: require('@/assets/need24.png') },
        { tp: require('@/assets/need25.png') },
        { tp: require('@/assets/need26.png') },
        { tp: require('@/assets/need27.png') },
        { tp: require('@/assets/need28.png') },
      ],
      they: require('@/assets/home/ach_need.png'),
    };
  },
  components: {
    HyscDia,
  },
  methods: {
    tabClickSolu(e) {
      const val = this.solution[e.index].val
      this.getClick(val, 'tel')
    },

    getClickLinkTel(val, type, title) {
      this.getClick(val, type)
      this.$router.push({ path: 'Customer', query: { title: title } })
    },

    getClickLinkPc(val, type, title) {
      this.getClick(val, type)
      // this.$router.push('Customer')
      this.$router.push({ path: 'Customer', query: { title: title } })
    },

    getClickLink(val, type) {
      this.getClick(val, type)
      this.$router.push('Application');
    },

    tabClick(e) {
      const val = this.zhaoPin[e.index].val
      this.getClick(val, 'tel')
    },

    // 点击做记录
    getClick(item, type, CZ) {
      if (CZ) {
        if (this.activeNamePc != item.title) {
          this.activeNamePc = item.title
          website({ type: item.val, name: type }).then()
        }
      } else {
        website({ type: item, name: type }).then()
      }
    },

    // 点击做记录
    getSolution(val, type, idx) {
      this.soluActive = idx
      website({ type: val, name: type }).then()
    },

    isDialog(val, type) {
      this.getClick(val, type)
      this.visibleD = true
      // this.$router.push('/Purchase')
    },

    getClickClose() {
      this.visibleD = false
    },
  }
}
</script>

<style scoped>
.pc {
  display: block;
}

.tel {
  display: none;
}

.banner-old {
  background: #e3ebf9
}

.pc .el-carousel__item {
  overflow: auto;
}

.carousel__arrow .el-carousel__arrow {
  width: 0;
  height: 0;
  display: none !important;
  opacity: 0;
}

.banner-old .container {
  position: relative;
  height: 100%;
  width: 1170px;
  margin: 0 auto;
}

.banner-old .container img {
  position: absolute;
  height: 600px;
  width: auto;
  top: -50px;
  right: 0;
  z-index: 999;
}

.banner-old .banner-content--old {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(0%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1;
  gap: 20px;
}

.banner-old .banner-content--old .new-title {
  font-size: 36px;
  font-weight: 900;
  display: flex;
  align-items: center;
  gap: 20px;
}

.banner-content--old .new-title .title-name {
  border-right: 1px;
  background: linear-gradient(87deg, #186FFF 0%, #3EB4EF 76%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.banner-content--old .new-title .title-tips {
  color: #000000;
  background: none;
}

.banner-content--old .new-title .title-border {
  background: linear-gradient(180deg, #3BAFF1 0%, #186FFF 100%);
  width: 3px;
  border-radius: 152px;
  height: 36px;
}

.banner-old .banner-content--old .new-litle {
  color: #666666;
  font-size: 20px;
}

.banner-old .banner-content--old button {
  background: #186FFF;
  border-radius: 24px;
  width: 170px;
  height: 48px;
  color: #FFFFFF;
  border: none;
  font-size: 18px;
  margin-top: 20px;
}

.banner_po {
  height: 500px;
  position: relative;
}

@media (max-width: 1200px) {
  .pc {
    display: none !important;
  }

  .tel {
    display: block !important;
  }

  .tel .profuile .container .row .text-center {
    padding-bottom: 5px;
    font-weight: bold;
    color: #000;
    font-size: 26px;
  }

  :deep(.tel .el-tabs--card > .el-tabs__header .el-tabs__nav) {
    border: none;
  }

  :deep(.el-tabs__nav) {
    border: none;
  }

  :deep(.el-tabs__nav-scroll) {
    width: 100%;
    overflow-x: scroll;
    background: #f1f8ff;
    border-radius: 40px;
    padding: 6px 0;
  }

  :deep(.el-tabs__nav-scroll::-webkit-scrollbar) {
    display: none;
  }

  :deep(.el-tabs--card > .el-tabs__header .el-tabs__item) {
    border: none;
  }

  :deep(.el-tabs__nav-wrap span) {
    display: none;
  }

  :deep(.el-tabs__nav-wrap.is-scrollable) {
    padding: 0 0;
  }

  .tel .banner {
    background: url(../../assets/telbg.png) no-repeat;
    background-size: 100%;
    height: 400px;
    position: absolute;
    top: 0px;
    width: 100%;
  }

  .tel .solution,
  .monitor,
  .change,
  .system {
    padding-top: 0px !important;
  }

  .tel .achievement {
    padding-bottom: 0;
  }

  .tel .what-to-do .container {
    margin-bottom: 0;
  }

  .tel .solution .container {
    margin-bottom: 0;
  }

  .tel .change .container {
    margin-bottom: 0;
  }

  .tel .achievement .container {
    margin-bottom: 0;
  }

  .tel .container {
    margin-bottom: 66px;
  }

  .tel .elTabs .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
    background: linear-gradient(90deg, #52aaff 0%, #186fff 98%) !important;
    color: #fff;
  }

  .tel .teldesc {
    position: absolute;
    left: 0%;
    right: 50%;
    top: 40%;
    width: 100%;
    text-align: center;
  }

  .tel .teldesc .teld1 {
    font-size: 40px;
    font-weight: bold;
    background: linear-gradient(85deg, #f3f7ff 0%, #03abff 99%);
    background-clip: text;
    color: #ffffff;
    letter-spacing: 1.82px;
  }

  .teld2 {
    font-size: 34px;
    font-weight: bold;
    letter-spacing: 1.82px;
  }

  .teld2,
  .teld3 {
    color: #fff;
  }

  .teld3 {
    font-size: 16px;
  }

  .tel .zhaopinBG {
    background: linear-gradient(310deg, #186fff 12%, #32a9f7 101%);
    border-radius: 13px 13px 0px 0px;
    text-align: center;
  }

  .tel .zhaopinBG img {
    width: 90%;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  :deep(.el-tabs__content) {
    width: 100% !important;
    background: #ffffff;
    border-radius: 13px;
    box-shadow: 0px 6px 10px 0px rgba(29, 67, 102, 0.373);
  }

  .tel .profuile {
    padding-top: 328px;
  }

  .tel .change .container .row .flex-8 .flex-width .title {
    font-size: 1.5rem;
    padding-top: 0px;
  }

  .tel .change .container .row .flex-8 .flex-width {
    width: 22%;
    padding: 5px 0 0 0;
    border-radius: 20px;
  }

  .tel .zhaopinDesc {
    padding: 20px 20px 0 20px;
  }

  .tel .zhaopinDesc .zhaoPinTitle {
    font-size: 20px;
    color: #4d5565;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .tel .zhaopinDesc img {
    width: 15px;
    height: 15px;
    margin-top: 3px;
    margin-right: 10px;
    margin-bottom: 0;
  }

  .tel .zhaopinDesc .zhaopinDis {
    display: flex;
    margin-bottom: 38px;
    color: #333;
    letter-spacing: 1.35px;
  }

  .tel .zhaopinDesc .infoR {
    justify-content: center;
    color: #186fff;
    font-weight: 500;
  }

  .tel .zhaopinDesc .infoR a {
    color: #186fff;
    font-weight: 500;
  }

  .tel .zhaopinDesc .zhaopinDis .zhaopinR {
    width: 15px;
    height: 11px;
    margin-left: 6px;
  }

  /* 解决方案 */
  :deep(.solution .el-tabs__nav-scroll) {
    padding: 6px 0 !important;
  }

  :deep(.tel .el-tabs--card > .el-tabs__header) {
    border: none;
  }

  .tel .solution {
    background-color: initial;
  }

  .tel .solutionBG {
    background-size: 100% 100%;
    height: 366px;
    color: #fff;
  }

  .tel .title {
    text-align: center;
    padding-top: 20px;
    font-size: 20px;
    font-weight: 600;
  }

  .tel .soluDis {
    justify-content: center;
    display: flex;
    position: relative;
  }

  .tel .soluDis .soluDL {
    margin-left: 20px;
    position: relative;
  }

  .tel .soluDis div::before {
    content: '';
    width: 4px;
    height: 4px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: 9px;
    left: -10px;
  }

  .tel .soluDes {
    margin: 0 20px;
    padding: 15px 20px 10px 20px;
  }

  .tel .soluBorder {
    border-top: 1px dotted #fff;
    border-bottom: 1px dotted #fff;
  }

  .tel .soluDes .soluDt {
    font-size: 17px;
    font-weight: 500;
  }

  .tel .soluDes .soluDd {
    font-size: 14px;
    margin-top: 10px;
  }

  .tel .soluF {
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1.35px;
    margin-top: 5px;
  }

  .tel .soluF a {
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1.35px;
    margin-top: 5px;
    color: #fff;
  }

  .tel .soluF img {
    width: 15px;
    height: 11px;
    margin-top: 0;
    margin-left: 10px;
  }

  .tel .monBg {
    box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.1476);
    text-align: center;
    padding-bottom: 22px;
    border-radius: 12px;
    background: #f7faff;
    margin-top: 22px;
    position: relative;
  }

  .tel .monBg .po_ab {
    position: absolute;
    width: 102px;
    height: 80px;
    top: 0;
    right: 0;
    margin: 0;
  }

  .tel .monBg .po_ab2 {
    position: absolute;
    width: 102px;
    height: 80px;
    bottom: 0;
    left: 0;
  }

  .tel .monBg .monImg {
    width: 80%;
    margin-top: 22px;
  }

  .tel .monBg .part {
    width: 90%;
    height: 124px;
    margin: 22px auto;
    border-radius: 8px;
    border: 1px solid #d3e4ff;
    border-left: 9px solid #186fff;
    background: linear-gradient(111deg, #ffffff 0%, #eaf1ff 100%);
  }

  .tel .monBg .part .bg-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 23px;
    gap: 9px;
    text-align: left;
  }

  .tel .monBg .part .bg-wrap.bg1 {
    background: url(../../assets/home/monitor-bg1.png) no-repeat;
    background-size: 43%;
    background-position: 120% -40%;
    background-origin: content-box;
  }

  .tel .monBg .part .bg-wrap.bg2 {
    background: url(../../assets/home/monitor-bg2.png) no-repeat;
    background-size: 43%;
    background-position: 120% -40%;
    background-origin: content-box;
  }

  .tel .monBg .part .bg-wrap.bg3 {
    background: url(../../assets/home/monitor-bg3.png) no-repeat;
    background-size: 43%;
    background-position: 120% -40%;
    background-origin: content-box;
  }

  .tel .monBg .part .bg-wrap.bg4 {
    background: url(../../assets/home/monitor-bg4.png) no-repeat;
    background-size: 43%;
    background-position: 120% -40%;
    background-origin: content-box;
  }

  .tel .monBg .part .text {
    color: #333;
    font-size: 20px;
    font-weight: bold;
  }

  .tel .monBg .part .sub-text {
    color: #666;
    font-size: 15px;
  }

  .tel .change {
    background: none;
  }

  .tel .change .container .row .flex-8 .flex-width {
    width: 48%;
  }

  .tel .change .container .row .flex-8 .flex-width img {
    margin-top: 0;
  }

  /* 为什么 */
  .tel .change .container .row .flex-8 .flex-width .change_desc1 {
    font-size: 12px;
    margin-bottom: 12px;
    height: 36px;
  }

  .tel .change .container .row .flex-8 .flex-width .change_desc2 {
    font-size: 10px;
    color: #666;
    letter-spacing: 0.77px;
    line-height: 20px;
    height: 80px;
  }

  .tel .achievement .ach .flex-width {
    width: 48%;
    font-size: 12px;
    margin-top: 50px;
    color: #666;
    padding-left: 0%;
    margin-left: 0;
  }

  .tel .profuile .container .row .flex-8 .flex-width .details {
    display: none;
    -webkit-transition: All 0.4s;
  }

  .tel .profuile .container .row .flex-8 .flex-width .details .details_flex {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: white;
  }

  .tel .profuile .container .row .flex-8 .flex-width .details .details_flex img {
    width: 14px;
    height: 14px;
    margin-left: 5px;
  }

  .tel .profuile .container .row .flex-8 .flex-width:hover {
    background: linear-gradient(134deg, #00aaff -2%, #0085ff 60%);
    box-shadow: 0px 5px 12px 0px #ced3da;
    -webkit-transform: translate(0, -10px);
  }

  .tel .profuile .container .row .flex-8 .flex-width:hover .change_desc1 {
    color: white;
  }

  .tel .profuile .container .row .flex-8 .flex-width:hover .change_desc2 {
    color: white;
  }

  .tel .profuile .container .row .flex-8 .flex-width:hover .title {
    color: white;
  }

  .tel .profuile .container .row .flex-8 .flex-width:hover .desc {
    display: none;
  }

  .tel .achievement .ach .flex-width .flex-color {
    color: #186fff;
    font-weight: 600;
    font-size: 20px;
    display: flex;
  }

  .tel .noleft {
    width: 48% !important;
  }

  .tel .isleft {
    width: 48% !important;
    /* margin-left: 14%; */
  }

  .tel .isleft2 {
    width: 48% !important;
    /* margin-left: 12%; */
  }

  .tel .achievement .need {
    margin-top: -50px;
  }

  .tel .need .pcNeed {
    display: none;
  }

  .tel .need .telNeed {
    display: block;
    display: flex;
    flex-wrap: wrap;
  }

  .tel .need .telNeed img {
    width: 25%;
    height: 50px;
    box-sizing: border-box;
    border: 1px solid #d4d4d4;
    margin-top: 0;
  }

  :deep(.tel .elTabs .el-tabs__item.is-active) {
    background: linear-gradient(90deg, #52aaff 0%, #186fff 98%) !important;
    color: #fff;
  }

  :deep(.tel .solution .el-tabs__item.is-active) {
    /* background: initial; */
    background: linear-gradient(90deg, #52aaff 0%, #186fff 98%) !important;
    color: #fff;
  }

  :deep(.tel .el-tabs__item) {
    margin: 0px 0;
  }

  :deep(.tel .el-tabs--card > .el-tabs__header .el-tabs__nav) {
    padding: 0 6px;
  }
}
</style>

<style scoped>
:deep(.el-tabs__nav-wrap::after) {
  width: 0 !important;
}

:deep(.el-tabs__item.is-active) {
  color: #fff;
  border-radius: 4px;
  font-weight: bold;
  border-radius: 40px;
}

:deep(.el-tabs__active-bar) {
  color: #333;
  border-radius: 4px;
  display: none;
}

:deep(.el-tabs__item:hover) {
  color: #333;
  cursor: pointer;
}

:deep(.is-active:hover) {
  color: #fff;
  cursor: pointer;
}

:deep(.el-tabs__item) {
  height: 32px;
  line-height: 32px;
}

:deep(.el-tabs__item img) {
  width: 17px;
}

:deep(.el-tabs__item .flexSlot) {
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 24px;
}

:deep(.el-tabs__item .flexSlot img) {
  margin-right: 10px;
}

:deep(.el-tabs__item) {
  font-size: 15px;
}

:deep(.el-tabs--left) {
  display: flex;
  gap: 52px;
}

:deep(.el-tabs--left .el-tabs__header.is-left) {
  width: 23%;
}

:deep(.solution .el-tabs--left) {
  gap: 0;
}

:deep(.solution .el-tabs__nav-scroll) {
  background: #eaf1ff;
  padding: 65px 0px 96px 0;
}

:deep(.solution .el-tabs--left .el-tabs__header.is-left) {
  margin-right: 0px;
}

:deep(.solution .el-tabs__item) {
  height: initial;
}
</style>

<style scoped>
.carousel {
  position: relative;
}

.row {
  margin-right: 0;
  margin-left: 0;
}

.nav li {
  font-size: 14px;
  margin-right: 30px;
}

.navTel .free {
  margin-top: 5px;
}

.navTel .free button {
  width: 108px;
  height: 37px;
  line-height: 35px;
  padding: 0;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  background-color: #186fff;
  color: white;
}

.navTel .tel {
  color: #186fff;
  font-size: 16px;
  margin-top: 15px;
}

/* @media (min-width: 768px) {
  .navbar-nav>li>a {
    padding-top: 15px;
    padding-bottom: 10px;
  }
} */

@media (min-width: 1200px) {
  .carousel-caption {
    width: 1170px;
    margin: 0 auto;
  }
}

.banner-content {
  height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
}

.banner-content--new {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1;
  gap: 20px;
}

.banner-content--new .new-title {
  font-size: 70px;
  background: linear-gradient(87deg, #186FFF 0%, #3EB4EF 76%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 900;
}

.banner-content--new .new-dec {
  font-size: 50px;
  font-weight: 900;
  color: #000000;
}

.banner-content--new .new-litle {
  color: #666666;
  font-size: 20px;
}

.banner-content--new button {
  background: #186FFF;
  border-radius: 24px;
  width: 170px;
  height: 48px;
  color: #FFFFFF;
  border: none;
  font-size: 18px;
  margin-top: 20px;
}

.banner-content--new .banner-content .banner-img-wrap {
  position: relative;
  width: 1140px;
  height: 100%;
  margin: 0 auto;
}

.banner-content .banner-img {
  position: absolute;
  top: -25px;
  right: -80px;
  width: 690px;
}

.carousel-caption {
  position: absolute;
  text-shadow: none;
  top: 50%;
  left: 0;
  right: 0;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  color: #fff;
  text-align: left;
  transform: translate(0, -35%);
}

.carousel-caption .carousel-title {
  font-size: 36px;
  font-weight: 600;
  color: #000;
  display: flex;
}

.carousel-caption .carousel-title .title {
  position: relative;
  padding-right: 12px;
  background: linear-gradient(82deg, #186fff 0%, #3eb4ef 98%);
  -webkit-background-clip: text;
  color: transparent;
}

.carousel-caption .carousel-title .title::after {
  content: '';
  width: 3px;
  height: 37px;
  position: absolute;
  top: 10px;
  right: 0;
  border-radius: 999px;
  background: linear-gradient(180deg, #bbe0f9 0%, #b1cefd 100%);
}

.carousel-caption .carousel-title .sub-title {
  padding-left: 10px;
}

.carousel-caption .carousel-desc {
  font-size: 20px;
  margin-top: 6px;
  color: #666;
}

.carousel-caption .carousel-free {
  width: 170px;
  height: 48px;
  line-height: 46px;
  background-color: #186fff;
  color: white;
  font-size: 18px;
  border-radius: 40px;
  text-align: center;
  margin-top: 34px;
  border: none;
}

.profuile .container .row .text-center {
  padding-bottom: 5px;
  font-weight: bold;
  color: #000;
  font-size: 32px;
}

.profuile .container .row .text-desc {
  color: #666;
  text-align: center;
  margin-bottom: 10px;
}

.profuile .container .row h4 {
  font-size: 18px;
  color: #666;
}

.row .flex-2 {
  display: flex;
  gap: 50px;
  margin-top: 50px;
}

.profuile .container .row .flex-2 .left-menu {
  display: flex;
  flex-direction: column;
  width: 190px;
  gap: 15px;
}

.profuile .container .row .flex-2 .left-menu .flexSlot {
  /* background-color: #186fff; */
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 36px;
  color: #333333;
  gap: 17px;
  font-weight: normal;
  padding-left: 18px;
  cursor: pointer;
}

.profuile .container .row .flex-2 .left-menu .flexSlot.is-active {
  background-color: #186fff;
  color: #fff;
}

.profuile .container .row .flex-2 .left-menu img {
  height: 20px;
  width: 20px;
}

/* :deep(.is-active .flexSlot) {
  background-color: #186fff;
  border-radius: 4px;
} */

.profuile .container .row .flex-2 .left-list {
  width: 177px;
}

.profuile .container .row .flex-2 .left-list .canDo-list-item {
  width: 100%;
  height: 36px;
  line-height: 36px;
  font-size: 15px;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  padding-left: 24px;
}

.profuile .container .row .flex-2 .right-content {
  flex: 1;
  height: 426px;
  padding: 58px;
  padding-right: 43px;
  background: url(../../assets/home/what_can_do-bg.png) no-repeat;
  background-size: 100% 100%;
}

.profuile .container .row .flex-2 .right-content .content {
  height: 100%;
  display: flex;
  gap: 40px;
  justify-content: space-between;
}

.profuile .container .row .flex-2 .right-content .content .title {
  color: #3d3d3d;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 40px;
}

.profuile .container .row .flex-2 .right-content .content .right-list-item {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #333;
  font-size: 15px;
  margin-bottom: 22px;
}

.profuile .container .row .flex-2 .right-content .content .check-icon {
  width: 16px;
  height: 16px;
}

.profuile .container .row .flex-2 .right-content .content .right-content-img {
  flex: 1;
  height: 100%;
}

.profuile .container .row .flex-8 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.profuile .container .row .flex-2 .right-content .content .right-content-img img {
  object-fit: contain;
  height: 100%;
}

.profuile .container .row .flex-8 .flex-width {
  width: 23.3%;
  text-align: center;
  background-color: #f9fbff;
  margin: 18px 0;
  padding: 30px 20px;
  border: 1px solid #dfe2eb;
  border-radius: 6px;
  -webkit-transition: All 0.4s;
}

.profuile .container .row .flex-8 .flex-width .title {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 6px;
  margin-top: 9px;
}

.profuile .container .row .flex-8 .flex-width .desc {
  color: #666;
  font-size: 14px;
}

.profuile .container .row .flex-8 .flex-width img {
  width: 80px;
  height: 80px;
}

.solution {
  background-color: #f9fbff;
}

.solution .flex-2-0 {
  margin-top: 50px;
  display: flex;
  border-radius: 8px;
  overflow: hidden;
}

.solution .flex-2-0 .solution-wrap-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 240px;
  background: #EAF1FF;
  padding: 0 20px;
  gap: 18px;
}

.solution .flex-2-0 .solution-wrap-body .solution-list-item {
  width: 100%;
  height: 42px;
  line-height: 42px;
  font-size: 15px;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
}

.solution .flex-2-0 .solution-list-item.is-active {
  background-color: #186fff;
  -webkit-clip-path: polygon(0 0, 75% 0, 100% 50%, 75% 100%, 0 100%);
  clip-path: polygon(0 0, 90% 0, 100% 50%, 90% 100%, 0 100%);
  color: #fff;
  font-weight: bold;
}

.solution .flex-2-0 .solution-list-right {
  width: calc(100% - 220px);
  height: 426px;
  padding: 30px;
  background: url(../../assets/home/solution-bg1.png) no-repeat;
  background-size: 100% 100%;
}

.solution .container .row .flex-2-0 .right-content {
  flex: 1;
  height: 426px;
  padding: 30px;
  background: url(../../assets/home/solution-bg1.png) no-repeat;
  background-size: 100% 100%;
}

.solution .container .row .flex-2-0 .right-content .content {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: flex-end;
}

.solution .container .row .flex-2-0 .right-content .content .content-top {
  display: flex;
}

.solution .container .row .flex-2-0 .right-content .content .content-top .top-item {
  padding: 20px;
  position: relative;
}

.solution .container .row .flex-2-0 .right-content .content .content-top .top-item::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  opacity: 0.4;
  border-right: 1px dashed #fff;
}

.solution .container .row .flex-2-0 .right-content .content .content-top .top-item:nth-child(3):after {
  border: none;
}

.solution .container .row .flex-2-0 .right-content .content .content-top .text {
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 14px;
}

.solution .container .row .flex-2-0 .right-content .content .content-top .sub-text {
  color: #fff;
  font-size: 15px;
}

.solution .container .row .flex-2-0 .right-content .content .content-bottom {
  padding: 0 20px;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

.solution .container .row .flex-2-0 .right-content .content .content-bottom .carousel-free {
  width: 120px;
  height: 44px;
  line-height: 42px;
  background-color: #fff;
  color: #186fff;
  font-size: 16px;
  border-radius: 40px;
  text-align: center;
  margin-bottom: 34px;
  border: none;
  margin-right: 16px;
}

.solution .container .row .flex-2-0 .right-content .content .content-bottom .carousel-detail {
  width: 120px;
  height: 44px;
  line-height: 42px;
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  font-size: 16px;
  border-radius: 40px;
  text-align: center;
  margin-bottom: 34px;
  border: none;
  position: relative;
}

.solution .container .row .flex-2-0 .right-content .content .content-bottom .tips {
  display: flex;
  color: #fff;
  font-size: 15px;
  padding: 11px;
  gap: 18px;
}

.solution .container .row .flex-2-0 .right-content .content .content-bottom .tips div {
  position: relative;
  padding-left: 10px;
}

.solution .container .row .flex-2-0 .right-content .content .content-bottom .tips div::before {
  content: '';
  width: 4px;
  height: 4px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: 9px;
  left: 0;
}

.monitor .flex-2-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  gap: 55px;
}

.monitor .flex-2-1 .side-part .part {
  width: 322px;
  height: 124px;
  margin: 55px 0;
  border-radius: 8px;
  border: 1px solid #d3e4ff;
  border-left: 9px solid #186fff;
  background: linear-gradient(111deg, #ffffff 0%, #eaf1ff 100%);
}

.monitor .flex-2-1 .side-part .part .bg-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 23px;
  gap: 9px;
}

.monitor .flex-2-1 .side-part .part .bg-wrap.bg1 {
  background: url(../../assets/home/monitor-bg1.png) no-repeat;
  background-size: 43%;
  background-position: 120% -40%;
  background-origin: content-box;
}

.monitor .flex-2-1 .side-part .part .bg-wrap.bg2 {
  background: url(../../assets/home/monitor-bg2.png) no-repeat;
  background-size: 43%;
  background-position: 120% -40%;
  background-origin: content-box;
}

.monitor .flex-2-1 .side-part .part .bg-wrap.bg3 {
  background: url(../../assets/home/monitor-bg3.png) no-repeat;
  background-size: 43%;
  background-position: 120% -40%;
  background-origin: content-box;
}

.monitor .flex-2-1 .side-part .part .bg-wrap.bg4 {
  background: url(../../assets/home/monitor-bg4.png) no-repeat;
  background-size: 43%;
  background-position: 120% -40%;
  background-origin: content-box;
}

.monitor .flex-2-1 .side-part .part .text {
  color: #333;
  font-size: 20px;
  font-weight: bold;
}

.monitor .flex-2-1 .side-part .part .sub-text {
  color: #666;
  font-size: 15px;
}

.monitor .flex-2-1 .center-img {
  width: 417px;
}

.system .container .row {
  display: flex;
  justify-content: space-between;
}

.system .container .row .sys-width {
  width: 50%;
}

.system .container .row .sys-width2 {
  width: 45%;
  margin-left: 5%;
}

.system .container .row .sys-width img {
  margin-top: 80px;
}

.system .container .row .sys-width h2 {
  text-align: left;
}

.system .container .row .sys-width h4 {
  text-align: left;
  font-size: 14px;
}

.system .container .row .swipper {
  /* margin-top: 40px; */
  height: 400px;
}

.system .container .row .swipper .swipper-con {
  margin-bottom: 28px;
}

.system .container .row .swipper .swipper-con .swipper-title {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.system .container .row .swipper .swipper-con .swipper-desc {
  font-size: 14px;
  color: #666;
  margin-left: 30px;
  line-height: 26px;
}

.system .container .row .swipper .swipper-con .swipper-title .swipper-title-bg {
  background: #e2ecff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
  margin-right: 10px;
}

.system .container .row .swipper .swipper-con .swipper-title .swipper-title-bg .swipper-title-dian {
  background: #186fff;
  position: absolute;
  right: 35%;
  top: 35%;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  text-align: center;
}

.sys-text-desc {
  line-height: 26px;
}

.sys-text-center {
  text-align: center;
}

.change {
  background: #f9fbff;
}

.change .container .row .flex-8 .flex-width {
  width: 22%;
  padding: 30px 0 0 0;
  border-radius: 20px;
}

.change .container .row .flex-8 .flex-width img {
  width: 72px;
  height: 72px;
}

.change .container .row .flex-8 .flex-width .title {
  font-size: 24px;
}

.change .container .row .flex-8 .flex-width {
  background: white;
}

.change .container .row .flex-8 .flex-width .change_desc1 {
  font-size: 16px;
  margin-bottom: 23px;
}

.change .container .row .flex-8 .flex-width .change_desc2 {
  font-size: 13px;
  color: #666;
  letter-spacing: 0.77px;
  line-height: 26px;
}

.change .container .row .flex-8 .flex-width .change_bg {
  background: url('../../assets//home/change_bg.png') no-repeat;
  background-size: 100% 100%;
  height: 111px;
  padding-top: 15px;
  color: white;
  font-size: 18px;
  margin-top: 20px;
  border-bottom-right-radius: 20px;
}

.change .container .row .flex-8 .flex-width .change_bg .number {
  font-size: 32px;
  display: flex;
  justify-content: center;
}

.achievement .ach {
  background: url('../../assets//home/ach_map.png') no-repeat;
  background-size: 100% 100%;
  margin-top: -50px;
  height: 596px;
  width: 100%;
}

.achievement .ach .ach-title {
  margin-top: 40px;
  color: #000;
  font-size: 33px;
  font-weight: 600;
  text-align: center;
}

.achievement .ach .ach-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.achievement .ach .flex-width {
  width: 25%;
  font-size: 18px;
  margin-top: 50px;
  color: #666;
  /* padding-left: 7%; */
}

.profuile .container .row .flex-8 .flex-width .details {
  display: none;
  -webkit-transition: All 0.4s;
}

.profuile .container .row .flex-8 .flex-width .details .details_flex {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: white;
}

.profuile .container .row .flex-8 .flex-width .details .details_flex img {
  width: 14px;
  height: 14px;
  margin-left: 5px;
}

.profuile .container .row .flex-8 .flex-width:hover {
  background: linear-gradient(134deg, #00aaff -2%, #0085ff 60%);
  box-shadow: 0px 5px 12px 0px #ced3da;
  -webkit-transform: translate(0, -10px);
}

.profuile .container .row .flex-8 .flex-width:hover .change_desc1 {
  color: white;
}

.profuile .container .row .flex-8 .flex-width:hover .change_desc2 {
  color: white;
}

.profuile .container .row .flex-8 .flex-width:hover .title {
  color: white;
}

.profuile .container .row .flex-8 .flex-width:hover .desc {
  display: none;
}

/* 创建一个keyframes规则，用于定义动画的过渡效果 */
@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* 应用动画效果 */
.profuile .container .row .flex-8 .flex-width:hover .details {
  display: block;
  color: #fff;
  animation-name: fade-in;
  animation-duration: 2s;
  /* 动画完成所需时间 (s) */
  animation-fill-mode: forwards;
  /* 动画完成后保持最后状态 */
}

.achievement .ach .flex-width .flex-color {
  color: #186fff;
  font-weight: 500;
  font-size: 38px;
  display: flex;
}

.achievement .need {
  margin-top: -150px;
}

.achievement .need .ach-title {
  margin-bottom: 50px;
  font-weight: 600;
  color: #000;
  font-size: 33px;
  text-align: center;
}

.noleft {
  width: 18% !important;
}

.isleft {
  width: 14% !important;
  margin-left: 14%;
}

.isleft2 {
  width: 16% !important;
  margin-left: 12%;
}
</style>