<template>
  <div>
    <div class="tel">
      <div class="footer-free">
        <div class="title">立即获得试用</div>
        <div class="desc">留下您的联系方式，即可获得1V1免费专业咨询服务</div>
        <div class="free" @click="isDialogTel('footerFreeTel', 'tel')">申请试用</div>
      </div>
      <div class="footer-nav">
        <router-link :to="'/'">
          <div class="nav-dis">
            <div>首页</div>
            <img src="@/assets/footer-nav.png" alt="" />
          </div>
        </router-link>
        <router-link :to="'Application'">
          <div class="nav-dis">
            <div>应用场景</div>
            <img src="@/assets/footer-nav.png" alt="" />
          </div>
        </router-link>
        <router-link :to="'Customer'">
          <div class="nav-dis">
            <div>客户案例</div>
            <img src="@/assets/footer-nav.png" alt="" />
          </div>
        </router-link><router-link :to="'Purchase'">
          <div class="nav-dis">
            <div>购买</div>
            <img src="@/assets/footer-nav.png" alt="" />
          </div>
        </router-link>
        <router-link :to="'About'">
          <div class="nav-dis">
            <div>关于我们</div>
            <img src="@/assets/footer-nav.png" alt="" />
          </div>
        </router-link>
      </div>
      <div class="footer-f">
        <div class="footer-dis">
          <div class="gzh">
            <img src="@/assets/home/gzh.png" alt="" />
            <div>微信公众号</div>
          </div>
          <div class="gzh">
            <img src="@/assets/ewm.png" alt="" />
            <div>1对1的系统解答</div>
          </div>
        </div>
        <div class="beian">
          ©2022 创壹（上海）信息科技有限公司 | <a target="_blank" href="http://beian.miit.gov.cn">沪ICP备17004315号-4</a>
        </div>
      </div>
    </div>
    <div class="pc">
      <div class="profuile free-tria">
        <div class="container">
          <div class="row">
            <div class="goFree">立即获得试用</div>
            <div class="contact">
              留下您的联系方式，即可获得1对1免费咨询服务
            </div>
            <button class="carousel-free" @click="isDialog('footerFree', 'pc')" id="show8">
              申请试用
            </button>
          </div>
        </div>
      </div>
      <!-- <footer> -->
      <div class="footer-bg">
        <!--footer-->
        <div class="footer-top">
          <div class="container">
            <div class="company-name">创壹(上海)信息科技有限公司</div>
            <div class="con-flex">
              <div class="footer-flex">
                <div class="tel-text">全国统一服务热线</div>
                <div class="tel-number">400-0055-965</div>
                <div class="major">专业服务 全程支持</div>
              </div>
              <div class="footer-flex">
                <div class="new">新闻媒体</div>
                <div class="new-icon">
                  <div class="gzh-img">
                    <img src="@/assets/home/gzh.png" alt="" />
                  </div>
                  <div class="wx-flex wx-height">
                    <div class="wxicon-flex">
                      <img src="@/assets/home/wxicon.png" alt="" />
                      <div style="margin-left: 5px;">微信公众号</div>
                    </div>
                    <div class="more">了解更多请关注</div>
                  </div>
                </div>
              </div>
              <div class="footer-flex">
                <div class="new">联系我们</div>
                <div class="about">
                  <div class="address">地址：上海杨浦区军工路100号运河湾知识产权园18栋07室</div>
                  <div class="address email">邮箱：services@chuangyios.com</div>
                </div>
              </div>
            </div>
          </div>
          <div class="footer-button">
            ©2022 创壹（上海）信息科技有限公司 |
            <a target="_blank" href="http://beian.miit.gov.cn">沪ICP备17004315号-4</a>
          </div>
        </div>
      </div>
    </div>
    <hysc-dia v-if="visibleD" :visible.sync="visibleD" @getClickCancel="getClickClose"></hysc-dia>
    <hysc-tel-dia v-if="visibleT" :visible.sync="visibleT" @getClickCancel="getClickCloseTel"></hysc-tel-dia>
  </div>
</template>

<script>
import HyscDia from './Dialog/dialog.vue';
import HyscTelDia from './Dialog/telDialog.vue';
import { website } from '@/api/gw.js';

export default {
  props: {
    msg: String,
  },
  data() {
    return {
      visibleD: false,
      visibleT: false,
      luntan: 'http://lt.gaotaoshangmao.com/',
      chatBB: 'https://111.229.127.113:10001/webSocket',
    };
  },
  components: {
    HyscDia,
    HyscTelDia,
  },
  methods: {
    // 点击做记录
    getClick(val, type) {
      website({ type: val, name: type }).then((res) => {
        console.log(res, 'rrr');
      });
    },
    toLT() {
      window.location.href = this.luntan;
    },
    toBB() {
      window.location.href = this.chatBB;
    },
    isDialog(val, type) {
      this.getClick(val, type);
      this.visibleD = true
      // this.$router.push('/Purchase')
    },
    getClickClose() {
      this.visibleD = false;
    },
    isDialogTel(val, type) {
      this.getClick(val, type);
      this.visibleT = true;
    },
    getClickCloseTel() {
      this.visibleT = false;
    },
  },
  mounted() { },
};
</script>

<!-- tel 弹窗 -->
<style>
@media (max-width: 1200px) {
  :deep(.el-dialog) {
    width: 300px !important;
  }
}
</style>

<style scoped>
.pc {
  display: block;
}

.tel {
  display: none;
}

@media (max-width: 1200px) {

  /*  */
  .pc {
    display: none;
  }

  .tel {
    display: block;
  }

  .tel .footer-free {
    color: #fff;
    height: 230px;
    text-align: center;
    background: url(../../src/assets/footer-fre-bg.png) no-repeat;
    background-size: 100% 100%;
  }

  .tel .footer-free .title {
    font-size: 26px;
    padding: 30px 0;
    font-weight: 600;
  }

  .tel .footer-free .desc {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .tel .footer-free .free {
    width: 150px;
    height: 53px;
    border-radius: 26.25px;
    font-size: 16px;
    text-align: center;
    line-height: 53px;
    opacity: 1;
    margin: 0 auto;
    background: #34baef;
  }

  .tel .footer-nav .nav-dis {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
    padding: 20px 0;
  }

  .tel .footer-dis {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-top: 1px solid #eaebec;
    margin: 0 20px;
    padding: 20px 0 30px 0;
  }

  .tel .footer-dis img {
    width: 100px;
    height: 100px;
  }

  .tel .footer-dis .gzh {
    width: 32%;
    text-align: center;
  }

  .tel .beian {
    text-align: center;
    font-size: 12px;
    font-weight: normal;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.42px;
    color: #999999;
    padding-bottom: 30px;
  }

  .tel .beian a {
    color: #999999;
  }
}
</style>
<style scoped>
.footer {
  background: black;
}

footer .footer-bg {
  background: url('../../src/assets/home/footer_map.png') no-repeat;
  background-size: 100% 100%;
}

.free-tria {
  background: url('../../src/assets/home/contact-banner.jpg') no-repeat;
  background-size: 100% 100%;
  height: 268px;
}

.free-tria .goFree {
  font-size: 33px;
  font-weight: 500;
  color: #000;
}

.footer-flex .gzh-img img {
  width: 60px;
}

.free-tria .contact {
  font-size: 18px;
  color: #666;
}

.free-tria .carousel-free {
  width: 170px;
  height: 48px;
  line-height: 46px;
  background-color: #186fff;
  color: white;
  font-size: 18px;
  border-radius: 40px;
  text-align: center;
  margin-top: 34px;
  border: none;
}

.free-tria .container {
  padding: initial;
  border-bottom: none;
}

.container {
  padding-left: 0;
  padding-right: 0;
}

.foo {
  display: flex;
  justify-content: space-around;
  width: 1200px;
  margin: 0 auto;
  color: #f5f5f5;
  padding: 1% 0;
}

.foo_ div p img {
  width: 60%;
}

.foo_ div p {
  text-align: center;
}

.foo_p {
  margin-bottom: 10px;
  text-align: center;
}

.foo_p_d p {
  color: #c0c0c0;
  font-size: 12px;
}

.foo_p_d p:hover {
  color: #00bfff;
}

.foo2 {
  background: #000000;
  padding-bottom: 1%;
}

.foo_xx1 {
  display: flex;
  justify-content: space-between;
  color: #c0c0c0;
  width: 1200px;
  margin: 0 auto;
  border-bottom: 1px solid white;
  padding-bottom: 0.5%;
}

.foo_xx2 {
  display: flex;
  justify-content: space-between;
  color: #c0c0c0;
  width: 1200px;
  margin: 0 auto;
  padding-top: 0.5%;
}

.footerTel,
.footerTel2 {
  display: none;
}

/*Tel*/
@media screen and (max-width: 600px) {

  .footer,
  .foo2 {
    display: none;
  }

  .footerTel {
    display: block;
    background: black;
    color: #eeeeee;
    text-align: center;
  }

  .wechartTel img {
    width: 25%;
    padding: 2% 0;
  }

  .contactsTel {
    padding: 1% 0;
  }

  .addressTel {
    font-size: 12px;
    border-bottom: 1px solid;
    padding-bottom: 1%;
  }

  .baTel {
    font-size: 12px;
    padding-bottom: 1%;
  }
}
</style>