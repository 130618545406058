<template>
    <div class="link-phone">
        <el-popover placement="left-end" width="250" trigger="click">
            <div class="link-tips">
                <div class="link-title">添加企业微信</div>
                <div class="link-dec">为您提供1对1的系统解答</div>
                <img src="@/assets/qywx.png" alt="">
            </div>
            <div class="link-item yes-item" slot="reference">
                <img src="@/assets/home/wx-cion.png" alt="">
                微信客服
            </div>
        </el-popover>
        <el-popover placement="left-end" width="200" trigger="click">
            <div class="phone-tips">
                咨询电话：<span>400-0055-965</span>
            </div>
            <div class="link-item no-item" slot="reference">
                <img src="@/assets/home/phone-cion.png" alt="">
                电话沟通
            </div>
        </el-popover>
    </div>
</template>

<script>
export default {
    data() {
        return {
            visible: true
        }
    }
}
</script>

<style scoped>
.link-phone {
    position: fixed;
    right: 10px;
    top: 50%;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    z-index: 999;
}

.link-item {
    color: #333333;
    font-weight: normal;
    border: 1px solid #DFE2EB;
    width: 75px;
    height: 75px;
    display: flex;
    gap: 3px;
    font-size: 14px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.link-item img {
    width: 26px;
}

.yes-item {
    border-bottom: none !important;
    border-radius: 4px 4px 0 0;
}

.no-item {
    border-radius: 0 0 4px 4px;
}

.link-tips {
    font-weight: normal;
    color: #333333;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0px 0;
}

.link-tips img {
    width: 90%;
}

.link-title {
    font-size: 18px;
}

.link-dec {
    font-size: 16px;
}

.phone-tips span {
    color: #000000;
    font-weight: 700;
}
</style>