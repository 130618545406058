<template>
  <el-container>

    <el-header>
      <gt-header></gt-header>
    </el-header>

    <el-main>
      <router-view></router-view>
    </el-main>

    <Link />

    <el-footer>
      <gt-footer></gt-footer>
    </el-footer>

  </el-container>
</template>
<script>
import Link from './components/link/index.vue'
import Header from './components/Header';
import Footer from './components/Footer';

export default {
  components: {
    Link,
    'gt-header': Header,
    'gt-footer': Footer,
  },
};
</script>

<style>
/*Tel*/
@media screen and (max-width: 600px) {
  .el-main {
    /* margin-top: 0 !important; */
  }

  .el-header {
    height: auto !important;
  }
}

/* header star */
.el-header {
  padding: 0 !important;
  margin-top: 1%;
}

.el-main {
  padding: 0 !important;
  margin-top: 75px;
  overflow: hidden !important;
}

.el-footer {
  padding: 0 !important;
}

/* nav */
.breadcrumb {
  width: 80%;
  margin: 10px auto;
  position: relative;
  z-index: 23;
  display: flex;
  justify-content: space-between;
}

.pro_nav ul li {
  height: 40px;
  font-size: 1.5em;
}

/* 搜索 */
.el-input {
  width: 73% !important;
}

li {
  list-style: none;
}

img {
  vertical-align: top;
  border: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

address,
cite,
dfn,
em,
var {
  font-style: normal;
}

/* 将斜体扶正 */
code,
kbd,
pre,
samp {
  font-family: courier new, courier, monospace;
}

/* 统一等宽字体 */
small {
  font-size: 12px;
}

/** 重置列表元素 **/
ul,
ol {
  list-style: none;
}

/** 重置文本格式元素 **/
a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

sup {
  vertical-align: text-top;
}

/* 重置, 减少对行高的影响 */
sub {
  vertical-align: text-bottom;
}

/** 重置表单元素 **/
legend {
  color: #000;
}

/* for ie6 */
fieldset,
img {
  border: 0;
}

/* img 搭车：让链接里的 img 无边框 */
button,
input,
select,
textarea {
  font-size: 100%;
}

/* 使得表单元素在 ie 下能继承字体大小 */
/* 注：optgroup 无法扶正 */
/* 重置 HTML5 元素 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  display: block;
  margin: 0;
  padding: 0;
}

mark {
  background: #ff0;
}

:-moz-placeholder {
  color: #ddd;
  opacity: 1;
}

::-moz-placeholder {
  color: #ddd;
  opacity: 1;
}

input:-ms-input-placeholder {
  color: #ddd;
  opacity: 1;
}

input::-webkit-input-placeholder {
  color: #ddd;
  opacity: 1;
}

/* select标签 */
select {
  border: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  /*在选择框的最右侧中间显示小箭头图片*/
  background: url('http://ourjs.github.io/static/2015/arrow.png') no-repeat scroll right center transparent;
  padding-right: 14px;
}

/* 重置表格元素 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* 重置hr */
hr {
  border: none;
  height: 1px;
}

/* 清除< ol > < ul >的默认样式 */
ul ol {
  list-style: none;
}

/* .button按钮 */
.button {
  border: 0;
  background-color: none;
  outline: none;
  -webkit-appearance: none;
  /* 用于IOS下移除原生样式 */
}

/* < a >标签 */
a {
  text-decoration: none !important;
  color: #333;
}

/* < input >标签 */
input {
  border: none;
  appearance: none;
  -moz-appearance: none;
  outline: none;
  -webkit-appearance: none;
}

input:focus {
  outline: none;
}
</style>
