<template>
    <div class="form-wrap" id="formRegRef">
        <div class="container">
            <div class="header">注册信息</div>
            <el-form :model="form" :rules="rules" label-position="top" ref="ruleForm">
                <div class="row-flex">
                    <el-form-item label="版本选择" prop="version">
                        <el-select v-model="form.version" placeholder="请选择版本">
                            <el-option v-for="item in options" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="姓名" prop="name">
                        <el-input placeholder="请输入您的姓名" v-model="form.name" autocomplete="off"></el-input>
                    </el-form-item>
                </div>
                <div class="row-flex">
                    <el-form-item label="联系电话" prop="phone">
                        <el-input placeholder="请输入您的联系电话" v-model="form.phone" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="验证码" prop="code">
                        <el-input placeholder="请输入验证码" v-model="form.code" autocomplete="off">
                            <template v-slot:append v-if="!isCodeM">
                                <span class="getNum" @click="handleGetCode()">获取验证码</span>
                            </template>
                            <template v-slot:append v-else>
                                <span class="codeNum">{{ codeM }}秒</span>
                            </template>
                        </el-input>
                    </el-form-item>
                </div>
                <el-form-item label="企业名称" prop="company">
                    <el-input placeholder="请输入企业的全称" v-model="form.company" autocomplete="off"></el-input>
                </el-form-item>
                <div class="row-flex">
                    <el-form-item label="职位">
                        <el-input placeholder="请输入您的职位" v-model="form.postType" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="工作邮箱">
                        <el-input placeholder="请输入您的工作邮箱" v-model="form.email" autocomplete="off"></el-input>
                    </el-form-item>
                </div>
            </el-form>
            <div class="footer">
                <div class="footer-tips">
                    提交表单视为您已同意
                    <a href="https://hysc.creatoneos.com/privacy/index.html" target="_blank">《用户协议与隐私政策》</a>
                </div>
                <button class="footer-btn" @click="submit">提交</button>
            </div>
        </div>
        <div class="codeH" v-if="codeH">
            <slide-verify :l="42" :r="10" :w="310" :h="255" :imgs="picArray" slider-text="向右滑动" @success="onSuccess"
                @fail="onFail" @refresh="onRefresh"></slide-verify>
            <div>{{ msg }}</div>
        </div>
    </div>
</template>

<script>
import { postForm, websiteCode } from '@/api/gw.js'

export default {
    data() {
        return {
            picArray: [require('@/assets/huakuai.png')],
            codeM: 60,
            msg: '',
            isCodeM: false,
            codeH: false,
            options: [
                { label: '启航版', value: '89' },
                { label: '领航版', value: '90' },
                { label: '旗舰版', value: '91' },
                { label: '智慧人事版', value: '97' },
                { label: '智能管理版', value: '98' },
                { label: '合规经营版', value: '95' },
                { label: '智慧招聘版', value: '96' }
            ],
            form: {
                name: '',
                phone: '',
                company: '',
                postType: '',
                email: '',
                version: '89',
                code: '',
            },
            rules: {
                version: [{ required: true, message: '请选择版本' }],
                name: [{ required: true, message: '请输入姓名' }],
                phone: [{ required: true, message: '请输入联系电话' }],
                company: [{ required: true, message: '请输入企业名称' }],
                code: [{ required: true, message: '请输入验证码' }],
            }
        }
    },
    methods: {
        // 获取验证码
        getCode() {
            websiteCode({ phone: this.form.phone }).then((res) => {
                if (res.data.code == 500) {
                    this.$message.error(res.data.msg)
                } else if (res.data.code == '200') {
                    this.$message.success('操作成功')
                }
            })
        },
        // 计算时间
        getCodeM() {
            if (!this.isRunning) {
                this.isRunning = true
                const timerId = setInterval(() => {
                    if (this.codeM <= 1) {
                        clearInterval(timerId)
                        this.reset()
                    } else {
                        this.codeM--
                    }
                }, 1000)
            }
        },
        handleGetCode() {
            if (this.form.phone) {
                this.codeH = true;
            } else {
                this.$message({
                    showClose: true,
                    message: '请输入手机号',
                    type: 'error',
                });
            }
        },
        onSuccess() {
            this.codeH = false
            this.isCodeM = true
            this.getCodeM()
            this.getCode()
        },
        onRefresh() {
            this.msg = ''
        },
        onFail() {
            this.onRefresh()
            this.msg = ''
        },
        submit() {
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    postForm(this.form).then((res) => {
                        if (res.data.code == 200) {
                            this.$message({
                                showClose: true,
                                message: res.data.msg,
                                type: 'success',
                            });
                        } else {
                            this.$message({
                                showClose: true,
                                message: res.data.msg,
                                type: 'error',
                            })
                        }
                    })
                } else {
                    return false
                }
            })
        }
    },
}
</script>

<style scoped>
:deep(.el-input) {
    width: 100% !important;
}

.el-select {
    width: 100% !important;
}

:deep(.el-input__icon) {
    line-height: 30px !important;
}

.form-wrap {
    margin-top: 10px;
}

.container {
    box-shadow: 0px 5px 12px 0px #CED3DA;
    padding: 0;
}

.header {
    font-size: 24px;
    color: #222A41;
    height: 78px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

:deep(.el-form) {
    padding: 15px 20% 0 20%;
    min-width: 500px;
    background: #F3F3F5;
}

:deep(.el-form-item__content) {
    line-height: 1.3;
}

.row-flex {
    display: flex;
    align-items: center;
    gap: 20px;
}

.row-flex .el-form-item {
    width: calc((100% - 20px) / 2);
}

:deep(.row-flex .el-input-group__append) {
    background: #FFFFFF;
    color: #186FFF;
    border-left: 0;
}

.getNum {
    cursor: pointer;
}

.el-input {
    width: 100% !important;
    height: 30px;
}

:deep(.el-input__inner) {
    height: 30px;
}

:deep(.el-form-item__label) {
    line-height: 1 !important;
    padding: 0;
    margin: 0 0 5px 0;
    color: #666666;
}

.footer {
    height: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.footer .footer-tips {
    font-size: 12px;
    font-weight: normal;
    color: #3D3D3D;
}

.footer .footer-tips a {
    color: #1C70F1;
}

.footer .footer-btn {
    border: 0px solid #186FFF;
    background: #186FFF;
    color: #FFFFFF;
    border-radius: 4px;
    height: 40px;
    letter-spacing: 10px;
    min-width: 120px;
    width: 25%;
}
</style>