<template>
  <div>
    <div class="tel">
      <div class="posiHeight" :class="!isAct ? 'freePosiHeight' : ''">
        <div class="banner">
          <div class="teldesc">
            <div class="teld1">聚焦AI技术和大数据技术的研发与应用</div>
            <div class="teld3">
              致力于企业发展过程中发现、识别人才能力与价值
            </div>
          </div>
        </div>
        <div class="mTop" @touchstart="handleTouchStart" @touchmove="handleTouchMove" @touchend="handleTouchEnd"
          :class="!isAct ? 'freeMTop' : ''">
          <div class="mtop_head">
            <img v-if="isAct == 1" src="@/assets/purchase/newVersion/ver98.png" alt="" />
            <img v-if="isAct == 2" src="@/assets/purchase/newVersion/ver97.png" alt="" />
            <img v-if="isAct == 3" src="@/assets/purchase/start-type.png" alt="" />
            <img v-if="isAct == 4" src="@/assets/purchase/lead-type.png" alt="" />
            <img class="version-scale" v-if="isAct == 5" src="@/assets/purchase/newVersion/ver91.png" alt="" />
            <img v-if="isAct == 6" src="@/assets/purchase/newVersion/ver95.png" alt="" />
            <img v-if="isAct == 7" src="@/assets/purchase/newVersion/ver96.png" alt="" />
            <div>
              <div class="title">
                {{ isAct == 1 ? '智能管理版' : isAct == 2 ? '智慧人事版' : isAct == 3 ? '启航版' : isAct == 4 ? '领航版' : isAct == 5 ?
                  '旗舰版' : isAct == 6 ? '合规经营版' : '智慧招聘版' }}</div>
              <div class="line">
                <span v-if="isAct == 1">企业员工规模≤30（支持扩展）</span>
                <span v-if="isAct == 2">企业员工规模≤50（支持扩展）</span>
                <span v-if="isAct == 3">100≥企业员工规模≥10</span>
                <span v-if="isAct == 4">100≥企业员工规模≥10</span>
                <span v-if="isAct == 5">企业员工规模≥100名，管理员≥5名</span>
                <span v-if="isAct == 6">企业员工规模≤30（支持扩展）</span>
                <span v-if="isAct == 7">企业员工规模≤30（支持扩展）</span>
              </div>
            </div>
          </div>
          <div class="desc">
            <div class="money"
              :style="{ color: isAct == 1 ? '#004BA8' : isAct == 2 ? '#82BB85' : isAct == 3 ? '#25ADFF' : isAct == 4 ? '#5B65FA' : isAct == 6 ? '#5BC0EB' : isAct == 7 ? '#386641' : '#FFA93A' }">
              <div>￥</div>
              <div v-if="isAct == 1" class="number">3,980</div>
              <div v-if="isAct == 2" class="number">3,980</div>
              <div v-if="isAct == 3" class="number">29,800</div>
              <div v-if="isAct == 4" class="number">58,000</div>
              <div v-if="isAct == 5" class="number">168,000</div>
              <div v-if="isAct == 6" class="number">998</div>
              <div v-if="isAct == 7" class="number">998</div>
              <div>&nbsp;/年</div>
            </div>
            <div class="pay"
              :style="{ background: isAct == 1 ? '#004BA8' : isAct == 2 ? '#82BB85' : isAct == 3 ? '#25ADFF' : isAct == 4 ? '#5B65FA' : isAct == 6 ? '#5BC0EB' : isAct == 7 ? '#386641' : '#FFA93A' }"
              @click="isDialogTel('enterpriseFreeTel', 'tel')">
              咨询购买
            </div>
            <div v-if="isAct == 1">
              <div class="line_dis">
                <img src="@/assets/purchase/check-green.png" alt="" />
                <div>员工全生命周期管理</div>
              </div>
              <div class="line_dis">
                <img src="@/assets/purchase/check-green.png" alt="" />
                <div>绩效规则灵活配置，AI绩效顾问</div>
              </div>
              <div class="line_dis">
                <img src="@/assets/purchase/check-green.png" alt="" />
                <div>员工各项能力测评，促进团队发展</div>
              </div>
              <div class="line_dis">
                <img src="@/assets/purchase/check-green.png" alt="" />
                <div>人才盘点，提升团队效能</div>
              </div>
            </div>
            <div v-if="isAct == 2">
              <div class="line_dis">
                <img src="@/assets/purchase/check-green.png" alt="" />
                <div>人员组织管理，提高效能</div>
              </div>
              <div class="line_dis">
                <img src="@/assets/purchase/check-green.png" alt="" />
                <div>企业合规风险实时监测</div>
              </div>
              <div class="line_dis">
                <img src="@/assets/purchase/check-green.png" alt="" />
                <div>多维度图表分析，数据指导决策</div>
              </div>
              <div class="line_dis">
                <img src="@/assets/purchase/check-green.png" alt="" />
                <div>企业、人才价值体系</div>
              </div>
            </div>
            <div v-if="isAct == 3">
              <div class="line_dis">
                <img src="@/assets/purchase/check-green.png" alt="" />
                <div>打通招聘网站，多渠道简历聚合</div>
              </div>
              <div class="line_dis">
                <img src="@/assets/purchase/check-green.png" alt="" />
                <div>员工全生命周期管理</div>
              </div>
              <div class="line_dis">
                <img src="@/assets/purchase/check-green.png" alt="" />
                <div>多维度图表分析，数据指导决策</div>
              </div>
              <div class="line_dis">
                <img src="@/assets/purchase/check-green.png" alt="" />
                <div>人员组织管理，提高效能</div>
              </div>
            </div>
            <div v-if="isAct == 4">
              <div class="line_dis">
                <img src="@/assets/purchase/check-green.png" alt="" />
                <div><span style="color: #5B65FA;">领航版</span>包含<span style="color: #25ADFF;">启航版</span>全部功能</div>
              </div>
              <div class="line_dis">
                <img src="@/assets/purchase/check-green.png" alt="" />
                <div>绩效规则灵活配置，专业顾问指导</div>
              </div>
              <div class="line_dis">
                <img src="@/assets/purchase/check-green.png" alt="" />
                <div>员工各项能力测评，促进团队发展</div>
              </div>
              <div class="line_dis">
                <img src="@/assets/purchase/check-green.png" alt="" />
                <div>人才盘点，提升团队效能</div>
              </div>
              <div class="line_dis">
                <img src="@/assets/purchase/check-green.png" alt="" />
                <div>可持续发展的合规风险监测服务</div>
              </div>
            </div>
            <div v-if="isAct == 5">
              <div class="line_dis">
                <img src="@/assets/purchase/check-green.png" alt="" />
                <div><span style="color:  #FFA93A;">旗舰版</span>包含<span style="color: #5B65FA;">领航版</span>全部功能</div>
              </div>
              <div class="line_dis">
                <img src="@/assets/purchase/check-green.png" alt="" />
                <div>多场景生成式AI模型调用</div>
              </div>
              <div class="line_dis">
                <img src="@/assets/purchase/check-green.png" alt="" />
                <div>企业战略管理，商业画布、经营分析</div>
              </div>
              <div class="line_dis">
                <img src="@/assets/purchase/check-green.png" alt="" />
                <div>企业、人才价值体系</div>
              </div>
              <div class="line_dis">
                <img src="@/assets/purchase/check-green.png" alt="" />
                <div>人事数据分析报告</div>
              </div>
              <div class="line_dis">
                <img src="@/assets/purchase/check-green.png" alt="" />
                <div>多家企业一键绑定同步管理</div>
              </div>
            </div>
            <div v-if="isAct == 6">
              <div class="line_dis">
                <img src="@/assets/purchase/check-green.png" alt="" />
                <div>市场动态信息实时推送</div>
              </div>
              <div class="line_dis">
                <img src="@/assets/purchase/check-green.png" alt="" />
                <div>企业人力成本支出把控</div>
              </div>
              <div class="line_dis">
                <img src="@/assets/purchase/check-green.png" alt="" />
                <div>企业合规风险实时监测</div>
              </div>
              <div class="line_dis">
                <img src="@/assets/purchase/check-green.png" alt="" />
                <div>企业、人才价值体系</div>
              </div>
            </div>
            <div v-if="isAct == 7">
              <div class="line_dis">
                <img src="@/assets/purchase/check-green.png" alt="" />
                <div>打通招聘网站，多渠道简历聚合</div>
              </div>
              <div class="line_dis">
                <img src="@/assets/purchase/check-green.png" alt="" />
                <div>候选人能力素质评估报告</div>
              </div>
              <div class="line_dis">
                <img src="@/assets/purchase/check-green.png" alt="" />
                <div>企业招聘全流程一键式管理</div>
              </div>
              <div class="line_dis">
                <img src="@/assets/purchase/check-green.png" alt="" />
                <div>多维度图表分析，数据指导决策</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="isActC">
        <div @click="getIsAct(1)" :class="isAct == 1 ? 'active' : 'noActive'"></div>
        <div @click="getIsAct(2)" :class="isAct == 2 ? 'active' : 'noActive'"></div>
        <div @click="getIsAct(3)" :class="isAct == 3 ? 'active' : 'noActive'"></div>
        <div @click="getIsAct(4)" :class="isAct == 4 ? 'active' : 'noActive'"></div>
        <div @click="getIsAct(5)" :class="isAct == 5 ? 'active' : 'noActive'"></div>
        <div @click="getIsAct(6)" :class="isAct == 6 ? 'active' : 'noActive'"></div>
        <div @click="getIsAct(7)" :class="isAct == 7 ? 'active' : 'noActive'"></div>
      </div>
      <!-- 表单 -->
      <FormTel />
      <!-- 版本详细 -->
      <div class="page-container" v-show="isAct == 3 || isAct == 4 || isAct == 5">
        <div class="container">
          <div class="version-detail-wrap">
            <div class="detail-header">
              <div class="header-title">版本明细</div>
            </div>
            <div class="detail_dis">
              <div v-if="isAct == 3" class="detail-header free-l">
                <div class="header-free">
                  <div>(启航版)</div>
                </div>
              </div>
              <div v-if="isAct == 4" class="detail-header free-l">
                <div class="header-free">
                  <div>(领航版)</div>
                </div>
              </div>
              <div v-if="isAct == 5" class="detail-header">
                <div class="header-enterprise">
                  <div class="header-ent-w">(旗舰版)</div>
                  <div class="header-ent-po">
                    <img src="@/assets/purchase/purchicon.png" alt="" />
                    <span>推荐</span>
                  </div>
                </div>
              </div>
              <div class="detail-legend">
                <div>
                  <img src="@/assets/purchase/no-icon.png" alt="" />
                  <span>未开通</span>
                </div>
                <!-- <div>
                  <img src="@/assets/purchase/true-icon-gray.png" alt="" />
                  <span>限免</span>
                </div> -->
                <div>
                  <img src="@/assets/purchase/true-icon.png" alt="" />
                  <span>畅享</span>
                </div>
              </div>
            </div>
            <div class="detail-content">
              <div class="content-item">
                <div class="item-title isBold">适用端</div>
                <div v-if="isAct == 3" class="item-free"></div>
                <div v-if="isAct == 4" class="item-enterprise"></div>
                <div v-if="isAct == 5" class="item-enterprise"></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">电脑端</div>
                <div class="item-free">
                  <img src="@/assets/purchase/true-icon.png" alt="" />
                </div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">移动端</div>
                <div class="item-free">
                  <img src="@/assets/purchase/true-icon.png" alt="" />
                </div>
              </div>
              <div class="segment-line"></div>

              <!-- 技术服务 -->
              <div class="content-item content-empty">
                <div class="item-title"></div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="content-item">
                <div class="item-title isBold">技术服务</div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">5*36在线客服</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">代办设计与管理服务</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">操作向导与帮助指导</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">Q&A反馈</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">指导落地服务</div>
                <div class="item-free" v-if="isAct == 3">提供1次免费试用</div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item content-empty">
                <div class="item-title"></div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="segment-line"></div>



              <!-- 新体验 -->
              <div class="content-item content-empty">
                <div class="item-title"></div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="content-item">
                <div class="item-title isBold">新体验</div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">新功能推送与尝鲜</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item content-empty">
                <div class="item-title"></div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="segment-line"></div>



              <!-- 数字仪表板 -->
              <div class="content-item content-empty">
                <div class="item-title"></div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="content-item">
                <div class="item-title isBold">数字仪表板</div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">待办事项提醒</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">实时热点事件推送</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">成本核算预警</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">多组织切换</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">人事数据分析报告</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">招聘渠道分析</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">任务进度分析</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item content-empty">
                <div class="item-title"></div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="segment-line"></div>



              <!-- 战略管理 -->
              <div class="content-item content-empty">
                <div class="item-title"></div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="content-item">
                <div class="item-title isBold">战略管理</div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 45"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">AI商业画布</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/no-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">商业画布管理</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/no-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">发展大事记</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/no-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">经营分析</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/no-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">AI解读报告</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/no-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4">提供1次免费试用</div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item content-empty">
                <div class="item-title"></div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="segment-line"></div>

              <!-- 招聘管理 -->
              <div class="content-item content-empty">
                <div class="item-title"></div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="content-item">
                <div class="item-title isBold">招聘管理</div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">经营分析</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">一键发布岗位</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">JD统一管理</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">岗位候选人画像</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">一键沟通</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">职位分享</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">自建简历库</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">候选人标签管理</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">一键收录简历</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">人才PK</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">智慧JD编写</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">参考薪资</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">简历自动收录</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">面试分析</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">一键入职</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">综合匹配度</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">智慧面试话术</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">到岗预测分析</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">智能简历解析</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">候选人评估报告</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">能力与素质测评</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">校招管理</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">面试管理</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item content-empty">
                <div class="item-title"></div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="segment-line"></div>



              <!-- 薪酬管理 -->
              <div class="content-item content-empty">
                <div class="item-title"></div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="content-item">
                <div class="item-title isBold">薪酬管理</div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">人力成本</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">成本分析</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item content-empty">
                <div class="item-title"></div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="segment-line"></div>



              <!-- 绩效管理 -->
              <div class="content-item content-empty">
                <div class="item-title"></div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="content-item">
                <div class="item-title isBold">绩效管理</div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">AI绩效模型</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/no-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4">提供1次免费试用</div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">绩效方案管理</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">绩效指标管理</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">到期提醒管理</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">绩效报告分析</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">绩效申诉管理</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item content-empty">
                <div class="item-title"></div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="segment-line"></div>


              <!-- 安全合规 -->
              <div class="content-item content-empty">
                <div class="item-title"></div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="content-item">
                <div class="item-title isBold">安全合规</div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">用工风险看板</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/no-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">风险提示</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/no-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">合规风险监测</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/no-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">效能风险监测</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/no-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">操作留痕</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item content-empty">
                <div class="item-title"></div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="segment-line"></div>


              <!-- 人才盘点 -->
              <div class="content-item content-empty">
                <div class="item-title"></div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="content-item">
                <div class="item-title isBold">人才盘点</div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">AI盘点模型</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/no-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4">提供1次免费试用</div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">AI评估模型</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/no-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4">提供1次免费试用</div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">人才盘点方案</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/no-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">盘点分析看板</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">人才价值对比</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/no-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">能力测评管理</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">测评报告分析</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/no-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">人才评估方案</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/no-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">贡献价值模型</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">评估指标管理</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/no-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">人才评估报告</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/no-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">人才地图</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/no-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/no-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/no-icon.png" alt=""></div>
              </div>
              <div class="content-item content-empty">
                <div class="item-title"></div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="segment-line"></div>



              <!-- 价值体系 -->
              <div class="content-item content-empty">
                <div class="item-title"></div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="content-item">
                <div class="item-title isBold">价值体系</div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">人才价值分</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">企业价值分</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item content-empty">
                <div class="item-title"></div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="segment-line"></div>

              <!-- 组织管理 -->
              <div class="content-item content-empty">
                <div class="item-title"></div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="content-item">
                <div class="item-title isBold">组织管理</div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">组织管理</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">组织架构图</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">职位管理</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">职级管理 </div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">人事权限管理</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">员工管理</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">人才价值体系</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">一键离职</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">员工AI身份卡</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">员工数字档案</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">企业花名册</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">人事异动追踪</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item content-empty">
                <div class="item-title"></div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="segment-line"></div>



              <!-- 企业档案 -->
              <div class="content-item content-empty">
                <div class="item-title"></div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="content-item">
                <div class="item-title isBold">企业档案</div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
              <div class="content-item isHover">
                <div class="item-title">企业档案</div>
                <div class="item-free" v-if="isAct == 3"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 4"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free" v-if="isAct == 5"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item content-empty">
                <div class="item-title"></div>
                <div class="item-free" v-if="isAct == 3"></div>
                <div class="item-free" v-if="isAct == 4"></div>
                <div class="item-free" v-if="isAct == 5"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pc">
      <div class="fbwh banner_img banner_po">
        <img src="@/assets/purchase/purchase-banner.png" alt="" />
        <div class="banner_ab">
          <div class="app_title">聚焦AI技术和大数据技术的研发与应用</div>
          <div class="app_desc1">致力于帮助企业持续提高效能并规避合规风险</div>
        </div>
      </div>
      <div class="version-wrap">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="enterprise-version" style="border-top: 17px solid #004BA8;">
                <img class="version-img" src="@/assets/purchase/newVersion/ver98.png" alt="">
                <div class="version-name" style="color: #004BA8;">智能管理版</div>
                <div class="version-desc">企业员工规模≤30（支持扩展）</div>
                <div class="enterprise-price" style="color: #004BA8;">
                  <span>￥</span>3,980<span>/年</span>
                </div>
                <div class="enterprise-btn">
                  <button style="background: linear-gradient(0deg, #004BA8, #004BA8), #004BA8;"
                    @click="handleBuy">咨询购买</button>
                </div>
                <div class="list" style="width: 80%">
                  <div class="list-item">
                    <img src="@/assets/purchase/check-green.png" alt="">
                    <div>人员组织管理，提高效能</div>
                  </div>
                  <div class="list-item">
                    <img src="@/assets/purchase/check-green.png" alt="">
                    <div>企业合规风险实时监测</div>
                  </div>
                  <div class="list-item">
                    <img src="@/assets/purchase/check-green.png" alt="">
                    <div>多维度图表分析，数据指导决策</div>
                  </div>
                  <div class="list-item">
                    <img src="@/assets/purchase/check-green.png" alt="">
                    <div>企业、人才价值体系</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="enterprise-version" style="border-top: 17px solid #82BB85;">
                <img class="version-img" src="@/assets/purchase/newVersion/ver97.png" alt="">
                <div class="version-name" style="color: #82BB85;">智慧人事版</div>
                <div class="version-desc">企业员工规模≤50（支持扩展）</div>
                <div class="enterprise-price" style="color: #82BB85;">
                  <span>￥</span>3,980<span>/年</span>
                </div>
                <div class="enterprise-btn">
                  <button style="background: #82BB85;" @click="handleBuy">咨询购买</button>
                </div>
                <div class="list" style="width: 80%">
                  <div class="list-item">
                    <img src="@/assets/purchase/check-green.png" alt="">
                    <div>员工全生命周期管理</div>
                  </div>
                  <div class="list-item">
                    <img src="@/assets/purchase/check-green.png" alt="">
                    <div>绩效规则灵活配置，AI绩效顾问</div>
                  </div>
                  <div class="list-item">
                    <img src="@/assets/purchase/check-green.png" alt="">
                    <div>员工各项能力测评，促进团队发展</div>
                  </div>
                  <div class="list-item">
                    <img src="@/assets/purchase/check-green.png" alt="">
                    <div>人才盘点，提升团队效能</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="enterprise-version start-version">
                <img class="version-img" src="@/assets/purchase/start-type.png" alt="">
                <div class="version-name">启航版</div>
                <div class="version-desc">100≥企业员工规模≥10</div>
                <div class="enterprise-price">
                  <span>￥</span>29,800<span>/年</span>
                </div>
                <div class="enterprise-btn"><button @click="handleBuy">咨询购买</button></div>
                <div class="list" style="width: 80%">
                  <div class="list-item">
                    <img src="@/assets/purchase/check-green.png" alt="">
                    <div>打通招聘网站，多渠道简历聚合</div>
                  </div>
                  <div class="list-item">
                    <img src="@/assets/purchase/check-green.png" alt="">
                    <div>员工全生命周期管理</div>
                  </div>
                  <div class="list-item">
                    <img src="@/assets/purchase/check-green.png" alt="">
                    <div>多维度图表分析，数据指导决策</div>
                  </div>
                  <div class="list-item">
                    <img src="@/assets/purchase/check-green.png" alt="">
                    <div>人员组织管理，提高效能</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="swiper-slide" data-swiper-parallax-scale="0.2">
              <div class="enterprise-version lead-version">
                <img class="version-img" src="@/assets/purchase/lead-type.png" alt="">
                <div class="version-name">领航版</div>
                <div class="version-desc">100≥企业员工规模≥10</div>
                <div class="enterprise-price">
                  <span>￥</span>58,000<span>/年</span>
                </div>
                <div class="enterprise-btn"><button @click="handleBuy">咨询购买</button></div>
                <div class="list" style="width:80%">
                  <div class="list-item">
                    <img src="@/assets/purchase/check-green.png" alt="">
                    <div><span style="color: #5B65FA;">领航版</span>包含<span style="color: #25ADFF;">启航版</span>全部功能</div>
                  </div>
                  <div class="list-item">
                    <img src="@/assets/purchase/check-green.png" alt="">
                    <div>绩效规则灵活配置，专业顾问指导</div>
                  </div>
                  <div class="list-item">
                    <img src="@/assets/purchase/check-green.png" alt="">
                    <div>员工各项能力测评，促进团队发展</div>
                  </div>
                  <div class="list-item">
                    <img src="@/assets/purchase/check-green.png" alt="">
                    <div>人才盘点，提升团队效能</div>
                  </div>
                  <div class="list-item">
                    <img src="@/assets/purchase/check-green.png" alt="">
                    <div>可持续发展的合规风险监测服务</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="enterprise-version enter-version">
                <img class="version-img version-scale" src="@/assets/purchase/newVersion/ver91.png" alt="">
                <div class="version-name">旗舰版</div>
                <div class="version-desc">企业员工规模≥100名，管理员≥5名</div>
                <div class="enterprise-price">
                  <span>￥</span>168,000<span>/年</span>
                </div>
                <div class="enterprise-btn"><button @click="handleBuy">咨询购买</button></div>
                <div class="list" style="width: 80%">
                  <div class="list-item">
                    <img src="@/assets/purchase/check-green.png" alt="">
                    <div><span style="color:  #FFA93A;">旗舰版</span>包含<span style="color: #5B65FA;">领航版</span>全部功能</div>
                  </div>
                  <div class="list-item">
                    <img src="@/assets/purchase/check-green.png" alt="">
                    <div>多场景生成式AI模型调用</div>
                  </div>
                  <div class="list-item">
                    <img src="@/assets/purchase/check-green.png" alt="">
                    <div>企业战略管理，商业画布、经营分析</div>
                  </div>
                  <div class="list-item">
                    <img src="@/assets/purchase/check-green.png" alt="">
                    <div>企业、人才价值体系</div>
                  </div>
                  <div class="list-item">
                    <img src="@/assets/purchase/check-green.png" alt="">
                    <div>人事数据分析报告</div>
                  </div>
                  <div class="list-item">
                    <img src="@/assets/purchase/check-green.png" alt="">
                    <div>多家企业一键绑定同步管理</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="enterprise-version" style="border-top: 17px solid #5BC0EB;">
                <img class="version-img" src="@/assets/purchase/newVersion/ver95.png" alt="">
                <div class="version-name" style="color: #5BC0EB;">合规经营版</div>
                <div class="version-desc">企业员工规模≤30（支持扩展）</div>
                <div class="enterprise-price" style="color: #5BC0EB;">
                  <span>￥</span>998<span>/年</span>
                </div>
                <div class="enterprise-btn">
                  <button style="background: linear-gradient(0deg, #5BC0EB, #5BC0EB), #5BC0EB;"
                    @click="handleBuy">咨询购买</button>
                </div>
                <div class="list" style="width: 80%">
                  <div class="list-item">
                    <img src="@/assets/purchase/check-green.png" alt="">
                    <div>市场动态信息实时推送</div>
                  </div>
                  <div class="list-item">
                    <img src="@/assets/purchase/check-green.png" alt="">
                    <div>企业人力成本支出把控</div>
                  </div>
                  <div class="list-item">
                    <img src="@/assets/purchase/check-green.png" alt="">
                    <div>企业合规风险实时监测</div>
                  </div>
                  <div class="list-item">
                    <img src="@/assets/purchase/check-green.png" alt="">
                    <div>企业、人才价值体系</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="enterprise-version" style="border-top: 17px solid #386641;">
                <img class="version-img" src="@/assets/purchase/newVersion/ver96.png" alt="">
                <div class="version-name" style="color: #386641;">智慧招聘版</div>
                <div class="version-desc">企业员工规模≤30（支持扩展）</div>
                <div class="enterprise-price" style="color: #386641;">
                  <span>￥</span>998<span>/年</span>
                </div>
                <div class="enterprise-btn">
                  <button style="background: linear-gradient(0deg, #386641, #386641), #386641;"
                    @click="handleBuy">咨询购买</button>
                </div>
                <div class="list" style="width: 80%">
                  <div class="list-item">
                    <img src="@/assets/purchase/check-green.png" alt="">
                    <div>打通招聘网站，多渠道简历聚合</div>
                  </div>
                  <div class="list-item">
                    <img src="@/assets/purchase/check-green.png" alt="">
                    <div>候选人能力素质评估报告</div>
                  </div>
                  <div class="list-item">
                    <img src="@/assets/purchase/check-green.png" alt="">
                    <div>企业招聘全流程一键式管理</div>
                  </div>
                  <div class="list-item">
                    <img src="@/assets/purchase/check-green.png" alt="">
                    <div>多维度图表分析，数据指导决策</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Add Pagination -->
          <div class="swiper-pagination"></div>

          <div class="pre-swiper-prev" v-show="isBtn" @click="handleCk('prev')">
            <i class=" el-icon-arrow-left"></i>
          </div>
          <div class="net-swiper-next" v-show="isBtn" @click="handleCk('next')">
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div class="version-selection" v-if="false">
          <div class="enterprise-version start-version">
            <img class="version-img" src="@/assets/purchase/start-type.png" alt="">
            <div class="version-name">启航版</div>
            <div class="version-desc">100≥企业员工规模≥10</div>
            <div class="enterprise-price">
              <span>￥</span>29,800<span>/年</span>
            </div>
            <div class="enterprise-btn"><button @click="handleBuy">咨询购买</button></div>
            <div class="list" style="width: 80%">
              <div class="list-item">
                <img src="@/assets/purchase/check-green.png" alt="">
                <div>打通招聘网站，多渠道简历聚合</div>
              </div>
              <div class="list-item">
                <img src="@/assets/purchase/check-green.png" alt="">
                <div>员工全生命周期管理</div>
              </div>
              <div class="list-item">
                <img src="@/assets/purchase/check-green.png" alt="">
                <div>多维度图表分析，数据指导决策</div>
              </div>
              <div class="list-item">
                <img src="@/assets/purchase/check-green.png" alt="">
                <div>人员组织管理，提高效能</div>
              </div>
            </div>
          </div>

          <div class="enterprise-version lead-version">
            <img class="version-img" src="@/assets/purchase/lead-type.png" alt="">
            <div class="version-name">领航版</div>
            <div class="version-desc">100≥企业员工规模≥10</div>
            <div class="enterprise-price">
              <span>￥</span>58,000<span>/年</span>
            </div>
            <div class="enterprise-btn"><button @click="handleBuy">咨询购买</button></div>
            <div class="list" style="width:80%">
              <div class="list-item">
                <img src="@/assets/purchase/check-green.png" alt="">
                <div><span style="color: #5B65FA;">领航版</span>包含<span style="color: #25ADFF;">启航版</span>全部功能</div>
              </div>
              <div class="list-item">
                <img src="@/assets/purchase/check-green.png" alt="">
                <div>绩效规则灵活配置，专业顾问指导</div>
              </div>
              <div class="list-item">
                <img src="@/assets/purchase/check-green.png" alt="">
                <div>员工各项能力测评，促进团队发展</div>
              </div>
              <div class="list-item">
                <img src="@/assets/purchase/check-green.png" alt="">
                <div>人才盘点，提升团队效能</div>
              </div>
              <div class="list-item">
                <img src="@/assets/purchase/check-green.png" alt="">
                <div>可持续发展的合规风险监测服务</div>
              </div>
            </div>
          </div>

          <div class="enterprise-version enter-version">
            <img class="version-img " src="@/assets/purchase/newVersion/ver91.png" alt="">
            <div class="version-name">旗舰版</div>
            <div class="version-desc">企业员工规模≥100名，管理员≥5名</div>
            <div class="enterprise-price">
              <span>￥</span>168,000<span>/年</span>
            </div>
            <div class="enterprise-btn"><button @click="handleBuy">咨询购买</button></div>
            <div class="list" style="width: 80%">
              <div class="list-item">
                <img src="@/assets/purchase/check-green.png" alt="">
                <div><span style="color:  #FFA93A;">旗舰版</span>包含<span style="color: #5B65FA;">领航版</span>全部功能</div>
              </div>
              <div class="list-item">
                <img src="@/assets/purchase/check-green.png" alt="">
                <div>多场景生成式AI模型调用</div>
              </div>
              <div class="list-item">
                <img src="@/assets/purchase/check-green.png" alt="">
                <div>企业战略管理，商业画布、经营分析</div>
              </div>
              <div class="list-item">
                <img src="@/assets/purchase/check-green.png" alt="">
                <div>企业、人才价值体系</div>
              </div>
              <div class="list-item">
                <img src="@/assets/purchase/check-green.png" alt="">
                <div>人事数据分析报告</div>
              </div>
              <div class="list-item">
                <img src="@/assets/purchase/check-green.png" alt="">
                <div>多家企业一键绑定同步管理</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 表单 -->
      <Form />
      <div class="container">
        <div class="version-detail-wrap">
          <div class="detail-header">
            <div class="header-title">版本明细</div>
            <div class="header-free">
              <div>启航版</div>
              <!-- <button class="showDialog">咨询购买</button> -->
            </div>
            <div class="header-free">
              <div>领航版</div>
              <!-- <button class="showDialog">咨询购买</button> -->
            </div>
            <div class="header-free header-tj">
              <div>旗舰版</div>
              <!-- <button class="showDialog">咨询购买</button> -->
            </div>
          </div>
          <div class="detail-legend">
            <div>
              <img src="@/assets/purchase/no-icon.png" alt="" />
              <span>未开通</span>
            </div>
            <!-- <div>
                <img src="@/assets/purchase/no-icon.png" alt="">
                <span>限免</span>
              </div> -->
            <div>
              <img src="@/assets/purchase/true-icon.png" alt="">
              <span>畅享</span>
            </div>
          </div>

          <div class="detail-content">
            <!-- <div v-for="item, index in list ?? []" :key="index">
              <div class="content-item content-empty">
                <div class="item-title"></div>
                <div class="item-free"></div>
                <div class="item-free"></div>
                <div class="item-free"></div>
              </div>
              <div class="content-item">
                <div class="item-title isBold">{{ item.title }}</div>
                <div class="item-free"></div>
                <div class="item-free"></div>
                <div class="item-free"></div>
              </div>
              <div class="content-item isHover" v-for="child, idx in item.children ?? []" :key="idx">
                <div class="item-title">{{ child.title }}</div>
                <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
                <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              </div>
              <div class="content-item content-empty">
                <div class="item-title"></div>
                <div class="item-free"></div>
                <div class="item-free"></div>
                <div class="item-free"></div>
              </div>
              <div class="segment-line"></div>
            </div> -->
            <div class="content-item">
              <div class="item-title isBold">适用端</div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">电脑端</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">移动端</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item content-empty">
              <div class="item-title"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="segment-line"></div>

            <!-- 技术服务 -->
            <div class="content-item content-empty">
              <div class="item-title"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="content-item">
              <div class="item-title isBold">技术服务</div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">5*16在线客服</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">代办设计与管理服务</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">操作向导与帮助指导</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">Q&A反馈</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">指导落地服务</div>
              <div class="item-free">提供1次免费试用</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item content-empty">
              <div class="item-title"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="segment-line"></div>



            <!-- 新体验 -->
            <div class="content-item content-empty">
              <div class="item-title"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="content-item">
              <div class="item-title isBold">新体验</div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">新功能推送与尝鲜</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item content-empty">
              <div class="item-title"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="segment-line"></div>



            <!-- 数字仪表板 -->
            <div class="content-item content-empty">
              <div class="item-title"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="content-item">
              <div class="item-title isBold">数字仪表板</div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">待办事项提醒</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">实时热点事件推送</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">成本核算预警</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">多组织切换</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">人事数据分析报告</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">招聘渠道分析</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">任务进度分析</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item content-empty">
              <div class="item-title"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="segment-line"></div>

            <!-- 战略管理 -->
            <div class="content-item content-empty">
              <div class="item-title"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="content-item">
              <div class="item-title isBold">战略管理</div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">AI商业画布</div>
              <div class="item-free"><img src="@/assets/purchase/no-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">商业画布管理</div>
              <div class="item-free"><img src="@/assets/purchase/no-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">发展大事记</div>
              <div class="item-free"><img src="@/assets/purchase/no-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">经营分析</div>
              <div class="item-free"><img src="@/assets/purchase/no-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">AI解读报告</div>
              <div class="item-free"><img src="@/assets/purchase/no-icon.png" alt=""></div>
              <div class="item-free">提供1次免费试用</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item content-empty">
              <div class="item-title"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="segment-line"></div>



            <!-- 招聘管理 -->
            <div class="content-item content-empty">
              <div class="item-title"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="content-item">
              <div class="item-title isBold">招聘管理</div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">经营分析</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">一键发布岗位</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">JD统一管理</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">岗位候选人画像</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">一键沟通</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">职位分享</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">自建简历库</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">候选人标签管理</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">一键收录简历</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">人才PK</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">智慧JD编写</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">参考薪资</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">简历自动收录</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">面试分析</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">一键入职</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">综合匹配度</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">智慧面试话术</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">到岗预测分析</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">智能简历解析</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">候选人评估报告</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">能力与素质测评</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">校招管理</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">面试管理</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item content-empty">
              <div class="item-title"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="segment-line"></div>



            <!-- 薪酬管理 -->
            <div class="content-item content-empty">
              <div class="item-title"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="content-item">
              <div class="item-title isBold">薪酬管理</div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">人力成本</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">成本分析</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item content-empty">
              <div class="item-title"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="segment-line"></div>



            <!-- 绩效管理 -->
            <div class="content-item content-empty">
              <div class="item-title"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="content-item">
              <div class="item-title isBold">绩效管理</div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">AI绩效模型</div>
              <div class="item-free"><img src="@/assets/purchase/no-icon.png" alt=""></div>
              <div class="item-free">提供1次免费试用</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">绩效方案管理</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">绩效指标管理</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">到期提醒管理</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">绩效报告分析</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">绩效申诉管理</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item content-empty">
              <div class="item-title"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="segment-line"></div>


            <!-- 安全合规 -->
            <div class="content-item content-empty">
              <div class="item-title"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="content-item">
              <div class="item-title isBold">安全合规</div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">用工风险看板</div>
              <div class="item-free"><img src="@/assets/purchase/no-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">风险提示</div>
              <div class="item-free"><img src="@/assets/purchase/no-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">合规风险监测</div>
              <div class="item-free"><img src="@/assets/purchase/no-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">效能风险监测</div>
              <div class="item-free"><img src="@/assets/purchase/no-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">操作留痕</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item content-empty">
              <div class="item-title"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="segment-line"></div>


            <!-- 人才盘点 -->
            <div class="content-item content-empty">
              <div class="item-title"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="content-item">
              <div class="item-title isBold">人才盘点</div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">AI盘点模型</div>
              <div class="item-free"><img src="@/assets/purchase/no-icon.png" alt=""></div>
              <div class="item-free">提供1次免费试用</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">AI评估模型</div>
              <div class="item-free"><img src="@/assets/purchase/no-icon.png" alt=""></div>
              <div class="item-free">提供1次免费试用</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">人才盘点方案</div>
              <div class="item-free"><img src="@/assets/purchase/no-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">盘点分析看板</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">人才价值对比</div>
              <div class="item-free"><img src="@/assets/purchase/no-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">能力测评管理</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">测评报告分析</div>
              <div class="item-free"><img src="@/assets/purchase/no-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">人才评估方案</div>
              <div class="item-free"><img src="@/assets/purchase/no-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">贡献价值模型</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">评估指标管理</div>
              <div class="item-free"><img src="@/assets/purchase/no-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">人才评估报告</div>
              <div class="item-free"><img src="@/assets/purchase/no-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">人才地图</div>
              <div class="item-free"><img src="@/assets/purchase/no-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/no-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/no-icon.png" alt=""></div>
            </div>
            <div class="content-item content-empty">
              <div class="item-title"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="segment-line"></div>



            <!-- 价值体系 -->
            <div class="content-item content-empty">
              <div class="item-title"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="content-item">
              <div class="item-title isBold">价值体系</div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">人才价值分</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">企业价值分</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item content-empty">
              <div class="item-title"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="segment-line"></div>



            <!-- 组织管理 -->
            <div class="content-item content-empty">
              <div class="item-title"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="content-item">
              <div class="item-title isBold">组织管理</div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">组织管理</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">组织架构图</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">职位管理</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">职级管理 </div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">人事权限管理</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">员工管理</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">人才价值体系</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">一键离职</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">员工AI身份卡</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">员工数字档案</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">企业花名册</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">人事异动追踪</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item content-empty">
              <div class="item-title"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="segment-line"></div>


            <!-- 企业档案 -->
            <div class="content-item content-empty">
              <div class="item-title"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="content-item">
              <div class="item-title isBold">企业档案</div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
            <div class="content-item isHover">
              <div class="item-title">企业档案</div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
              <div class="item-free"><img src="@/assets/purchase/true-icon.png" alt=""></div>
            </div>
            <div class="content-item content-empty">
              <div class="item-title"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
              <div class="item-free"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hysc-dia v-if="visibleD" :visible.sync="visibleD" @getClickCancel="getClickClose"></hysc-dia>
    <hysc-tel-dia v-if="visibleT" :visible.sync="visibleT" @getClickCancel="getClickCloseTel"></hysc-tel-dia>
  </div>
</template>

<script>
import { website } from '@/api/gw.js'
import FormTel from '../form/telForm.vue'
import Form from '../form/index.vue'
import HyscDia from '../Dialog/dialog.vue'
import HyscTelDia from '../Dialog/telDialog.vue'
import Swiper from 'swiper'

export default {
  data() {
    return {
      isTouching: false, // 是否正在触摸
      startX: 0, // 触摸开始的X坐标
      startY: 0, // 触摸开始的Y坐标
      moveX: 0, // 触摸移动的X距离
      moveY: 0, // 触摸移动的Y距离
      threshold: 50, // 触发滑动的阈值
      visibleD: false,
      visibleT: false,
      isBtn: false,
      tabPosition: 'left',
      isAct: 4,
      swiper: null,
      list1: [],
      list: [
        {
          title: '适用端',
          children: [
            { title: '电脑端', version1: true, version2: true, version3: true },
            { title: '移动端', version1: true, version2: true, version3: true }
          ]
        }
      ]
    };
  },
  components: {
    HyscDia,
    HyscTelDia,
    Form,
    FormTel
  },
  methods: {
    // 触摸开始事件处理程序
    handleTouchStart(event) {
      this.isTouching = true
      this.startX = event.touches[0].clientX;
      this.startY = event.touches[0].clientY;
    },
    // 触摸移动事件处理程序
    handleTouchMove(event) {
      if (!this.isTouching) return;
      this.moveX = event.touches[0].clientX - this.startX;
      this.moveY = event.touches[0].clientY - this.startY;
    },
    // 触摸结束事件处理程序
    handleTouchEnd() {
      if (!this.isTouching) return
      this.isTouching = false
      if (Math.abs(this.moveX) > this.threshold && Math.abs(this.moveX) > Math.abs(this.moveY)) {
        if (this.moveX > 0) {// 向右滑动
          this.isRight = true
          this.isLeft = false
          if (Number(this.isAct) > 1) {
            ~this.isAct--
          }
        } else { // 向左滑动
          this.isRight = false
          this.isLeft = true
          if (Number(this.isAct) < 7) {
            ~this.isAct++
          }
        }
      }
      this.moveX = 0
      this.moveY = 0
    },
    // 点击做记录
    getClick(val, type) {
      website({ type: val, name: type }).then((res) => {
        console.log(res, 'rrr')
      });
    },
    isDialogTel(val, type) {
      const child = document.querySelector(`#formRegRefTEL`)
      child.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
      this.getClick(val, type)
      // this.visibleT = true
    },
    getClickCloseTel() {
      this.visibleT = false
    },
    isDialog(val, type) {
      this.getClick(val, type)
      this.visibleD = true
    },
    getClickClose() {
      this.visibleD = false
    },
    getIsAct(val) {
      this.isAct = val
    },
    tabClick(t, e) {
      console.log(t, e)
    },
    handleBuy() {
      const child = document.querySelector(`#formRegRef`)
      child.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
      // this.getClick(val, type)
    },
    handleCk(val) {
      if (val == 'next') {
        this.swiper.slideNext()
      } else {
        this.swiper.slidePrev()
      }
    },
    getSwiper() {
      this.swiper = new Swiper(".swiper-container", {
        loop: true,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        slidesPerView: 4, // 一次显示五张卡片
        centeredSlides: true, // 使得当前活动的幻灯片居中显示
        // spaceBetween: 0, // 调整为负值以实现卡片重叠
        effect: 'coverflow',
        coverflowEffect: {
          rotate: 0,
          stretch: 48.2,
          depth: 80,
          modifier: 1,
          scale: 1,
          slideShadows: false,
        },
        slidesOffsetBefore: 8,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.net-swiper-next',
          prevEl: '.pre-swiper-prev',
        },
        observer: true,
        observeParents: true,
        // loopAdditionalSlides: 2, // 确保循环时前后各有两张幻灯片
        // loop: true, // 无缝
        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false, //*手动操作轮播图后不会暂停*
        // },
        initialSlide: 3,
        // // slidesPerView: '4',// 默认一个屏幕显示几张图，必须auto
        // // paginationClickable: true,
        // spaceBetween: -30,
        // // slidesPerView: 4,
        // slidesPerView: "auto",
        // pagination: {
        //   el: '.swiper-pagination',
        //   clickable: true, // 分页器可以点击
        // },
        // parallax: true,
        // watchSlidesProgress: true,
        // centeredSlides: true,
        // slideNextClass: 'my-slide-next',
        // slidePrevClass: 'my-slide-prev',
        // // coverflowEffect: {
        // //   rotate: 0, //slide做3d旋转时Y轴的旋转角度。默认50。
        // //   stretch: 100, //每个slide之间的拉伸值（距离），越大slide靠得越紧。 默认0。
        // //   depth: 100, //slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
        // //   modifier: 2, //depth和rotate和stretch的倍率，相当于            depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。默认1。
        // //   slideShadows: false //开启slide阴影。默认 true。
        // // },
        // observer: true,
        // observeParents: true,
        // loopAdditionalSlides: 2, // 确保循环时前后各有两张幻灯片
        on: {
          // touchMove: function () {
          //   this.slides.each((index, slide) => {
          //     if (index == this.activeIndex) {
          //       slide.style.transform = 'scale(1,1)'
          //       slide.style.zIndex = 66
          //     } else if (index == (this.activeIndex + 1) || index == (this.activeIndex - 1)) {
          //       slide.style.transform = 'scale(0.92, 0.92)'
          //       slide.style.zIndex = 2
          //     } else {
          //       slide.style.transform = 'scale(0.85, 0.85)'
          //       slide.style.zIndex = 1
          //     }
          //   })
          // },
          // // 每次幻灯片切换动画结束时执行
          // slideChangeTransitionStart: function () {
          //   this.slides.each((index, slide) => {
          //     if (index == this.activeIndex) {
          //       // slide.style.transform = 'scale(1,1)'
          //       slide.style.zIndex = 66
          //     } else if (index == (this.activeIndex + 1) || index == (this.activeIndex - 1)) {
          //       // slide.style.transform = 'scale(0.92, 0.92)'
          //       slide.style.zIndex = 2
          //     } else {
          //       // slide.style.transform = 'scale(0.85, 0.85)'
          //       slide.style.zIndex = 1
          //     }
          //   })
          // },
        }
      })
      //鼠标移出隐藏按钮，移入显示按钮
      this.swiper.el.onmouseover = () => {
        this.isBtn = true
      }
      this.swiper.el.onmouseout = () => {
        this.isBtn = false
      }
    }
  },
  mounted() {
    this.getSwiper()
  }
}
</script>

<style scoped>
.version-wrap {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -12%;
  /* width: 1200px; */
}

.swiper-container {
  width: 100%;
  height: 100%;
  padding-bottom: 30px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  border-radius: 6px;
  padding: 0 10px 12px 0;
  /* width: 280px; */
  /* transform: scale(0.85); */
  transition: transform 0.2s ease;
  z-index: 1;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

/* .my-slide-next {
  z-index: 3;
  transform: scale(0.92);
}

.my-slide-prev {
  z-index: 3;
  transform: scale(0.92);
}

.swiper-slide-active {
  z-index: 4;
  transform: scale(1);
} */

.pre-swiper-prev,
.net-swiper-next {
  width: 38px;
  height: 38px;
  background-color: rgba(31, 45, 61, .31);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  color: #FFFFff;
  cursor: pointer;
}

.pre-swiper-prev:hover,
.net-swiper-next:hover {
  background-color: rgba(31, 45, 61, .51);
}

.pre-swiper-prev {
  position: absolute;
  z-index: 999;
  top: 40%;
  left: 10px;
  animation-duration: .3s;
  animation-fill-mode: both;
  animation-name: fadeInLeft;
  animation-timing-function: ease-in-out;
}

.net-swiper-next {
  position: absolute;
  z-index: 999;
  top: 40%;
  right: 10px;
  animation-duration: .3s;
  animation-fill-mode: both;
  animation-name: fadeRight;
  animation-timing-function: ease-in-out;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeRight {
  from {
    opacity: 0;
    transform: translate3d(200%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.pc {
  display: block !important;
}

.tel {
  display: none !important;
}

@media (max-width: 1200px) {
  .tel .version-detail-wrap .detail-header .header-title {
    padding-left: 0;
  }

  .tel .version-detail-wrap {
    padding-top: 15px;
  }

  .tel .freePosiHeight {
    height: 650px !important;
  }

  .tel .mTop {
    width: 93%;
  }

  .tel .freeMoney {
    background: url(../../assets/purchase/FREE.png) no-repeat;
    background-size: 100% 100%;
    width: 150px;
    margin: 15px auto;
    font-size: 20px;
    color: #186fff;
    font-weight: 600;
    text-align: center;
  }

  .tel .version-detail-wrap .detail-header .header-free {
    display: initial;
  }

  .tel .isActC {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
  }

  .tel .active {
    width: 38px;
    height: 14px;
    border-radius: 79px;
    opacity: 1;
    margin-right: 10px;
    margin-left: 10px;
    background: #186fff;
  }

  .tel .noActive {
    width: 14px;
    height: 14px;
    border-radius: 79px;
    opacity: 1;

    background: #90baff;
  }

  .tel .version-detail-wrap .detail-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 16px 0;
    text-align: center;
    position: sticky;
    top: 72px;
    background-color: white;
  }

  .tel .version-detail-wrap .free-l {
    text-align: left;
  }

  .tel .version-detail-wrap .detail-header .header-enterprise {
    flex: 1;
    display: flex;
    gap: 0px;
    align-items: center;
    flex-direction: initial;
    font-weight: bold;
    font-size: 20px;
    position: relative;
    margin-left: 15px;
  }

  .tel .version-detail-wrap .detail-header .header-enterprise::after {
    content: '';
    position: absolute;
    top: 12px;
    display: none;
    right: 46px;
    transform: rotate(45deg);
    width: 5px;
    height: 5px;
    border-radius: 1px;
    background-color: #f6584f;
  }

  .header-ent-po {
    position: relative;
    font-size: 0;
  }

  .header-ent-po span {
    position: absolute;
    left: 12px;
    top: 0;
    color: #fff;
    font-size: 14px;
  }

  .tel .version-detail-wrap .detail-header .header-enterprise .header-ent-w {
    width: 86px;
  }

  .tel .version-detail-wrap .detail-header .header-enterprise::before {
    content: '推荐';
    display: none;
    width: 48px;
    height: 23px;
    line-height: 23px;
    color: #fff;
    text-align: center;
    font-size: 14px;
    border-radius: 4px;
    background: #f6584f;
    position: absolute;
    top: 2px;
    right: 0;
  }

  .tel .detail_dis .detail-header {
    padding: 0;
    width: 33%;
  }

  .tel .detail_dis .version-detail-wrap .detail-header {
    width: 50%;
    display: flex;
    /* gap: 20px; */
    /* flex-wrap: wrap; */
    align-items: center;
    padding: 16px 0;
    /* position: sticky; */
    /* top: 72px; */
    background-color: white;
    justify-content: space-between;
  }

  .tel .detail_dis {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .tel .version-detail-wrap .detail-content .content-item .item-enterprise span {
    color: #666;
    font-size: 13px;
  }

  .tel .version-detail-wrap .detail-content .content-item .item-title {
    width: 130px;
    color: #666;
    font-size: 14px;
    padding-left: 0px;
    font-weight: 300;
  }

  .tel .desc .line_dis {
    display: flex;
    align-items: center;
    color: #606266;
    font-size: 14px;
    padding: 15px 0 4px 9%;
  }

  .tel .desc .line_dis img {
    width: 15px;
    margin-right: 10px;
  }

  .tel .mTop .mtop_head {
    position: relative;
    padding: 6px 0 0 0;
  }

  .tel .mTop .mtop_head img {
    width: 50px;
    height: 50px;
    position: absolute;
  }

  .tel .mTop .line {
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.95px;
    color: #666666;
  }

  .tel .mTop .desc .freePay {
    color: #186fff;
    font-size: 16px;
    border-radius: 20px;
    text-align: center;
    width: 200px;
    margin: 0 auto;
    border: 1px solid #186fff;
    margin-top: 20px;
    padding: 10px;
  }

  .tel .mTop .desc .pay {
    color: #fff;
    font-size: 16px;
    border-radius: 20px;
    text-align: center;
    width: 200px;
    margin: 0 auto;
    margin-top: 20px;
    padding: 10px;
  }

  .tel .mTop .desc .money {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffa93a;
    font-size: 22px;
    margin-top: 20px;
  }

  .tel .mTop .desc .money .number {
    font-size: 45px;
    font-weight: 600;
  }

  .tel .mTop .title {
    font-size: 28px;
    color: #4d5565;
    font-weight: 600;
    text-align: center;
    padding-bottom: 6px;
  }

  .tel .mTop {
    background: #fff;
    margin: 125px 0 15px 0;
    padding: 8px 15px 15px 15px;
    position: absolute;
    z-index: 22;
    height: 480px;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 8px;
    box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.1476);
  }

  .tel .mTop img {
    width: 100%;
  }

  .tel .posiHeight {
    min-height: 650px;
  }

  .pc {
    display: none !important;
  }

  .tel {
    display: block !important;
  }

  .tel .banner {
    background: url(../../assets/telbg4.png) no-repeat;
    background-size: 100%;
    height: 400px;
    position: absolute;
    top: 0px;
    width: 100%;
  }

  .tel .teldesc {
    position: absolute;
    left: 0%;
    right: 50%;
    top: 20%;
    width: 100%;
    text-align: center;
    letter-spacing: 1.2px;
    line-height: 1.5;
  }

  .tel .teldesc .teld1 {
    padding: 15px;
    font-size: 35px;
    font-weight: bold;
    letter-spacing: 1.82px;
  }

  .tel .teldesc .teld3,
  .teld1 {
    color: #FFF;
  }
}
</style>

<style scoped>
.version-wrap .version-selection {
  width: 100%;
  display: flex;
  gap: 20px;
}

.version-wrap .version-name {
  color: #333;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.version-wrap .version-desc {
  color: #666;
  font-size: 14px;
  text-align: center;
}

.version-wrap .basic-version {
  flex: 1;
  position: relative;
  padding: 34px 40px;
  background: #fff;
  border-radius: 8px;
  border-top: 12px solid #186fff;
  box-shadow: 0px 5px 12px 0px #ced3da;
}

.version-wrap .version-img {
  position: absolute;
  top: 17px;
  left: 17px;
  width: 66px;
  height: 70px;
}

.version-scale {
  transform: scale(0.75) !important;
}

.version-wrap .list {
  width: 80%;
  font-size: 14px;
  margin: 0 auto;
  padding-top: 5px;
}

.version-wrap .list .list-item {
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: left;
  margin-top: 20px;
}

.version-wrap .list .list-item img {
  width: 16px;
  height: 16px;
}

.version-wrap .list .list-item div {
  color: #606266;
}

.version-wrap .basic-version .basic-price {
  color: #e8f1ff;
  font-size: 67px;
  font-weight: bold;
  text-align: center;
  position: relative;
}

.version-wrap .basic-version .basic-price .inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #186fff;
  font-size: 36px;
  font-weight: bold;
}

.version-wrap .basic-version .free-btn {
  text-align: center;
}

.version-wrap .basic-version .free-btn button {
  width: 290px;
  height: 44px;
  line-height: 35px;
  border: 1px solid #186fff;
  border-radius: 50px;
  font-weight: 500;
  font-size: 16px;
  background-color: #fff;
  color: #186fff;
}

.version-wrap .enterprise-version {
  flex: 1;
  height: 550px;
  position: relative;
  padding: 30px;
  background: #fff;
  border-radius: 8px;
  /* border-top: 12px solid #ffa93a; */
  box-shadow: 0px 5px 12px 0px #ced3da;
}

.version-wrap .enterprise-version .version-name {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-left: 16px;
}

.version-wrap .enterprise-version .version-name span {
  display: inline-block;
  width: 48px;
  height: 23px;
  line-height: 23px;
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
  background: #f6584f;
  position: relative;
}

.version-wrap .enterprise-version .version-name span::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -2px;
  transform: translateY(-50%) rotate(45deg);
  width: 5px;
  height: 5px;
  border-radius: 1px;
  background-color: #f6584f;
}

.version-wrap .enterprise-version .enterprise-price {
  /* color: #ffa93a; */
  font-size: 42px;
  height: 90px;
  line-height: 90px;
  text-align: center;
  font-weight: bold;
}

.version-wrap .enterprise-version .enterprise-price span {
  font-size: 22px;
}

.version-wrap .enterprise-version .enterprise-btn {
  text-align: center;
}

.version-wrap .enterprise-version .enterprise-btn button {
  width: 290px;
  height: 44px;
  line-height: 35px;
  border: none;
  border-radius: 50px;
  font-weight: 500;
  font-size: 16px;
  color: white;
}

.start-version {
  border-top: 17px solid #1A95FD;
}

.start-version button {
  background: linear-gradient(0deg, #25ADFF, #25ADFF), #1A95FD;
}

.start-version .enterprise-price {
  color: #1A95FD !important;
}

.lead-version {
  border-top: 17px solid #5B65FA;
}

.lead-version button {
  background: #5B65FA;
}

.lead-version .enterprise-price {
  color: #5B65FA;
}

.enter-version {
  border-top: 17px solid #FFA93A;
}

.enter-version button {
  background: #FFA93A;
}

.enter-version .enterprise-price {
  color: #FFA93A;
}

.version-detail-wrap {
  padding-top: 100px;
  padding-bottom: 70px;
}

.version-detail-wrap .detail-header {
  width: 100%;
  display: flex;
  gap: 20px;
  padding: 16px 0;
  position: sticky;
  top: 72px;
  background-color: white;
}

.version-detail-wrap .detail-header .header-title {
  width: 230px;
  color: #141c2b;
  font-size: 24px;
  font-weight: bold;
  padding-left: 28px;
}

.version-detail-wrap .detail-header .header-free,
.version-detail-wrap .detail-header .header-enterprise {
  flex: 1;
  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: column;
  font-weight: bold;
  font-size: 20px;
  position: relative;
}

.version-detail-wrap .detail-header .header-enterprise::before {
  content: '推荐';
  display: inline-block;
  width: 48px;
  height: 23px;
  line-height: 23px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  border-radius: 4px;
  background: #f6584f;
  position: absolute;
  top: 2px;
  right: 132px;
}

.version-detail-wrap .detail-header .header-enterprise::after {
  content: '';
  position: absolute;
  top: 12px;
  right: 178px;
  transform: rotate(45deg);
  width: 5px;
  height: 5px;
  border-radius: 1px;
  background-color: #f6584f;
}

.version-detail-wrap .detail-header .header-free button {
  width: 99px;
  height: 35px;
  line-height: 1;
  border: 1px solid #186fff;
  border-radius: 50px;
  font-weight: 500;
  font-size: 16px;
  background-color: #fff;
  color: #186fff;
}

.version-detail-wrap .detail-header .header-enterprise button {
  width: 99px;
  height: 35px;
  line-height: 1;
  border: none;
  border-radius: 50px;
  font-weight: 500;
  font-size: 16px;
  background-color: #186fff;
  color: white;
}

.version-detail-wrap .detail-legend {
  font-size: 15px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-bottom: 8px;
}

.version-detail-wrap .detail-legend div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.version-detail-wrap .detail-legend img {
  width: 16px;
  height: 16px;
}

.version-detail-wrap .detail-content .content-item {
  display: flex;
  gap: 20px;
  height: 40px;
  line-height: 40px;
  position: relative;
}

.version-detail-wrap .detail-content .content-empty {
  display: flex;
  gap: 20px;
  height: 20px !important;
  line-height: 20px !important;
  position: relative;
}

.version-detail-wrap .detail-content .content-item.isHover:hover::after {
  content: '';
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  cursor: pointer;
}

.version-detail-wrap .detail-content .content-item .item-free {
  display: flex;
  align-items: center;
  justify-content: center;
}

.version-detail-wrap .detail-content .content-item .item-enterprise {
  display: flex;
  align-items: center;
  justify-content: center;
}

.version-detail-wrap .detail-content .content-item.isHover:hover .item-free,
.version-detail-wrap .detail-content .content-item.isHover:hover .item-enterprise {
  background: #ffffff;
}

.version-detail-wrap .detail-content .content-item .item-title {
  width: 230px;
  color: #666666;
  font-size: 16px;
  font-weight: normal;
  padding-left: 28px;
}

.version-detail-wrap .detail-content .content-item .item-title.isBold {
  color: #141c2b;
  font-size: 20px;
  font-weight: bold;
}

.version-detail-wrap .detail-content .content-item .item-free,
.version-detail-wrap .detail-content .content-item .item-enterprise {
  flex: 1;
  text-align: center;
  background: #f9fbff;
}

.version-detail-wrap .detail-content .content-item .item-free img,
.version-detail-wrap .detail-content .content-item .item-enterprise img {
  width: 20px;
  height: 20px;
}

.version-detail-wrap .detail-content .content-item .item-free span,
.version-detail-wrap .detail-content .content-item .item-enterprise span {
  color: #666;
  font-size: 15px;
}

.version-detail-wrap .detail-content .segment-line {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #d5dbe8;
}
</style>

<style scoped>
.row {
  margin-right: 0px;
  margin-left: 0px;
}

.container #container {
  height: 300px;
}

.banner_po {
  position: relative;
  /* margin-top: 71px; */
}

.banner_po .banner_ab {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: #fff;
}

.banner_po .banner_ab .app_title {
  color: #333;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 10px;
}

.banner_po .banner_ab .app_desc1 {
  color: #666;
  font-size: 18px;
  margin-bottom: 10px;
}
</style>