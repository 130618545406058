<template>
  <header>
    <nav class="navbar navbar-expand-lg" role="navigation">
      <div class="container tel-bg">
        <div class="navbar-header">
          <div class="logotel" @click="getTelImg('headImg', 'tel')">
            <img class="logo" src="@/assets/headerTelLogoW.png" alt="" />
          </div>
          <button @click="getTelNav('nav', 'tel')" type="button" class="navbar-toggle collapsed" data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
        </div>
        <div class="navbar-headerB">
          <div class="logotel">
            <router-link :to="'/'" class="aLogo"><img class="logo" src="@/assets/headerTelLogoH.png"
                alt="" /></router-link>
          </div>
          <button @click="getTelNav('home', 'tel')" type="button" class="navbar-toggle collapsed" data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1">
            <span class="sr-only noH">Toggle navigation</span>
            <span class="icon-bar noH"></span>
            <span class="icon-bar noH"></span>
            <span class="icon-bar noH"></span>
          </button>
        </div>
        <div v-if="showTelNav" class="navTel collapse navbar-collapse tel" id="bs-example-navbar-collapse-1">
          <ul class="nav navbar-nav navbar-right">
            <li @click="getTelNav('home', 'tel')">
              <router-link :to="'/'">首页</router-link>
            </li>
            <li @click="getTelNav('application', 'tel')">
              <router-link :to="'Application'">应用场景</router-link>
            </li>
            <li @click="getTelNav('customers', 'tel')">
              <router-link :to="'Customer'">客户案例</router-link>
            </li>
            <li @click="getTelNav('purchased', 'tel')">
              <router-link :to="'Purchase'">购买</router-link>
            </li>
            <li @click="getTelNav('about', 'tel')">
              <router-link :to="'About'">关于我们</router-link>
            </li>
          </ul>
        </div>

        <!-- pc -->
        <div class="navTel collapse navbar-collapse pc" id="bs-example-navbar-collapse-1">
          <ul class="dis-width nav navbar-nav navbar-right">
            <li @click="getClick('home', 'pc')">
              <router-link :to="'/'" class="aLogo"><img class="logo" src="@/assets/logo.png" alt="" /></router-link>
            </li>
            <li @click="getClick('home', 'pc')">
              <router-link :to="'/'">首页</router-link>
            </li>
            <li @click="getClick('application', 'pc')">
              <router-link :to="'Application'">应用场景</router-link>
            </li>
            <li @click="getClick('customers', 'pc')">
              <router-link :to="'Customer'">客户案例</router-link>
            </li>
            <li @click="getClick('purchased', 'pc')">
              <router-link :to="'Purchase'">购买</router-link>
            </li>
            <li @click="getClick('about', 'pc')">
              <router-link :to="'About'">关于我们</router-link>
            </li>
          </ul>
          <ul class="dis-width-phone navPhone nav navbar-nav navbar-right">
            <li>
              <p><i class="fa fa-phone"> </i> 400-0055-965</p>
            </li>
          </ul>
          <div class="dis-width-free">
            <ul class="free nav navbar-nav navbar-right freeNav">
              <li @click="isDialog()">
                <button id="show">申请试用</button>
              </li>
            </ul>
            <ul class="free nav navbar-nav navbar-right loginHYSC">
              <li @click="goHYSC()">
                <button>产品登录</button>
              </li>
            </ul>
          </div>
        </div>

        <hysc-dia v-if="visibleD" :visible.sync="visibleD" @getClickCancel="getClickClose"></hysc-dia>

        <div class="telPhone" @click="goTelPhone">
          <img src="@/assets/telIcon.png" alt="" />
        </div>
      </div>

    </nav>
    <!-- <hysc-dia v-if="visibleD" :visible.sync="visibleD" @getClickCancel="getClickClose"></hysc-dia> -->
  </header>
</template>

<script>
import HyscDia from './Dialog/dialog.vue';
import { website } from '@/api/gw.js'

export default {
  data() {
    return {
      activeIndex: '1',
      activeIndex2: '1',
      show: false,
      isShow: false,
      visibleD: false,
      showTelNav: false,
    }
  },
  components: {
    HyscDia,
  },
  methods: {
    getTelImg(val, type) {
      this.getClick(val, type)
      this.showTelNav = false
      this.$router.push('/')
    },
    // 点击做记录
    getClick(val, type) {
      website({ type: val, name: type }).then((res) => {
        console.log(res, 'rrr')
      })
    },
    goTelPhone() {
      window.location.href = 'tel://400-0055-965'
    },
    goHYSC() {
      window.open('https://hysc.creatoneos.com/', '_blank')
      this.getClick('userLoginInfo', 'pc');
    },
    goYongHu() {
      window.open('https://hysc.creatoneos.com/privacy/index.html', '_blank')
    },
    isDialog() {
      this.visibleD = true
      // this.getClick('entry', 'pc')
      // this.$router.push('/Purchase')
    },
    getClickClose() {
      this.visibleD = false
    },
    getTelNav(val, type) {
      this.getClick(val, type)
      this.showTelNav = !this.showTelNav
    }
  },
  watch: {
    $route: {
      handler: function () {
        this.show = false
      }
    }
  }
}
</script>

<style>
:deep(.el-dialog__body) {
  padding: 0;
}

.form-wrap .form-padding {
  padding: 15px 20px;
}

.router-link-exact-active {
  color: #2074ff !important;
  font-weight: 500;
  border-bottom: solid #2074ff 2px;
}
</style>

<style scoped>
/*PC star*/
.nav>li>a:focus {
  background-color: initial;
}

.logo {
  text-align: center;
}

.aLogo {
  padding-top: 10px;
  padding-left: 0 !important;
  border-bottom: inherit;
}

.aLogo:hover {
  padding-top: 10px;
  padding-left: 0 !important;
  border-bottom: inherit;
}

.navbar-collapse {
  display: flex !important;
}

/* @media (min-width: 768px) {
  .navbar-right {
    float: initial !important;
  }
}

@media (min-width: 768px) {
  .navbar-nav {
    float: inherit;
  }
} */

.logo .logo_ch {
  height: 19px;
  font-size: 18px;
  font-weight: 400;
  color: #437ff6;
  line-height: 20px;
}

.logo .logo_en {
  height: 14px;
  font-size: 12px;
  font-weight: 400;
  color: #437ff6;
}

.el-menu {
  height: 23px;
  font-size: 24px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
  text-align: center;
}

.el-menu--horizontal>.el-menu-item.is-active {
  color: #437ff6;
}

.header_nav {
  display: flex;
  width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
}

.el-menu.el-menu--horizontal {
  border-bottom: none;
}

.el-menu--horizontal>.el-menu-item {
  height: 43px;
  line-height: 25px;
}

.el-menu-item {
  padding: 0;
  margin: 0 20px !important;
}

.el-menu-item a {
  display: block;
}

.nav_ch {
  font-size: 16px;
  display: block;
  line-height: 1;
}

.nav_en {
  font-size: 14px;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  outline: 0;
  color: #437ff6;
}

.telHead {
  display: none;
}

/* Tel end */
/*@media only screen and (max-width: 400px){*/
@media screen and (max-width: 768px) {
  .header_nav {
    display: none;
    width: 100%;
  }

  .telHead {
    display: block;
  }

  .headTel {
    display: flex;
    align-items: center;
  }

  .headTel-i {
    font-size: 2em;
    color: #437ff6;
  }

  .headTel-img {
    width: 100%;
    text-align: center;
  }

  .headTel-img img {
    width: 10%;
  }

  /*Tel导航*/
  .headTel-nav {
    position: relative;
  }

  .headTel-nav ul {
    width: 50%;
    text-align: center;
    line-height: 2em;
    position: absolute;
    z-index: 100;
    left: 0;
    top: 0;
    background: #0077ff;
    padding-bottom: 20%;
    color: white;
    font-size: 1.2em;
  }

  .headTel-nav li {
    margin: 2% 0;
    border-bottom: 1px solid #eee;
  }

  .headTel-nav li a {
    color: white;
  }
}
</style>

<!-- 媒体查询 -->
<style scoped>
.tel,
.teldesc {
  display: none !important;
}

.telPhone {
  display: none;
}

.logotel {
  display: none;
}

.navPhone {
  color: #186FFF;
  font-size: 16px;
  margin-top: 15px;
  font-weight: 500;
}

.dis-width {
  width: 60%;
}

.dis-width-phone {
  width: 15%;
}

.dis-width-free {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: right;
}

.navbar-nav.navbar-right:last-child {
  margin-right: 2px;
}

.loginHYSC li {
  margin-right: 0;
}

@media screen and (max-width: 600px) {
  .telPhone {
    display: block;
    position: fixed;
    right: 15px;
    bottom: 10%;
    background: #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    align-items: center;
    box-sizing: border-box;
    border: 1px solid #d1d1d1;
    box-shadow: 0px 6px 11px 0px rgba(0, 0, 0, 0.1204);
  }

  .telPhone img {
    width: 18px;
    margin-top: 11px;
    height: 18px;
  }

  .tel .navbar-nav {
    margin: 0;
  }

  .tel .nav li {
    margin-right: 0;
  }

  .tel .nav li a {
    text-align: left;
    border: 1px solid #e6e6e6;
    font-weight: 600;
    color: #414243;
  }

  .el-main {
    margin-top: 0 !important;
  }

  .el-header {
    height: 400px !important;
  }

  .navbar {
    border: none;
  }

  header .navbar-toggle span {
    border-bottom: 2px #fff solid;
  }

  header .navbar-toggle .noH {
    border-bottom: 2px #000 solid;
  }

  .navbar-header {
    padding: 15px;
  }
}

@media (max-width: 1200px) {
  .pc {
    display: none !important;
  }

  .tel,
  .teldesc {
    display: block !important;
  }

  .tel {
    background: #fff;
    padding: 0;
    margin: 0;
    height: 100vh;
  }

  .navbar-toggle {
    margin-right: 0;
  }

  .navbar-header {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navbar-headerB {
    display: none;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 10px 15px;
  }

  .logotel {
    width: 85%;
    display: block;
  }

  .teldesc {
    position: absolute;
    left: 0%;
    right: 50%;
    top: 30%;
    width: 100%;
    text-align: center;
  }

  .teld1 {
    font-size: 60px;
    font-weight: bold;
    background: linear-gradient(85deg, #f3f7ff 0%, #03abff 99%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    letter-spacing: 1.82px;
  }

  .teld2 {
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 1.82px;
  }

  .teld2,
  .teld3 {
    color: #fff;
  }

  .teld3 {
    font-size: 16px;
  }

  header .navbar-toggle span {
    border-bottom: 2px #fff solid;
  }

  .tel-bg {
    z-index: 222;
    position: fixed;
    top: 0;
    width: 100%;
    padding: 0;
  }

  .el-header {
    position: initial;
    margin-top: 0 !important;
  }

  .el-header {
    height: 400px;
  }

  header .navbar-toggle {
    border: none;
  }
}
</style>