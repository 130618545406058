<template>
  <div>
    <div class="tel">
      <div class="banner">
        <div class="teldesc">
          <div class="teld1">客户案例</div>
          <div class="teld3 telTop">聚焦AI技术和大数据技术的研发与应用，</div>
          <div class="teld3">致力于帮助企业持续提高效能并规避合规风险</div>
        </div>
      </div>
      <div class="elTab">
        <el-tabs v-model="posiTitle" :tab-position="tabPositionTel" @tab-click="tabClickTel">
          <el-tab-pane label="信息安全" name="信息安全">
            <div class="container">
              <div class="row gjianjie-jiesao">
                <div class="app_flex">
                  <div class="app_width_r">
                    <div class="app_width_r_title">
                      <div class="app_width_r_title_name">
                        某计算机科技公司—优化招聘流程
                      </div>
                    </div>
                    <div class="app_width_r_con">
                      <div class="app_width_r_con_1">
                        <div class="app_width_r_con_name">客户需求</div>
                        <div class="app_width_r_con_desc">
                          该客户所属于信息安全行业，竞争非常激烈。为保持竞争优势，需要大量招聘软件工程师来支持其研发需求，但面临两大困难：
                        </div>
                        <div class="app_width_r_con_desc">
                          1.行业内人才挤兑，大量的候选人简历等待筛选和评估，HR和用人需求部门需要在有限的时间内完成候选人的评估和完成招聘任务，规避因招不到人而影响公司发展。
                        </div>
                        <div class="app_width_r_con_desc">
                          2.需要一整套招聘管理系统来解决招聘全流程的需要，最重要的核心是满足对候选简历的筛选不会因为人工疏忽或人工主观因素的臆判而影响人才的精准需要。
                        </div>
                      </div>
                      <div class="app_width_r_con_1">
                        <div class="app_width_r_con_name">解决方案</div>
                        <div class="app_width_r_con_desc">
                          在了解客户需求后，创壹科技慧眼识才结合AI与大数据，提供一键招聘解决方案。匹配、筛选候选人，智能话术，实时查看招聘数据支持调整，对比分析渠道效果，提高效率。
                        </div>
                        <div class="app_width_r_con_flex">
                          <div class="app_width_r_con_flex_width2">
                            <div class="app_width_r_con_flex_width">
                              <div class="app_width_r_con_flex_bottom">
                                简历画像-<span>综合匹配度、估值评分</span>
                              </div>
                            </div>
                            <img src="@/assets/home/zhaopin-img.png" alt="" />
                          </div>
                          <div class="app_width_r_con_flex_width1">
                            <div class="app_width_r_con_flex_width">
                              <div class="app_width_r_con_flex_bottom">
                                智慧话术-<span>AI精准挖掘</span>
                              </div>
                            </div>
                            <img class="mLeft" src="@/assets/application/zhaopin-img-4.png" />
                          </div>
                          <div class="app_width_r_con_flex_width1">
                            <div class="app_width_r_con_flex_width">
                              <div class="app_width_r_con_flex_bottom">
                                到岗机率-<span>匹配度、反馈</span>
                              </div>
                            </div>
                            <img class="mLeft" src="@/assets/application/zhaopin-img-5.png" />
                          </div>
                        </div>
                      </div>
                      <div class="app_width_r_con_1 no-border">
                        <div class="app_width_r_con_name">客户收益</div>
                        <div class="app_width_r_con_desc">
                          客户使用慧眼识才后，收益十分显著。他们节省了大量的金钱和人力成本，简化了招聘流程，提高了招聘效率。客户能够更准确地制定招聘策略，并且可以及时根据实际情况进行调整。总体而言，客户对我们的服务表示高度满意，并与我们签订了长期服务协议。
                        </div>
                        <div class="app_width_r_con_desc_top">
                          <img src="@/assets/customerStories/cus-earning_tel1.png" alt="" srcset="" />
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="生物医药" name="生物医药">
            <div class="container">
              <div class="row gjianjie-jiesao">
                <div class="app_flex">
                  <div class="app_width_r">
                    <div class="app_width_r_title">
                      <div class="app_width_r_title_name">
                        某制药技术公司—一站式解决校招难题
                      </div>
                    </div>
                    <div class="app_width_r_con">
                      <div class="app_width_r_con_1">
                        <div class="app_width_r_con_name">客户需求</div>
                        <div class="app_width_r_con_desc">
                          客户在生物医药行业面临内部人才储备不足的挑战，通过校园招聘寻找优秀的应届毕业生。
                          所以面临两大困难：<br />
                          1.希望在最短时间内从1000名应届毕业生中筛选出100名，需要一个快速高效的校园招聘流程。<br />
                          2.希望除了筛选优秀人才外，还能够获得对这些人才的后续培养和管理建议，以确保他们能够更好地融入公司并取得良好的职业发展。
                        </div>
                      </div>
                      <div class="app_width_r_con_1">
                        <div class="app_width_r_con_name">解决方案</div>
                        <div class="app_width_r_con_desc">
                          在了解客户需求后，创壹科技的慧眼识才，全流程人才评估系统，通过匹配度和估值评分快速筛选出优秀应届毕业生。提供专业报告和建议，助力客户挖掘潜力，实现用人、育人目标。
                        </div>
                        <div class="app_width_r_con_flex">
                          <div class="app_width_r_con_flex_width1">
                            <div class="app_width_r_con_flex_bottom">
                              测评管理-<span>能力素质报告</span>
                            </div>
                          </div>
                          <div class="app_width_r_con_flex_width2">
                            <div class="app_width_r_con_flex_bottom">
                              招聘管理系统-<span>综合匹配度</span>
                            </div>
                          </div>
                        </div>
                        <div class="app_width_r_con_flex">
                          <div class="app_width_r_con_flex_width1">
                            <img src="@/assets/customerStories/page-2-1.png" alt="" />
                          </div>
                          <div class="app_width_r_con_flex_width2">
                            <img src="@/assets/customerStories/page-2-2.png" alt="" />
                          </div>
                        </div>
                        <!-- <div class="app_width_r_con_flex">
                                    <div class="app_width_r_con_flex_width3">
                                        <div class="app_width_r_con_flex_bottom">智慧话术 <span>AI精准挖掘</span></div>
                                    </div>

                                </div>
                                <div class="app_width_r_con_flex">
                                    <div>
                                        <img src="@/assets/customerStories/cus2-3.png" alt="">
                                    </div>
                                </div> -->
                      </div>
                      <div class="app_width_r_con_1 no-border">
                        <div class="app_width_r_con_name">客户收益</div>
                        <div class="app_width_r_con_desc">
                          客户使用慧眼识才之后，实现了人才的科学评估，在招聘过程中更加快速、精准地进行人才挑选。储备人才的同时，也为后续的人才培养和管理提供了有效的的参考建议。
                        </div>
                        <div class="app_width_r_con_desc_top">
                          <img src="@/assets/customerStories/cus-earning_tel2.png" alt="" srcset="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="零售连锁" name="零售连锁">
            <div class="container">
              <div class="row gjianjie-jiesao">
                <div class="app_flex">
                  <div class="app_width_r">
                    <div class="app_width_r_title">
                      <div class="app_width_r_title_name">
                        某服饰有限公司一信息化驱动的绩效管理效率提升
                      </div>
                    </div>
                    <div class="app_width_r_con">
                      <div class="app_width_r_con_1">
                        <div class="app_width_r_con_name">客户需求</div>
                        <div class="app_width_r_con_desc">
                          某知名服饰品牌在业务扩展中面临绩效管理难题，包括庞大企业体系导致信息传递困难、绩效流程监控不力。
                          所以面临两大困难：<br />
                          1.实现灵活、按需搭建的绩效考核体系。<br />
                          2.希望绩效进度随时可见，并通过多维应用结合分析，提升绩效管理效率，解决庞大企业体系导致的信息传递困难、绩效流程监控不力、数据准确性难以保障等问题。-3年之后的发展空间。为此，客户需要一套科学的人才评估方案。
                        </div>
                      </div>
                      <div class="app_width_r_con_1">
                        <div class="app_width_r_con_name">解决方案</div>
                        <div class="app_width_r_con_desc">
                          在了解客户需求后，创壹科技的慧眼识才提供在线绩效管理解决方案，包括自动分发表单、360环评员工绩效，微信小程序完成评定，同时实时追踪绩效进展，助力客户高效管理与辅导。
                        </div>
                        <div class="app_width_r_con_flex">
                          <div class="app_width_r_con_flex_width1">
                            <div class="app_width_r_con_flex_bottom">
                              绩效管理系统-<span>360环评</span>
                            </div>
                          </div>
                          <div class="app_width_r_con_flex_width2">
                            <div class="app_width_r_con_flex_bottom">
                              绩效管理系统-<span>绩效流程追踪</span>
                            </div>
                          </div>
                        </div>
                        <div class="app_width_r_con_flex">
                          <div class="app_width_r_con_flex_width3">
                            <img src="@/assets/application/jixiao-img-1.png" alt="" />
                          </div>
                          <div class="app_width_r_con_flex_width4">
                            <img src="@/assets/application/jixiao-img-2.png" alt="" />
                          </div>
                        </div>
                      </div>
                      <div class="app_width_r_con_1 no-border">
                        <div class="app_width_r_con_name">客户收益</div>
                        <div class="app_width_r_con_desc">
                          自从企业使用慧眼识才以来，在绩效管理方面取得了明显的改善。利用慧眼识才大幅提高绩效管理效率的同时，也减少了人力成本的投入并补齐了传统绩效评定方式所欠缺的员工认可度。
                        </div>
                        <div class="app_width_r_con_desc_top">
                          <img src="@/assets/customerStories/cus-earning_tel3.png" alt="" srcset="" />
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="国央企" name="国央企">
            <div class="container">
              <div class="row gjianjie-jiesao">
                <div class="app_flex">
                  <div class="app_width_r">
                    <div class="app_width_r_title">
                      <div class="app_width_r_title_name">
                        某国有重点骨干企业—人才盘点
                      </div>
                    </div>
                    <div class="app_width_r_con">
                      <div class="app_width_r_con_1">
                        <div class="app_width_r_con_name">客户需求</div>
                        <div class="app_width_r_con_desc">
                          某国有重点骨干企业在“十四五”时期，面临全面深化改革和推动经济高质量发展的攻坚阶段，将人才规划作为战略规划的重要组成部分。作为该企业的客户，客户需求集中在进行全面的人才盘点，以适应未来战略发展的需要。
                          所以面临两大困难：<br />
                          1.客户希望进行全面的人才盘点，以了解和梳理当前企业内部的人才结构、潜力和缺口，为未来发展制定有针对性的人才战略。<br />
                          2.客户迫切需要人才规划作为关键支持，以便更好地融入国家改革和高质量发展的战略，提高人才的整体素质和企业的竞争力。
                        </div>
                      </div>
                      <div class="app_width_r_con_1">
                        <div class="app_width_r_con_name">解决方案</div>
                        <div class="app_width_r_con_desc">
                          在了解客户需求后，创壹科技的慧眼识才提供全面评估员工，深度分析潜力，呈现直观价值报告。提供精准测评信息，通过人才地图找出优质高贡献人才，为人才规划提供支持。
                        </div>
                        <div class="app_width_r_con_flex">
                          <div class="app_width_r_con_flex_width1">
                            <div class="app_width_r_con_flex_bottom">
                              人才盘点-<span>多维立体盘点</span>
                            </div>
                          </div>
                          <div class="app_width_r_con_flex_width1">
                            <div class="app_width_r_con_flex_bottom">
                              人才报告-<span>价值评估分析</span>
                            </div>
                          </div>
                          <div class="app_width_r_con_flex_width1">
                            <div class="app_width_r_con_flex_bottom">
                              人才价值对比-<span>直观选拔</span>
                            </div>
                          </div>
                        </div>
                        <div class="app_width_r_con_flex">
                          <div class="app_width_r_con_flex_width1">
                            <img src="@/assets/customerStories/page-3-1.png" alt="" />
                          </div>
                          <div class="app_width_r_con_flex_width1">
                            <img src="@/assets/customerStories/page-3-2.png" alt="" />
                          </div>
                          <div class="app_width_r_con_flex_width1">
                            <img src="@/assets/application/pandian-img-2.png" alt="" />
                          </div>
                        </div>
                      </div>
                      <div class="app_width_r_con_1 no-border">
                        <div class="app_width_r_con_name">客户收益</div>
                        <div class="app_width_r_con_desc">
                          客户通过使用慧眼识才建立了一套科学客观的人才价值评估体系，有效做到人才数据落地并辅助人才规划决策，实现了企业人才和组织效能的提升，促进了员工和组织的共同发展。客户对慧眼识才的效果非常满意，我们也因此与客户成为了长期合作伙伴。
                        </div>
                        <div class="app_width_r_con_desc_top">
                          <img src="@/assets/customerStories/cus-earning_tel4.png" alt="" srcset="" />
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="生产制造" name="生产制造">
            <div class="container">
              <div class="row gjianjie-jiesao">
                <div class="app_flex">
                  <div class="app_width_r">
                    <div class="app_width_r_title">
                      <div class="app_width_r_title_name">
                        某制造公司—高效、合规的用工方案
                      </div>
                    </div>
                    <div class="app_width_r_con">
                      <div class="app_width_r_con_1">
                        <div class="app_width_r_con_name">客户需求</div>
                        <div class="app_width_r_con_desc">
                          客户企业分布于各地，各分部独立进行人力资源管理，但地域性汇总导致耗时且容易出错，并因此产生用工纠纷和法律风险。
                          所以面临两大困难：<br />
                          1.客户希望建立一套系统，实现员工信息的无纸化存档，摆脱纸质档案的繁琐管理和地域性汇总的问题。<br />
                          2.客户迫切需要一个集中管理系统，以降低合规风险，确保员工信息的准确性和完整性，满足法规要求。
                        </div>
                      </div>
                      <div class="app_width_r_con_1">
                        <div class="app_width_r_con_name">解决方案</div>
                        <div class="app_width_r_con_desc">
                          创壹科技的慧眼识才系统覆盖员工全生命周期，通过小程序让员工自助填写信息，系统智能识别生成数字档案，随时在线编辑。多账号协同办公功能实现信息档案的统一管理，省去繁琐的档案汇总和盘点。慧眼识才还提供用工风险排查，检测并预警用工过程中可能存在的风险点，确保用工流程的安全和高效。
                        </div>
                        <div class="app_width_r_con_flex">
                          <div class="app_width_r_con_flex_width1">
                            <div class="app_width_r_con_flex_bottom">
                              员工数字档案-<span>无纸化存档</span>
                            </div>
                          </div>
                          <div class="app_width_r_con_flex_width2">
                            <div class="app_width_r_con_flex_bottom">
                              风险检测预警-<span>多维度风险筛查</span>
                            </div>
                          </div>
                        </div>
                        <div class="app_width_r_con_flex">
                          <div class="app_width_r_con_flex_width1">
                            <img src="@/assets/application/zuzhi-img-2.png" alt="" />
                          </div>
                          <div class="app_width_r_con_flex_width2">
                            <img src="@/assets/application/fengxian-img-1.png" alt="" />
                          </div>
                        </div>
                      </div>
                      <div class="app_width_r_con_1 no-border">
                        <div class="app_width_r_con_name">客户收益</div>
                        <div class="app_width_r_con_desc">
                          客户通过慧眼识才快速实现了对各分部人员档案的统一管理，提高了人事部门的工作效率与准确度。同时，客户对慧眼识才的风险排查与预警提示表示肯定，并向其他企业推荐了慧眼识才，成功实现付费转化。
                        </div>
                        <div class="app_width_r_con_desc_top">
                          <img src="@/assets/customerStories/cus-earning_tel5.png" alt="" srcset="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="pc">
      <div class="joinyemian">
        <div class="join-banner">
          <div class="banner_img banner_po">
            <img src="@/assets/customerStories/customerStories-banner.png" alt="" />
            <div class="banner_ab">
              <div class="app_title">客户案例</div>
              <div class="app_desc1">聚焦AI技术和大数据技术的研发与应用，</div>
              <div class="app_desc1">致力于帮助企业持续提高效能并规避合规风险</div>
              <button class="reservation" @click="isDialog('customersFree', 'pc')" id="show2">申请试用</button>
            </div>
          </div>
          <el-tabs v-model="posiTitle" :tab-position="tabPosition" @tab-click="tabClick">
            <el-tab-pane label="信息安全" name="信息安全">
              <div class="container">
                <div class="row gjianjie-jiesao">
                  <div class="app_flex">
                    <div class="app_width_r">
                      <div class="app_width_r_title">
                        <div class="app_width_r_title_name">
                          某计算机科技公司—优化招聘流程
                        </div>
                      </div>
                      <div class="app_width_r_con">
                        <div class="app_width_r_con_1">
                          <div class="app_width_r_con_name">客户需求</div>
                          <div class="app_width_r_con_desc">
                            该客户所属于信息安全行业，竞争非常激烈。为保持竞争优势，需要大量招聘软件工程师来支持其研发需求，但面临两大困难：
                          </div>
                          <div class="app_width_r_con_desc">
                            1.行业内人才挤兑，大量的候选人简历等待筛选和评估，HR和用人需求部门需要在有限的时间内完成候选人的评估和完成招聘任务，规避因招不到人而影响公司发展。
                          </div>
                          <div class="app_width_r_con_desc">
                            2.需要一整套招聘管理系统来解决招聘全流程的需要，最重要的核心是满足对候选简历的筛选不会因为人工疏忽或人工主观因素的臆判而影响人才的精准需要。
                          </div>
                        </div>
                        <div class="app_width_r_con_1">
                          <div class="app_width_r_con_name">解决方案</div>
                          <div class="app_width_r_con_desc">
                            在了解客户需求后，创壹科技慧眼识才结合AI与大数据，提供一键招聘解决方案。匹配、筛选候选人，智能话术，实时查看招聘数据支持调整，对比分析渠道效果，提高效率。
                          </div>
                          <div class="app_width_r_con_flex">
                            <div class="app_width_r_con_flex_width2">
                              <div class="app_width_r_con_flex_bottom">
                                简历画像-<span>综合匹配度、估值评分</span>
                              </div>
                            </div>
                            <div class="app_width_r_con_flex_width1">
                              <div class="app_width_r_con_flex_bottom">
                                智慧话术-<span>AI精准挖掘</span>
                              </div>
                            </div>
                            <div class="app_width_r_con_flex_width1">
                              <div class="app_width_r_con_flex_bottom">
                                到岗机率-<span>匹配度、反馈</span>
                              </div>
                            </div>
                          </div>
                          <div class="app_width_r_con_flex">
                            <div class="app_width_r_con_flex_width2">
                              <img src="@/assets/home/zhaopin-img.png" alt="" />
                            </div>
                            <div class="app_width_r_con_flex_width1">
                              <img src="@/assets/application/zhaopin-img-4.png" alt="" />
                            </div>
                            <div class="app_width_r_con_flex_width1">
                              <img src="@/assets/application/zhaopin-img-5.png" alt="" />
                            </div>
                          </div>
                        </div>
                        <div class="app_width_r_con_1 no-border">
                          <div class="app_width_r_con_name">客户收益</div>
                          <div class="app_width_r_con_desc">
                            客户使用慧眼识才后，收益十分显著。他们节省了大量的金钱和人力成本，简化了招聘流程，提高了招聘效率。客户能够更准确地制定招聘策略，并且可以及时根据实际情况进行调整。总体而言，客户对我们的服务表示高度满意，并与我们签订了长期服务协议。
                          </div>
                          <div class="app_width_r_con_desc_top">
                            <img src="@/assets/customerStories/cus-earning1.png" alt="" srcset="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="app_width_l">
                      <div class="cus_po">
                        <img src="@/assets/customerStories/cus-r.png" alt="" srcset="" />
                        <div class="cus_ab">信息安全</div>
                      </div>
                      <div class="app_width_l_pro">
                        <div class="app_width_l_title">使用产品</div>
                        <div class="app_width_l_desc">
                          多维度综合评估候选人简历-综合匹配度
                        </div>
                        <div class="app_width_l_desc">智慧话术-AI精准挖掘</div>
                        <div class="app_width_l_desc">
                          到岗机率-匹配度、反馈
                        </div>
                      </div>
                      <button class="app_width_l_free" @click="isDialog('securityFreeList', 'pc')" id="show3">
                        申请试用
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="生物医药" name="生物医药">
              <div class="container">
                <div class="row gjianjie-jiesao">
                  <div class="app_flex">
                    <div class="app_width_r">
                      <div class="app_width_r_title">
                        <div class="app_width_r_title_name">
                          某制药技术公司—一站式解决校招难题
                        </div>
                      </div>
                      <div class="app_width_r_con">
                        <div class="app_width_r_con_1">
                          <div class="app_width_r_con_name">客户需求</div>
                          <div class="app_width_r_con_desc">
                            客户在生物医药行业面临内部人才储备不足的挑战，通过校园招聘寻找优秀的应届毕业生。
                            所以面临两大困难：<br />
                            1.希望在最短时间内从1000名应届毕业生中筛选出100名，需要一个快速高效的校园招聘流程。<br />
                            2.希望除了筛选优秀人才外，还能够获得对这些人才的后续培养和管理建议，以确保他们能够更好地融入公司并取得良好的职业发展。
                          </div>
                        </div>
                        <div class="app_width_r_con_1">
                          <div class="app_width_r_con_name">解决方案</div>
                          <div class="app_width_r_con_desc">
                            在了解客户需求后，创壹科技的慧眼识才，全流程人才评估系统，通过匹配度和估值评分快速筛选出优秀应届毕业生。提供专业报告和建议，助力客户挖掘潜力，实现用人、育人目标。
                          </div>
                          <div class="app_width_r_con_flex">
                            <div class="app_width_r_con_flex_width1">
                              <div class="app_width_r_con_flex_bottom">
                                测评管理-<span>能力素质报告</span>
                              </div>
                            </div>
                            <div class="app_width_r_con_flex_width2">
                              <div class="app_width_r_con_flex_bottom">
                                招聘管理系统-<span>综合匹配度</span>
                              </div>
                            </div>
                          </div>
                          <div class="app_width_r_con_flex">
                            <div class="app_width_r_con_flex_width1">
                              <img src="@/assets/customerStories/page-2-1.png" alt="" />
                            </div>
                            <div class="app_width_r_con_flex_width2">
                              <img src="@/assets/customerStories/page-2-2.png" alt="" />
                            </div>
                          </div>

                        </div>
                        <div class="app_width_r_con_1 no-border">
                          <div class="app_width_r_con_name">客户收益</div>
                          <div class="app_width_r_con_desc">
                            客户使用慧眼识才之后，实现了人才的科学评估，在招聘过程中更加快速、精准地进行人才挑选。储备人才的同时，也为后续的人才培养和管理提供了有效的的参考建议。
                          </div>
                          <div class="app_width_r_con_desc_top">
                            <img src="@/assets/customerStories/cus-earning2.png" alt="" srcset="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="app_width_l">
                      <div class="cus_po">
                        <img src="@/assets/customerStories/cus-r.png" alt="" srcset="" />
                        <div class="cus_ab">生物医药</div>
                      </div>
                      <div class="app_width_l_pro">
                        <div class="app_width_l_title">使用产品</div>
                        <div class="app_width_l_desc">
                          测评管理-能力素质报告
                        </div>
                        <div class="app_width_l_desc">
                          招聘管理系统-综合匹配度
                        </div>
                      </div>
                      <button class="app_width_l_free" @click="isDialog('creatureFreeList', 'pc')" id="show3">
                        申请试用
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="零售连锁" name="零售连锁">
              <div class="container">
                <div class="row gjianjie-jiesao">
                  <div class="app_flex">
                    <div class="app_width_r">
                      <div class="app_width_r_title">
                        <div class="app_width_r_title_name">
                          某服饰有限公司一信息化驱动的绩效管理效率提升
                        </div>
                      </div>
                      <div class="app_width_r_con">
                        <div class="app_width_r_con_1">
                          <div class="app_width_r_con_name">客户需求</div>
                          <div class="app_width_r_con_desc">
                            某知名服饰品牌在业务扩展中面临绩效管理难题，包括庞大企业体系导致信息传递困难、绩效流程监控不力。
                            所以面临两大困难：<br />
                            1.实现灵活、按需搭建的绩效考核体系。<br />
                            2.希望绩效进度随时可见，并通过多维应用结合分析，提升绩效管理效率，解决庞大企业体系导致的信息传递困难、绩效流程监控不力、数据准确性难以保障等问题。-3年之后的发展空间。为此，客户需要一套科学的人才评估方案。
                          </div>
                        </div>
                        <div class="app_width_r_con_1">
                          <div class="app_width_r_con_name">解决方案</div>
                          <div class="app_width_r_con_desc">
                            在了解客户需求后，创壹科技的慧眼识才提供在线绩效管理解决方案，包括自动分发表单、360环评员工绩效，微信小程序完成评定，同时实时追踪绩效进展，助力客户高效管理与辅导。
                          </div>
                          <div class="app_width_r_con_flex">
                            <div class="app_width_r_con_flex_width1">
                              <div class="app_width_r_con_flex_bottom">
                                绩效管理系统-<span>360环评</span>
                              </div>
                            </div>
                            <div class="app_width_r_con_flex_width2">
                              <div class="app_width_r_con_flex_bottom">
                                绩效管理系统-<span>绩效流程追踪</span>
                              </div>
                            </div>
                          </div>
                          <div class="app_width_r_con_flex">
                            <div class="app_width_r_con_flex_width3">
                              <img src="@/assets/application/jixiao-img-1.png" alt="" />
                            </div>
                            <div class="app_width_r_con_flex_width4">
                              <img src="@/assets/application/jixiao-img-2.png" alt="" />
                            </div>
                          </div>
                        </div>
                        <div class="app_width_r_con_1 no-border">
                          <div class="app_width_r_con_name">客户收益</div>
                          <div class="app_width_r_con_desc">
                            自从企业使用慧眼识才以来，在绩效管理方面取得了明显的改善。利用慧眼识才大幅提高绩效管理效率的同时，也减少了人力成本的投入并补齐了传统绩效评定方式所欠缺的员工认可度。
                          </div>
                          <div class="app_width_r_con_desc_top">
                            <img src="@/assets/customerStories/cus-earning3.png" alt="" srcset="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="app_width_l">
                      <div class="cus_po">
                        <img src="@/assets/customerStories/cus-r.png" alt="" srcset="" />
                        <div class="cus_ab">零售连锁</div>
                      </div>
                      <div class="app_width_l_pro">
                        <div class="app_width_l_title">使用产品</div>
                        <div class="app_width_l_desc">绩效管理系统-360环评</div>
                        <div class="app_width_l_desc">
                          绩效管理系统-绩效流程追踪
                        </div>
                      </div>
                      <button @click="isDialog('chainFreeList', 'pc')" class="app_width_l_free" id="show3">
                        申请试用
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="国央企" name="国央企">
              <div class="container">
                <div class="row gjianjie-jiesao">
                  <div class="app_flex">
                    <div class="app_width_r">
                      <div class="app_width_r_title">
                        <div class="app_width_r_title_name">
                          某国有重点骨干企业—人才盘点
                        </div>
                      </div>
                      <div class="app_width_r_con">
                        <div class="app_width_r_con_1">
                          <div class="app_width_r_con_name">客户需求</div>
                          <div class="app_width_r_con_desc">
                            某国有重点骨干企业在“十四五”时期，面临全面深化改革和推动经济高质量发展的攻坚阶段，将人才规划作为战略规划的重要组成部分。作为该企业的客户，客户需求集中在进行全面的人才盘点，以适应未来战略发展的需要。
                            所以面临两大困难：<br />
                            1.客户希望进行全面的人才盘点，以了解和梳理当前企业内部的人才结构、潜力和缺口，为未来发展制定有针对性的人才战略。<br />
                            2.客户迫切需要人才规划作为关键支持，以便更好地融入国家改革和高质量发展的战略，提高人才的整体素质和企业的竞争力。
                          </div>
                        </div>
                        <div class="app_width_r_con_1">
                          <div class="app_width_r_con_name">解决方案</div>
                          <div class="app_width_r_con_desc">
                            在了解客户需求后，创壹科技的慧眼识才提供全面评估员工，深度分析潜力，呈现直观价值报告。提供精准测评信息，通过人才地图找出优质高贡献人才，为人才规划提供支持。
                          </div>
                          <div class="app_width_r_con_flex">
                            <div class="app_width_r_con_flex_width1">
                              <div class="app_width_r_con_flex_bottom">
                                人才盘点-<span>多维立体盘点</span>
                              </div>
                            </div>
                            <div class="app_width_r_con_flex_width1">
                              <div class="app_width_r_con_flex_bottom">
                                人才报告-<span>价值评估分析</span>
                              </div>
                            </div>
                            <div class="app_width_r_con_flex_width1">
                              <div class="app_width_r_con_flex_bottom">
                                人才价值对比-<span>直观选拔</span>
                              </div>
                            </div>
                          </div>
                          <div class="app_width_r_con_flex">
                            <div class="app_width_r_con_flex_width1">
                              <img src="@/assets/customerStories/page-3-1.png" alt="" />
                            </div>
                            <div class="app_width_r_con_flex_width1">
                              <img src="@/assets/customerStories/page-3-2.png" alt="" />
                            </div>
                            <div class="app_width_r_con_flex_width1">
                              <img src="@/assets/application/pandian-img-2.png" alt="" />
                            </div>
                          </div>
                        </div>
                        <div class="app_width_r_con_1 no-border">
                          <div class="app_width_r_con_name">客户收益</div>
                          <div class="app_width_r_con_desc">
                            客户通过使用慧眼识才建立了一套科学客观的人才价值评估体系，有效做到人才数据落地并辅助人才规划决策，实现了企业人才和组织效能的提升，促进了员工和组织的共同发展。客户对慧眼识才的效果非常满意，我们也因此与客户成为了长期合作伙伴。
                          </div>
                          <div class="app_width_r_con_desc_top">
                            <img src="@/assets/customerStories/cus-earning4.png" alt="" srcset="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="app_width_l">
                      <div class="cus_po">
                        <img src="@/assets/customerStories/cus-r.png" alt="" srcset="" />
                        <div class="cus_ab">国央企</div>
                      </div>
                      <div class="app_width_l_pro">
                        <div class="app_width_l_title">使用产品</div>
                        <div class="app_width_l_desc">
                          人才盘点-多维立体盘点
                        </div>
                        <div class="app_width_l_desc">
                          人才报告-价值评估分析
                        </div>
                        <div class="app_width_l_desc">
                          人才价值对比-直观选拔
                        </div>
                      </div>
                      <button @click="isDialog('chEnterpriseFreeList', 'pc')" class="app_width_l_free" id="show3">
                        申请试用
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="生产制造" name="生产制造">
              <div class="container">
                <div class="row gjianjie-jiesao">
                  <div class="app_flex">
                    <div class="app_width_r">
                      <div class="app_width_r_title">
                        <div class="app_width_r_title_name">
                          某制造公司—高效、合规的用工方案
                        </div>
                      </div>
                      <div class="app_width_r_con">
                        <div class="app_width_r_con_1">
                          <div class="app_width_r_con_name">客户需求</div>
                          <div class="app_width_r_con_desc">
                            客户企业分布于各地，各分部独立进行人力资源管理，但地域性汇总导致耗时且容易出错，并因此产生用工纠纷和法律风险。
                            所以面临两大困难：<br />
                            1.客户希望建立一套系统，实现员工信息的无纸化存档，摆脱纸质档案的繁琐管理和地域性汇总的问题。<br />
                            2.客户迫切需要一个集中管理系统，以降低合规风险，确保员工信息的准确性和完整性，满足法规要求。
                          </div>
                        </div>
                        <div class="app_width_r_con_1">
                          <div class="app_width_r_con_name">解决方案</div>
                          <div class="app_width_r_con_desc">
                            创壹科技的慧眼识才系统覆盖员工全生命周期，通过小程序让员工自助填写信息，系统智能识别生成数字档案，随时在线编辑。多账号协同办公功能实现信息档案的统一管理，省去繁琐的档案汇总和盘点。慧眼识才还提供用工风险排查，检测并预警用工过程中可能存在的风险点，确保用工流程的安全和高效。
                          </div>
                          <div class="app_width_r_con_flex">
                            <div class="app_width_r_con_flex_width1">
                              <div class="app_width_r_con_flex_bottom">
                                员工数字档案-<span>无纸化存档</span>
                              </div>
                            </div>
                            <div class="app_width_r_con_flex_width2">
                              <div class="app_width_r_con_flex_bottom">
                                风险检测预警-<span>多维度风险筛查</span>
                              </div>
                            </div>
                          </div>
                          <div class="app_width_r_con_flex">
                            <div class="app_width_r_con_flex_width1">
                              <img src="@/assets/application/zuzhi-img-2.png" alt="" />
                            </div>
                            <div class="app_width_r_con_flex_width2">
                              <img src="@/assets/application/fengxian-img-1.png" alt="" />
                            </div>
                          </div>
                        </div>
                        <div class="app_width_r_con_1 no-border">
                          <div class="app_width_r_con_name">客户收益</div>
                          <div class="app_width_r_con_desc">
                            客户通过慧眼识才快速实现了对各分部人员档案的统一管理，提高了人事部门的工作效率与准确度。同时，客户对慧眼识才的风险排查与预警提示表示肯定，并向其他企业推荐了慧眼识才，成功实现付费转化。
                          </div>
                          <div class="app_width_r_con_desc_top">
                            <img src="@/assets/customerStories/cus-earning5.png" alt="" srcset="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="app_width_l">
                      <div class="cus_po">
                        <img src="@/assets/customerStories/cus-r.png" alt="" srcset="" />
                        <div class="cus_ab">生产制造</div>
                      </div>
                      <div class="app_width_l_pro">
                        <div class="app_width_l_title">使用产品</div>
                        <div class="app_width_l_desc">
                          员工数字档案-无纸化存档
                        </div>
                        <div class="app_width_l_desc">
                          风险检测预警-多维度风险筛查
                        </div>
                      </div>
                      <button @click="isDialog('manufacturingFreeList', 'pc')" class="app_width_l_free" id="show3">
                        申请试用
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>

    <hysc-dia v-if="visibleD" :visible.sync="visibleD" @getClickCancel="getClickClose"></hysc-dia>
  </div>
</template>

<script>
import HyscDia from '../Dialog/dialog.vue'
import { website } from '@/api/gw.js'

export default {
  data() {
    return {
      visibleD: false,
      tabPosition: 'top',
      tabPositionTel: 'top',
      posiTitle: '信息安全',
      customerInfo: [
        { val: 'securityPc', valTel: 'securityTel' },
        { val: 'creaturePc', valTel: 'creatureTel' },
        { val: 'chainPc', valTel: 'chainTel' },
        { val: 'chEnterprisePc', valTel: 'chEnterpriseTel' },
        { val: 'manufacturingPc', valTel: 'manufacturingTel' }
      ],
      customer: [{
        title: '某计算机科技公司—优化招聘流程',
        nedd: '该客户所属于信息安全行业，竞争非常激烈。为保持竞争优势，需要大量招聘软件工程师来支持其研发需求，但面临两大困难：1.行业内人才挤兑，大量的候选人简历等待筛选和评估，HR和用人需求部门需要在有限的时间内完成候选人的评估和完成招聘任务，规避因招不到人而影响公司发展。2.需要一整套招聘管理系统来解决招聘全流程的需要，最重要的核心是满足对候选简历的筛选不会因为人工疏忽或人工主观因素的臆判而影响人才的精准需要。',
        solution: {
          desc: '在了解客户需求后，创壹科技慧眼识才结合AI与大数据，提供一键招聘解决方案。匹配、筛选候选人，智能话术，实时查看招聘数据支持调整，对比分析渠道效果，提高效率。',
          imgs: [
            {
              title: '简历画像-综合匹配度、估值评分',
              iimag: '',
            },
          ],
        },
        kehu: '',
      }],
    };
  },
  components: {
    HyscDia,
  },
  methods: {
    // 点击做记录
    getClick(val, type) {
      website({ type: val, name: type }).then((res) => {
        console.log(res, 'rrr');
      })
    },

    tabClick(e) {
      const val = this.customerInfo[e.index].val
      this.getClick(val, 'pc')
    },

    tabClickTel(e) {
      const val = this.customerInfo[e.index].valTel
      this.getClick(val, 'tel')
    },

    isDialog(val, type) {
      this.getClick(val, type)
      this.visibleD = true
      // this.$router.push('/Purchase')
    },

    getClickClose() {
      this.visibleD = false
    }

  },
  mounted() {
    if (this.$route.query && this.$route.query.title) {
      this.posiTitle = this.$route.query.title
    }
  }
}
</script>
<!-- tel -->
<style scoped>
.pc {
  display: block !important;
}

.tel {
  display: none !important;
}

@media screen and (max-width: 430px) and (min-width: 391px) {
  .tel .elTab[data-v-96f54922] {
    padding-top: 340px;
    margin: 0 15px;
  }
}

@media (max-width: 1200px) {
  /* 客户案例 */

  :deep(.tel .el-tabs__nav-scroll) {
    padding: 6px 0;
    border-radius: 0;
  }

  :deep(.tel .el-tabs__nav-scroll) {
    box-shadow: initial;
  }

  .tel .gjianjie-jiesao {
    padding: 50px 0 15px 0;
  }

  .tel .gjianjie-jiesao .app_width_r_title_name {
    color: #4d5565;
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .tel .gjianjie-jiesao .app_width_r_con_name {
    font-size: 18px;
    color: #5577aa;
    font-weight: 600;
    padding-bottom: 15px;
    border-top: 1px solid #D9D9DB;
    padding: 20px 0 10px 0;
    margin-top: 15px;
  }

  .tel .gjianjie-jiesao .app_width_r_con_desc {
    font-size: 16px;
    letter-spacing: 0.94px;
    color: #666;
    line-height: 30px;
  }

  .tel .app_width_r_con_flex {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
  }

  .tel .app_width_r_con_desc_top img {
    width: 100%;
  }

  .tel .app_width_r_con_flex_width2 {
    width: 48%;
  }

  .tel .app_width_r_con_flex_width1 {
    width: 48%;
  }

  .tel .app_width_r_con_flex_bottom {
    font-size: 12px;
    font-weight: 600;
  }

  .tel .app_width_r_con_flex_bottom span {
    color: #186fff;
  }

  .tel .app_width_r_con_flex img {
    width: 147px;
  }

  .tel .app_width_r_con_flex .mLeft {
    margin-left: -10px;
  }

  .tel .elTab {
    padding-top: 315px;
    margin: 0 15px;
  }

  .tel .page-content .section {
    /* display: flex; */
    align-items: center;
    padding: 30px 0;
    background: #f9fbff;
    margin: 20px 0;
    box-shadow: 0px 6px 31px 0px rgba(29, 67, 132, 0.373);
    border-radius: 8px;
  }

  .tel .page-content {
    margin: 0 15px;
  }

  .tel .page-content .section .section-wrap .images {
    background: #fff;
    height: 300px;
    margin-top: 15px;
  }

  .tel .page-content .section:nth-child(odd) .section-wrap .description {
    margin-left: 0px;
    letter-spacing: 0.82px;
    color: #666666;
  }

  .tel .page-content .section .section-wrap .description {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .tel .page-wrap {
    margin-top: 30px;
  }

  .tel .page-content .section:nth-child(even) .section-wrap .images {
    margin-left: 0px;
  }

  .tel .page-content .section:nth-child(even) .section-wrap .images img {
    padding: 20px;
  }

  .tel .page-content .section .section-wrap .description .title {
    color: #4d5565;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .tel .page-content .section .section-wrap .description .text {
    margin-top: 15px;
  }

  .tel .noDis {
    width: 50%;
  }

  .tel .noDis .value-item-sub-title {
    font-size: 14px !important;
  }

  .tel .title {
    color: #4d5565;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
  }

  :deep(.tel .el-tabs__content) {
    background: none;
    box-shadow: initial;
  }

  :deep(.el-tabs__item) {
    margin: 0;
  }

  .pc {
    display: none !important;
  }

  .tel {
    display: block !important;
  }

  :deep(.el-tabs__nav-scroll) {
    width: 100%;
    overflow-x: scroll;
  }

  :deep(.el-tabs__nav-wrap span) {
    display: none;
  }

  :deep(.el-tabs__nav-wrap.is-scrollable) {
    padding: 0 0;
  }

  .tel .banner {
    background: url(../../assets/telbg3.png) no-repeat;
    background-size: 100%;
    height: 400px;
    position: absolute;
    top: 0px;
    width: 100%;
  }

  .tel .teldesc {
    position: absolute;
    left: 0%;
    right: 50%;
    top: 30%;
    width: 100%;
    text-align: center;
  }

  .tel .teldesc .teld1 {
    font-size: 40px;
    font-weight: bold;
    color: #FFFFFF;
    letter-spacing: 1.82px;
  }

  .teld2 {
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 1.82px;
  }

  .teld2,
  .teld3 {
    color: #fff;
  }

  .telTop {
    margin-top: 20px;
  }

  .teld3 {
    font-size: 16px;
    padding: 0 30px;
  }

  .tel .zhaopinBG {
    background: linear-gradient(310deg, #186fff 12%, #32a9f7 101%);
    border-radius: 13px 13px 0px 0px;
    text-align: center;
  }

  .tel .zhaopinBG img {
    width: 90%;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  :deep(.el-tabs__content) {
    width: 100% !important;
    background: #ffffff;
    border-radius: 13px;
    box-shadow: 0px 6px 31px 0px rgba(29, 67, 132, 0.373);
  }

  .tel .profuile {
    padding-top: 320px;
  }

  .tel .zhaopinDesc {
    padding: 20px 20px 0 20px;
  }

  .tel .zhaopinDesc .zhaoPinTitle {
    font-size: 20px;
    color: #4d5565;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .tel .zhaopinDesc img {
    width: 15px;
    height: 15px;
    margin-top: 0;
    margin-right: 10px;
    margin-bottom: 0;
  }

  .tel .zhaopinDesc .zhaopinDis {
    display: flex;
    align-items: center;
    margin-bottom: 38px;
    color: #333;
    letter-spacing: 1.35px;
  }

  .tel .zhaopinDesc .infoR {
    justify-content: center;
    color: #186fff;
    font-weight: 500;
  }

  .tel .zhaopinDesc .zhaopinDis .zhaopinR {
    width: 15px;
    height: 11px;
    margin-left: 6px;
  }

  /* 解决方案 */
  :deep(.solution .el-tabs__nav-scroll) {
    padding: 0 !important;
  }

  .tel .solution {
    background-color: initial;
  }

  .tel .solutionBG {
    background-size: 100% 100%;
    height: 366px;
    color: #fff;
  }

  .tel .soluDis {
    justify-content: center;
    display: flex;
    position: relative;
  }

  .tel .soluDis .soluDL {
    margin-left: 20px;
    position: relative;
  }

  .tel .soluDis div::before {
    content: '';
    width: 4px;
    height: 4px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: 9px;
    left: -10px;
  }

  .tel .soluDes {
    margin: 0 20px;
    padding: 15px 20px 10px 20px;
  }

  .tel .soluBorder {
    border-top: 1px dotted #fff;
    border-bottom: 1px dotted #fff;
  }

  .tel .soluDes .soluDt {
    font-size: 20px;
  }

  .tel .soluDes .soluDd {
    font-size: 14px;
    margin-top: 10px;
  }

  .tel .soluF {
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1.35px;
    margin-top: 5px;
  }

  .tel .soluF img {
    width: 15px;
    height: 11px;
    margin-top: 0;
    margin-left: 10px;
  }

  .tel .monBg {
    box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.1476);
    text-align: center;
    padding-bottom: 22px;
    border-radius: 12px;
    background: #f7faff;
    margin-top: 22px;
  }

  .tel .monBg .monImg {
    width: 80%;
    margin-top: 22px;
  }

  .tel .monBg .part {
    width: 90%;
    height: 124px;
    margin: 22px auto;
    border-radius: 8px;
    border: 1px solid #d3e4ff;
    border-left: 9px solid #186fff;
    background: linear-gradient(111deg, #ffffff 0%, #eaf1ff 100%);
  }

  .tel .monBg .part .bg-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 23px;
    gap: 9px;
    text-align: left;
  }

  .tel .monBg .part .bg-wrap.bg1 {
    background: url(../../assets/home/monitor-bg1.png) no-repeat;
    background-size: 43%;
    background-position: 120% -40%;
    background-origin: content-box;
  }

  .tel .monBg .part .bg-wrap.bg2 {
    background: url(../../assets/home/monitor-bg2.png) no-repeat;
    background-size: 43%;
    background-position: 120% -40%;
    background-origin: content-box;
  }

  .tel .monBg .part .bg-wrap.bg3 {
    background: url(../../assets/home/monitor-bg3.png) no-repeat;
    background-size: 43%;
    background-position: 120% -40%;
    background-origin: content-box;
  }

  .tel .monBg .part .bg-wrap.bg4 {
    background: url(../../assets/home/monitor-bg4.png) no-repeat;
    background-size: 43%;
    background-position: 120% -40%;
    background-origin: content-box;
  }

  .tel .monBg .part .text {
    color: #333;
    font-size: 20px;
    font-weight: bold;
  }

  .tel .monBg .part .sub-text {
    color: #666;
    font-size: 15px;
  }

  .tel .change {
    background: none;
  }

  .tel .change .container .row .flex-8 .flex-width {
    width: 48%;
  }

  .tel .change .container .row .flex-8 .flex-width img {
    margin-top: 0;
  }

  /* 为什么 */
  .tel .change .container .row .flex-8 .flex-width .change_desc1 {
    font-size: 12px;
    margin-bottom: 23px;
  }

  .tel .change .container .row .flex-8 .flex-width .change_desc2 {
    font-size: 10px;
    color: #666;
    letter-spacing: 0.77px;
    line-height: 20px;
  }

  .tel .achievement .ach .flex-width {
    width: 48%;
    font-size: 12px;
    margin-top: 50px;
    color: #666;
    padding-left: 0%;
    margin-left: 0;
  }

  .tel .profuile .container .row .flex-8 .flex-width .details {
    display: none;
    -webkit-transition: All 0.4s;
  }

  .tel .profuile .container .row .flex-8 .flex-width .details .details_flex {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: white;
  }

  .tel .profuile .container .row .flex-8 .flex-width .details .details_flex img {
    width: 14px;
    height: 14px;
    margin-left: 5px;
  }

  .tel .profuile .container .row .flex-8 .flex-width:hover {
    background: linear-gradient(134deg, #00aaff -2%, #0085ff 60%);
    box-shadow: 0px 5px 12px 0px #ced3da;
    -webkit-transform: translate(0, -10px);
  }

  .tel .profuile .container .row .flex-8 .flex-width:hover .change_desc1 {
    color: white;
  }

  .tel .profuile .container .row .flex-8 .flex-width:hover .change_desc2 {
    color: white;
  }

  .tel .profuile .container .row .flex-8 .flex-width:hover .title {
    color: white;
  }

  .tel .profuile .container .row .flex-8 .flex-width:hover .desc {
    display: none;
  }

  .tel .achievement .ach .flex-width .flex-color {
    color: #186fff;
    font-weight: 500;
    font-size: 20px;
    display: flex;
  }

  .tel .noleft {
    width: 48% !important;
  }

  .tel .isleft {
    width: 48% !important;
    /* margin-left: 14%; */
  }

  .tel .isleft2 {
    width: 48% !important;
    /* margin-left: 12%; */
  }

  .tel .achievement .need {
    margin-top: -50px;
  }

  .tel .need .pcNeed {
    display: none;
  }

  .tel .need .telNeed {
    display: block;
    display: flex;
    flex-wrap: wrap;
  }

  .tel .need .telNeed img {
    width: 25%;
    box-sizing: border-box;
    border: 1px solid #d4d4d4;
    margin-top: 0;
  }
}
</style>

<style scoped>
:deep(.el-tabs__header) {
  margin: auto;
}

:deep(.el-tabs__nav-wrap) {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  overflow: initial;
}

:deep(.el-tabs__nav-scroll) {
  z-index: 22;
  position: absolute;
  top: -70px;
  /* width: 45%; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 36px 0;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
}

@media (min-width: 1200px) {
  :deep(.el-tabs__nav-scroll) {
    width: 1170px;
  }
}

:deep(.el-tabs__nav) {
  float: initial;
}

:deep(.el-tabs__active-bar) {
  display: none;
}

:deep(.el-tabs__item) {
  padding: 0 70px;
}

:deep(.el-tabs__nav-wrap::after) {
  background-color: initial;
}

:deep(.el-tab-pane .container) {
  padding-left: 0;
  padding-right: 0;
}

:deep(.el-tabs__nav .is-active) {
  background: linear-gradient(90deg, #52aaff 0%, #186fff 98%);
  color: #fff;
  border-radius: 80px;
  height: 32px;
  width: 100px;
  line-height: 32px;
  padding: 0;
}

:deep(.el-tabs__nav-scroll::-webkit-scrollbar) {
  display: none
}

:deep(.el-tabs__item) {
  color: #666;
  border-radius: 80px;
  height: 32px;
  width: 100px;
  line-height: 32px;
  display: inline-block;
  padding: 0;
  margin-left: 15px;
  cursor: pointer;
}

:deep(.tel .elTab .el-tabs__nav-scroll) {
  background: #f1f8ff;
  border-radius: 40px;
}

:deep(.el-tabs__item:hover) {
  background: #186fff;
  color: #fff;
}

.row {
  margin-right: 0px;
  margin-left: 0px;
}

@media (min-width: 1200px) {
  .join_sul {
    width: 1138px;
  }
}

.joinyemian .banner_po {
  position: relative;
}

.joinyemian .banner_po .banner_ab {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #333;
}

.joinyemian .banner_po .banner_ab .app_title {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 15px;
}

.joinyemian .banner_po .banner_ab .app_desc1 {
  font-size: 18px;
  margin-bottom: 5px;
  color: #666;
}

.joinyemian .banner_po .banner_ab .reservation {
  width: 170px;
  height: 44px;
  margin: 0 auto;
  background: #186FFF;
  color: #fff;
  font-size: 18px;
  margin-top: 10px;
  border-radius: 25px;
  cursor: pointer;
  border: none;
}

/* 
    @media (min-width: 992px) {
        .join_sul {
            width: 970px;
        }
    }

    @media (min-width: 768px) {
        .join_sul {
            width: 750px;
        }
    } */

.join_sul {
  background-color: #fff;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-shadow: 0px 5px 12px 0px #ced3da;
  border-radius: 6px;
  height: 120px;
}

.join_sul .isCus {
  background: #186fff;
  border-radius: 80px;
  color: #fff;
  height: 48px;
  width: 148px;
  line-height: 46px;
}

.joinyemian .join-banner ul li {
  border-radius: 80px;
  height: 48px;
  width: 148px;
  line-height: 46px;
  margin-top: 36px;
}

.joinyemian .join-banner ul li a {
  background: inherit;
  color: #666;
  padding: 0;
  height: 48px;
}

.joinyemian .join-banner ul li a:hover {
  background: #186fff;
  border-radius: 80px;
  color: white;
  height: 48px;
  width: 148px;
  line-height: 46px;
}

.joinyemian .container .app_flex {
  display: flex;
  justify-content: space-between;
  margin-top: 160px;
}

.joinyemian .container .app_flex .app_width_l {
  width: 25%;
}

.joinyemian .container .app_flex .app_width_l .app_width_title {
  font-size: 16px;
  color: #333;
  border: 1px solid #dfe2eb;
  padding: 15px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.joinyemian .container .app_flex .app_width_l .app_width_title a {
  color: #333;
}

.joinyemian .container .app_flex .app_width_l .app_width_title .app_width_title_text {
  width: 70%;
}

.joinyemian .container .app_flex .app_width_r {
  width: 65%;
}

.joinyemian .container .app_flex .app_width_r .app_width_r_title_name {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.joinyemian .container .app_flex .app_width_r .app_width_r_title_desc {
  font-size: 16px;
  color: #666;
  line-height: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d9d9db;
}

.joinyemian .container .app_flex .app_width_r .app_width_r_con {
  margin-top: 70px;
}

.joinyemian .container .app_flex .app_width_r .app_width_r_con .app_width_r_con_1 {
  margin-bottom: 28px;
  padding-bottom: 28px;
  border-bottom: 1px solid #d9d9db;
}

.joinyemian .container .app_flex .app_width_r .app_width_r_con .no-border {
  border-bottom: none;
}

.joinyemian .container .app_flex .app_width_r .app_width_r_con .app_width_r_con_1 .app_width_r_con_name {
  font-size: 18px;
  color: #5577aa;
  font-weight: 500;
  margin-bottom: 10px;
}

.joinyemian .container .app_flex .app_width_r .app_width_r_con .app_width_r_con_1 .app_width_r_con_desc_top {
  margin-top: 26px;
}

.joinyemian .container .app_flex .app_width_r .app_width_r_con .app_width_r_con_1 .app_width_r_con_desc {
  font-size: 16px;
  color: #666;
  line-height: 34px;
  line-height: 30px;
}

.joinyemian .container .app_flex .app_width_r .app_width_r_con .app_width_r_con_1 .app_width_r_con_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
}

.joinyemian .container .app_flex .app_width_r .app_width_r_con .app_width_r_con_1 .app_width_r_con_flex_width1 {
  width: 45%;
}

.joinyemian .container .app_flex .app_width_r .app_width_r_con .app_width_r_con_1 .app_width_r_con_flex_width2 {
  width: 54%;
}

.joinyemian .container .app_flex .app_width_r .app_width_r_con .app_width_r_con_1 .app_width_r_con_flex .app_width_r_con_flex_bottom {
  margin-bottom: 10px;
  margin-top: 20px;
}

.joinyemian .container .app_flex .app_width_r .app_width_r_con .app_width_r_con_1 .app_width_r_con_flex .app_width_r_con_flex_bottom span {
  color: #186fff;
}

.joinyemian .container .app_flex .app_width_r .app_width_r_con .app_width_r_con_1 .app_width_r_con_desc span {
  font-size: 16px;
  color: #333;
}

.joinyemian .container .app_flex .app_width_r .app_desc {
  font-size: 16px;
  color: #666;
  line-height: 30px;
  position: relative;
}

.joinyemian .container .app_flex .app_width_r .app_desc .app_desc_ab {
  width: 100%;
}

.joinyemian .container .app_flex .app_width_l .cus_po {
  position: relative;
}

.joinyemian .container .app_flex .app_width_l .cus_po .cus_ab {
  position: absolute;
  top: 40%;
  left: 21%;
  width: 180px;
  height: 60px;
  background: #186fff;
  z-index: 22;
  color: #195dce;
  font-size: 24px;
  transition: all 0.3s;
  text-align: center;
  line-height: 58px;
  opacity: 0.75;
  background: rgba(255, 255, 255, 0.75);
}

.joinyemian .container .app_flex .app_width_l .app_width_l_pro {
  margin-top: 60px;
}

.joinyemian .container .app_flex .app_width_l .app_width_l_pro .app_width_l_title {
  font-size: 25px;
  color: #000;
  margin-bottom: 9px;
}

.joinyemian .container .app_flex .app_width_l .app_width_l_pro .app_width_l_desc {
  background: #e2ecff;
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
  padding: 4px 0 4px 6px;
}

.app_width_l_free {
  margin-top: 40px;
  background: #186fff;
  color: white;
  font-size: 16px;
  height: 48px;
  line-height: 46px;
  text-align: center;
  width: 100%;
  border: none;
}
</style>
