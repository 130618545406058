<template>
  <el-dialog title="感谢您关注慧眼识才" width="32%" height="610px" style="min-width: 650px;" :visible.sync="visibleNew"
    @close="closeClick" append-to-body :close-on-click-modal="false">
    <div id="dialoag-form" class="center-dialog hideform">
      <div class="form-wrap">
        <div class="form-padding">
          <div class="form-item-title">版本选择</div>
          <!-- <div class="swiper-version" v-if="false">
            <div class="swiper" id="swiperRef">
              <div class="version-item">
                <label @click="getVersion(1)">
                  <input type="radio" name="version" id="version" :value="version" class="hide required" />
                  <div class="version-top">
                    <div class="left">
                      <img class="version-img" src="@/assets/purchase/start-type.png" alt="" />
                      <div>启航版</div>
                    </div>
                    <div class="right" style="color: #25ADFF;">
                      <span style="color: #25ADFF;">￥</span>
                      <b style="color: #25ADFF;">29,800</b>
                      <span style="color: #25ADFF;">/年</span>
                    </div>
                  </div>
                  <div class="version-middle">
                    <div>·打通招聘网站，多渠道简历聚合</div>
                    <div>·员工全生命周期管理</div>
                    <div>·人员组织管理，提高效能</div>
                  </div>
                  <div class="version-bottom">100≥企业员工规模≥10</div>
                </label>
              </div>
              <div class="version-item">
                <label @click="getVersion(2)">
                  <input type="radio" name="version" id="version" :value="version" checked class="hide required" />
                  <div class="version-top">
                    <div class="left">
                      <img class="version-img" src="@/assets/purchase/lead-type.png" alt="" />
                      <div>领航版</div>
                    </div>
                    <div class="right">
                      <span style="color: #5B65FA;">￥</span>
                      <b style="color: #5B65FA;">58,000</b>
                      <span style="color: #5B65FA;">/年</span>
                    </div>
                  </div>
                  <div class="version-middle">
                    <div>·<span style="color: #5B65FA;">领航版</span>包含<span style="color: #25ADFF;">启航版</span>全部功能</div>
                    <div>·人才盘点，提升团队效能</div>
                    <div>·可持续发展的合规风险监测服务</div>
                  </div>
                  <div class="version-bottom">100≥企业员工规模≥10</div>
                </label>
              </div>
              <div class="version-item">
                <label @click="getVersion(3)">
                  <input type="radio" name="version" id="version" :value="version" class="hide required" />
                  <div class="version-top">
                    <div class="left">
                      <img class="version-img" src="@/assets/purchase/last-type.png" alt="" />
                      <div>旗舰版</div>
                    </div>
                    <div class="right">
                      <span style="color: #FFA93A;">￥</span>
                      <b style="color: #FFA93A;">168,000</b>
                      <span style="color: #FFA93A;">/年</span>
                    </div>
                  </div>
                  <div class="version-middle">
                    <div>·<span style="color:  #FFA93A;">旗舰版</span>包含<span style="color: #5B65FA;">领航版</span>全部功能</div>
                    <div>·多场景生成式AI模型调用服务</div>
                    <div>·企业战略管理：商业画布、经营分析...</div>
                  </div>
                  <div class="version-bottom">100≥企业员工规模≥10</div>
                </label>
              </div>
            </div>
            <div class="swiper-dot">
              <div class="dot-item" v-for="idx in 2" :key="idx" :class="{ 'active': activeIndex == idx }"
                @click="handleScroll(idx)">
              </div>
            </div>
          </div> -->

          <div class="swiper-dialog">
            <div class="swiper-wrapper">
              <div class="swiper-slide" @click.prevent="getVersion(98)" :data-item="98">
                <div class="version-item" :class="{ 'version-active': isCheck == 98 }">
                  <div class="version-top">
                    <div class="left">
                      <img class="version-img" src="@/assets/purchase/newVersion/ver98.png" alt="" />
                      <div>智能管理版</div>
                    </div>
                    <div class="right">
                      <span style="color: #004BA8;">￥</span>
                      <b style="color: #004BA8;">3,980</b>
                      <span style="color: #004BA8;">/年</span>
                    </div>
                  </div>
                  <div class="version-middle">
                    <div>·<span style="color: #004BA8;">智能管理版</span>为特别版本</div>
                    <div>·人员组织管理，提高效能</div>
                    <div>·可持续发展的合规风险监测服务</div>
                  </div>
                  <div class="version-bottom">企业员工规模≤30（支持扩展）</div>
                </div>
              </div>
              <div class="swiper-slide" @click.stop="getVersion(97)">
                <div class="version-item" :class="{ 'version-active': isCheck == 97 }">
                  <div class="version-top">
                    <div class="left">
                      <img class="version-img" src="@/assets/purchase/newVersion/ver97.png" alt="" />
                      <div>智慧人事版</div>
                    </div>
                    <div class="right">
                      <span style="color: #82BB85;">￥</span>
                      <b style="color: #82BB85;">3,980</b>
                      <span style="color: #82BB85;">/年</span>
                    </div>
                  </div>
                  <div class="version-middle">
                    <div>·<span style="color: #82BB85;">智慧人事版</span>为特别版本</div>
                    <div>·员工全生命周期管理</div>
                    <div>·人才盘点，提升团队效能</div>
                  </div>
                  <div class="version-bottom">企业员工规模≤50（支持扩展）</div>
                </div>
              </div>
              <div class="swiper-slide" @click.stop="getVersion(89)">
                <div class="version-item" :class="{ 'version-active': isCheck == 89 }">
                  <div class="version-top">
                    <div class="left">
                      <img class="version-img" src="@/assets/purchase/start-type.png" alt="" />
                      <div>启航版</div>
                    </div>
                    <div class="right" style="color: #25ADFF;">
                      <span style="color: #25ADFF;">￥</span>
                      <b style="color: #25ADFF;">29,800</b>
                      <span style="color: #25ADFF;">/年</span>
                    </div>
                  </div>
                  <div class="version-middle">
                    <div>·打通招聘网站，多渠道简历聚合</div>
                    <div>·员工全生命周期管理</div>
                    <div>·人员组织管理，提高效能</div>
                  </div>
                  <div class="version-bottom">100≥企业员工规模≥10</div>
                </div>
              </div>
              <div class="swiper-slide" @click="getVersion(90)">
                <div class="version-item" :class="{ 'version-active': isCheck == 90 }">
                  <div class="version-top">
                    <div class="left">
                      <img class="version-img" src="@/assets/purchase/newVersion/ver90.png" alt="" />
                      <div>领航版</div>
                    </div>
                    <div class="right">
                      <span style="color: #5B65FA;">￥</span>
                      <b style="color: #5B65FA;">58,000</b>
                      <span style="color: #5B65FA;">/年</span>
                    </div>
                  </div>
                  <div class="version-middle">
                    <div>·<span style="color: #5B65FA;">领航版</span>包含<span style="color: #25ADFF;">启航版</span>全部功能</div>
                    <div>·人才盘点，提升团队效能</div>
                    <div>·可持续发展的合规风险监测服务</div>
                  </div>
                  <div class="version-bottom">100≥企业员工规模≥10</div>
                </div>
              </div>
              <div class="swiper-slide" @click="getVersion(91)">
                <div class="version-item" :class="{ 'version-active': isCheck == 91 }">
                  <div class="version-top">
                    <div class="left">
                      <img class="version-img scale-little" src="@/assets/purchase/newVersion/ver91.png" alt="" />
                      <div>旗舰版</div>
                    </div>
                    <div class="right">
                      <span style="color: #FFA93A;">￥</span>
                      <b style="color: #FFA93A;">168,000</b>
                      <span style="color: #FFA93A;">/年</span>
                    </div>
                  </div>
                  <div class="version-middle">
                    <div>·<span style="color:  #FFA93A;">旗舰版</span>包含<span style="color: #5B65FA;">领航版</span>全部功能
                    </div>
                    <div>·多场景生成式AI模型调用服务</div>
                    <div>·企业战略管理：商业画布、经营分析...</div>
                  </div>
                  <div class="version-bottom">企业员工规模≥100名，管理员≥5名</div>
                </div>
              </div>
              <div class="swiper-slide" @click="getVersion(95)">
                <div class="version-item" :class="{ 'version-active': isCheck == 95 }">
                  <!-- <label >
                    <input type="radio" name="version" value="91" :checked="version == 95" class="hide required" />
                  </label> -->
                  <div class="version-top">
                    <div class="left">
                      <img class="version-img" src="@/assets/purchase/newVersion/ver95.png" alt="" />
                      <div>合规经营版</div>
                    </div>
                    <div class="right">
                      <span style="color: #5BC0EB;">￥</span>
                      <b style="color: #5BC0EB;">998</b>
                      <span style="color: #5BC0EB;">/年</span>
                    </div>
                  </div>
                  <div class="version-middle">
                    <div>·<span style="color: #5BC0EB;">合规经营版</span>为特别版本</div>
                    <div>·企业人力成本支出把控</div>
                    <div>·可持续发展的合规风险监测服务</div>
                  </div>
                  <div class="version-bottom">企业员工规模≤30（支持扩展）</div>
                </div>
              </div>
              <div class="swiper-slide" @click="getVersion(96)">
                <div class="version-item" :class="{ 'version-active': isCheck == 96 }">
                  <!-- <label>
                    <input type="radio" name="version" value="96" :checked="version == 96" />
                  </label> -->
                  <div class="version-top">
                    <div class="left">
                      <img class="version-img" src="@/assets/purchase/newVersion/ver96.png" alt="" />
                      <div>智慧招聘版</div>
                    </div>
                    <div class="right">
                      <span style="color: #386641;">￥</span>
                      <b style="color: #386641;">998</b>
                      <span style="color: #386641;">/年</span>
                    </div>
                  </div>
                  <div class="version-middle">
                    <div>·<span style="color: #386641;">智慧招聘版</span>为特别版本</div>
                    <div>·打通招聘网站，多渠道简历聚合</div>
                    <div>·企业招聘全流程一键式管理</div>
                  </div>
                  <div class="version-bottom">企业员工规模≤30（支持扩展）</div>
                </div>
              </div>
            </div>

            <div class="dialog-pagination"></div>

            <div class="dialog-swiper-prev" v-show="isBtn" @click="handleCk('prev')">
              <i class=" el-icon-arrow-left"></i>
            </div>
            <div class="dialog-swiper-next" v-show="isBtn" @click="handleCk('next')">
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>

        <el-form :model="form" :rules="rules" label-position="top">
          <div class="regsign-info">
            <div class="row-flex">
              <el-form-item label="版本选择" prop="version">
                <el-select v-model="form.version" placeholder="请选择版本">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="姓名" prop="name" :label-width="formLabelWidth">
                <el-input placeholder="请输入您的姓名" v-model="form.name" autocomplete="off"></el-input>
              </el-form-item>
            </div>
            <div class="row-flex">
              <el-form-item label="联系电话" prop="phone" :label-width="formLabelWidth">
                <el-input placeholder="请输入您的联系电话" v-model="form.phone" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="验证码" prop="code" :label-width="formLabelWidth">
                <el-input placeholder="请输入验证码" v-model="form.code" autocomplete="off">
                  <template v-slot:append v-if="!isCodeM">
                    <span class="getNum" @click="handleGetCode()">获取验证码</span>
                  </template>
                  <template v-slot:append v-else>
                    <span class="codeNum">{{ codeM }}秒</span>
                  </template>
                </el-input>
              </el-form-item>
            </div>
            <el-form-item label="企业名称" prop="company">
              <el-input placeholder="请输入企业的全称" v-model="form.company" autocomplete="off"></el-input>
            </el-form-item>
            <div class="row-flex">
              <el-form-item label="职位">
                <el-input placeholder="请输入您的职位" v-model="form.postType" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="工作邮箱">
                <el-input placeholder="请输入您的工作邮箱" v-model="form.email" autocomplete="off"></el-input>
              </el-form-item>
            </div>
          </div>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button class="footer-button" type="primary" @click="putForm()">提交</el-button>
          <div class="footer-yonghu">
            提交表单视为您已同意
            <span @click="goYongHu()"><a href="">《用户协议与隐私政策》</a></span>
          </div>
        </div>
        <div class="codeH" v-if="codeH">
          <slide-verify :l="42" :r="10" :w="310" :h="255" :imgs="picArray" slider-text="向右滑动" @success="onSuccess"
            @fail="onFail" @refresh="onRefresh"></slide-verify>
          <div>{{ msg }}</div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import Swiper from 'swiper'
import { postForm, websiteCode } from '@/api/gw.js'

export default {
  props: {
    visible: {
      type: Boolean, // 接收的类型为数值
      default: false, // 默认值
      require: true, // 属性必须传递
    },
  },
  data() {
    return {
      picArray: [require('@/assets/huakuai.png')],
      codeM: 60,
      isCodeM: false,
      codeH: false,
      msg: '',
      activeIndex: 1,
      isCheck: 90,
      isBtn: false,
      options: [
        { label: '启航版', value: '89' },
        { label: '领航版', value: '90' },
        { label: '旗舰版', value: '91' },
        { label: '智慧人事版', value: '97' },
        { label: '智能管理版', value: '98' },
        { label: '合规经营版', value: '95' },
        { label: '智慧招聘版', value: '96' }
      ],
      form: {
        name: '',
        phone: '',
        company: '',
        postType: '',
        email: '',
        version: '89',
        code: '',
      },
      swiper: null,
      version: 90,
      rules: {
        version: [{ required: true, message: '请选择版本' }],
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入联系电话', trigger: 'blur' }],
        company: [
          { required: true, message: '请输入企业名称', trigger: 'blur' },
        ],
      },
      formLabelWidth: '120px',
      visibleNew: this.visible,
    };
  },
  methods: {
    goYongHu() {
      window.open('https://hysc.creatoneos.com/privacy/index.html', '_blank');
    },
    getCode() {
      websiteCode({ phone: this.form.phone }).then((res) => {
        console.log(res, 'rrr')
      })
    },
    change() {
      // VOID
    },
    getCodeM() {
      if (!this.isRunning) {
        this.isRunning = true
        const timerId = setInterval(() => {
          if (this.codeM <= 1) {
            clearInterval(timerId)
            this.reset()
          } else {
            this.codeM--
          }
        }, 1000)
      }
    },
    reset() {
      this.isCodeM = false;
      this.codeM = 60;
    },
    handleGetCode() {
      if (this.form.phone) {
        this.codeH = true;
      } else {
        this.$message({
          showClose: true,
          message: '请输入手机号',
          type: 'error',
        });
      }
    },
    onSuccess() {
      this.codeH = false
      this.isCodeM = true
      this.getCodeM()
      this.getCode()
    },
    onFail() {
      this.onRefresh()
      this.msg = ''
    },
    onRefresh() {
      this.msg = ''
    },
    getVersion(val) {
      this.isCheck = val
      this.version = String(val)
      this.form.version = this.version
    },
    putForm() {
      this.form.version = String(this.version)
      postForm(this.form).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'success',
          });
        } else {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error',
          })
        }
      });
    },
    close() {
      // this.$emit('getClickCancel');
    },
    closeClick() {
      this.$emit('getClickCancel');
    },
    handleScroll(idx) {
      const carouselInner = document.querySelector('#swiperRef')
      const carouselItems = document.querySelectorAll('.version-item')
      const totalItems = carouselItems.length
      const itemWidth = carouselItems[0].offsetWidth
      function scrollCarousel(idx) {
        let index = idx - 1
        if (index < 0 || index >= totalItems) return // 防止索引越界
        let transformX = 0
        if (idx > 1) {
          transformX = -index * itemWidth - 30
        }
        carouselInner.style.transform = `translateX(${transformX}px)`
      }
      scrollCarousel(idx)
      this.activeIndex = idx
    },
    handleCk(val) {
      if (val == 'next') {
        this.swiper.slideNext()
      } else {
        this.swiper.slidePrev()
      }
    },
    getSwiper() {
      this.version = 90
      this.swiper = new Swiper(".swiper-dialog", {
        loop: true,
        slidesPerView: 'auto',//默认一个屏幕显示几张图，必须auto！
        centeredSlides: true,//每屏，居中显示
        autoplay: false,
        initialSlide: 3,
        slideToClickedSlide: true,
        spaceBetween: 15,
        observeParents: true,
        pagination: {
          el: '.dialog-pagination',
          clickable: true, // 分页器可以点击
        },
        // on: {   //事件
        //   click: (event) => {
        //     // attributes["data-item"].nodeValue
        //     console.log(event.clickedSlide)
        //   }
        // },
      })
      //鼠标移出隐藏按钮，移入显示按钮
      this.swiper.el.onmouseover = () => {
        this.isBtn = true
      }
      this.swiper.el.onmouseout = () => {
        this.isBtn = false
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.getSwiper()
    }, 50)
  },
  watch: {},
};
</script>

<style scoped>
.swiper-dialog {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

:deep(.el-input) {
  width: 100% !important;
}

.el-select {
  width: 100% !important;
}

:deep(.el-input__icon) {
  line-height: 30px !important;
}

:deep(.swiper-dialog .swiper-wrapper) {
  margin-bottom: 10px !important;
}

.dialog-swiper-prev,
.dialog-swiper-next {
  width: 35px;
  height: 35px;
  background-color: rgba(31, 45, 61, .11);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #FFFFff;
  cursor: pointer;
}

.dialog-swiper-prev:hover,
.dialog-swiper-next:hover {
  background-color: rgba(31, 45, 61, .31);
}

.dialog-swiper-prev {
  position: absolute;
  z-index: 999;
  top: 40%;
  left: 10px;
  animation-duration: .3s;
  animation-fill-mode: both;
  animation-name: fadeInLeft;
  animation-timing-function: ease-in-out;
}

.dialog-swiper-next {
  position: absolute;
  z-index: 999;
  top: 40%;
  right: 10px;
  animation-duration: .3s;
  animation-fill-mode: both;
  animation-name: fadeRight;
  animation-timing-function: ease-in-out;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeRight {
  from {
    opacity: 0;
    transform: translate3d(200%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.swiper-slide {
  font-size: 18px;
  width: 40%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.dialog-pagination {
  display: flex;
  justify-content: center;
}

:deep(.router-link-exact-active) {
  background: #fff;
  color: #2074ff;
  border-bottom: solid #2074ff 2px;
}

:deep(.center-dialog .dialog-footer) {
  padding: 25px 23px;
}

:deep(.el-carousel__container) {
  min-height: 175px;
  height: auto;
}

:deep(.el-form) {
  min-width: 500px;
}

:deep(.el-form-item__content) {
  line-height: 1.3;
}

.row-ban {
  display: flex;
  width: 50%;
}

.row-ban .el-form-item {
  width: calc(100% - 10px);
}

.row-flex {
  display: flex;
  align-items: center;
  gap: 20px;
}

.row-flex .el-form-item {
  width: calc((100% - 20px) / 2);
}

:deep(.row-flex .el-input-group__append) {
  background: #FFFFFF;
  color: #186FFF;
  border-left: 0;
}

.regsign-info {
  padding: 15px 100px 0 100px;
  background: #F3F3F5;
}

.el-input {
  width: 100% !important;
  height: 30px;
}

:deep(.el-input__inner) {
  height: 30px;
}

:deep(.el-form-item__label) {
  line-height: 1 !important;
  padding: 0 !important;
  margin: 0 0 5px 0;
  color: #666666;
}

:deep(.el-dialog__body) {
  padding: 0;
}

:deep(.el-dialog) {
  min-width: 650px;
  border-radius: 13px;
}

.getNum {
  cursor: pointer;
}

.codeNum {
  color: #333 !important;
  position: absolute;
  top: 7px;
  font-size: 13px;
  right: 20px;
}

.footer-button {
  width: 45%;
  background: #e0e5f0;
  font-size: 16px;
  text-align: center;
  color: #8994aa;
  border: initial;
}

.footer-yonghu {
  width: 52%;
  color: #979797;
  font-size: 12px;
}

.footer-yonghu a {
  color: #1c70f1;
}

.swiper-version {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-top: 10px;
}

.swiper-version .swiper {
  display: flex;
  transition: transform 0.5s ease;
}

.version-item {
  width: 100%;
  border-radius: 8px;
  border: 2px solid #f1f2f5;
  cursor: pointer;
  font-weight: bold;
  background: #FFFFFF;
}

.version-active {
  border: 2px solid #186fff;
  background: #f5faff;
}

.swiper-version .swiper .version-item {
  flex: 0 0 calc(50% - 15px);
  margin-right: 30px;
  background: #000;
}

.swiper-version .swiper-dot {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.swiper-version .swiper-dot .dot-item {
  width: 14px;
  height: 14px;
  background: #e0e5f0;
  border-radius: 50%;
  cursor: pointer;
}

.swiper-version .swiper-dot .dot-item.active {
  width: 20px;
  height: 14px;
  background: #186FFF;
  border-radius: 4px;
}
</style>