<template>
  <div>
    <div class="tel">
      <div class="banner">
        <div class="teldesc">
          <div class="teld1">应用场景</div>
          <div class="teld3 telTop">慧眼识才的产品适用于各种规模的企业，</div>
          <div class="teld3">帮助企业更加智能化地进行人才</div>
          <div class="teld3">招聘、管理、合规和发展</div>
        </div>
      </div>
      <div class="elTab">
        <el-tabs :tab-position="tabPosition" @tab-click="tabClickTel" v-model="activeId">
          <el-tab-pane v-for="(item, index) in list" :key="index">
            <div slot="label" class="nav-item">
              <div>{{ item.name }}</div>
            </div>
            <div class="page-wrap">
              <div id="zhaopin" class="page-content">
                <div class="section">
                  <div class="section-wrap container">
                    <div class="row">
                      <div class="col-xs-12 col-lg-6">
                        <div class="title">{{ item.title1 }}</div>
                        <div class="images">
                          <img :style="{ width: item.isImg1 ? '80%' : '' }" :src="item.img1" alt="" />
                        </div>
                      </div>
                      <div class="col-xs-12 col-lg-6">
                        <div class="description">
                          <div class="text">{{ item.desc1 }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="section" v-if="item.title2">
                  <div class="section-wrap container">
                    <div class="row">
                      <div class="col-xs-12 col-lg-6">
                        <div class="description">
                          <div class="title">{{ item.title2 }}</div>
                          <div class="images"><img :src="item.img2" alt="" /></div>
                          <div class="text">{{ item.desc2 }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="section" v-if="item.title3">
                  <div class="section-wrap container">
                    <div class="row">
                      <div class="col-xs-12 col-lg-6">
                        <div class="title">{{ item.title3 }}</div>
                        <div class="images">
                          <img :src="item.img3" alt="" />
                        </div>
                      </div>
                      <div class="col-xs-12 col-lg-6">
                        <div class="description">
                          <div class="text">
                            {{ item.desc3 }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="section" v-if="item.title4">
                  <div class="section-wrap container">
                    <div class="row">
                      <div class="col-xs-12 col-lg-6">
                        <div class="description">
                          <div class="title">{{ item.title4 }}</div>
                          <div class="images">
                            <img :style="{ width: item.img42 ? '50%' : '' }" :src="item.img4" alt="" />
                            <img v-if="item.img42" style="width: 50%" :src="item.img42" alt="" />
                          </div>
                          <div class="text">
                            {{ item.desc4 }}
                          </div>
                        </div>
                      </div>
                      <div class="col-xs-12 col-lg-6"></div>
                    </div>
                  </div>
                </div>
                <div class="section">
                  <div class="section-wrap container">
                    <div class="value-title">{{ item.title5 }}</div>
                    <div class="row">
                      <div class="value-content">
                        <div class="col-xs-12 noDis col-lg-3" v-for="(keys, indexk) in item.desc5" :key="indexk">
                          <div class="value-item">
                            <div class="value-item-img" :style="{ background: keys.color }">
                              <!-- :style="{ height: keys.isImg1 ? '35%' : '' }" -->
                              <img :src="keys.img1" alt="" />
                            </div>
                            <div class="value-item-title">{{ keys.title1 }}</div>
                            <div class="value-item-sub-title">
                              {{ keys.desc1 }}<span>{{ keys.descC }}</span>{{ keys.desc2 }}
                            </div>
                            <div class="value-item-text" v-for="(des, indexD) in keys.descS" :key="indexD">
                              · {{ des.item1 }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <div class="pc">
      <div class="gjianjie">
        <div class="gjinajie-banner banner_img banner_po">
          <img src="@/assets/application/application-banner.png" alt="" />
          <div class="banner_ab">
            <div class="app_title">应用场景</div>
            <div class="app_desc1">慧眼识才的产品适用于各种规模的企业，</div>
            <div class="app_desc1">
              帮助企业更加智能化地进行人才招聘、管理、合规和发展
            </div>
            <button class="reservation" @click="isDialog('scenariosFree', 'pc')" id="show2">申请试用</button>
          </div>
        </div>

        <div class="page-container">
          <div class="nav-bar container">
            <el-tabs :tab-position="tabPosition" @tab-click="tabClick" v-model="activeId">
              <el-tab-pane v-for="(item, index) in list" :key="index">
                <div slot="label" class="nav-item">
                  <img :src="activeId == index ? item.icon : item.icon2"
                    :data-original="activeId == index ? item.icon2 : item.icon" />
                  <div>{{ item.name }}</div>
                </div>
                <div class="page-wrap">
                  <div id="zhaopin" class="page-content">
                    <div class="section">
                      <div class="section-wrap container">
                        <div class="row">
                          <div class="col-xs-12 col-lg-6">
                            <div class="images">
                              <img :style="{ width: item.isImg1 ? '80%' : '' }" :src="item.img1" alt="" />
                            </div>
                          </div>
                          <div class="col-xs-12 col-lg-6">
                            <div class="description">
                              <div class="title">{{ item.title1 }}</div>
                              <div class="text">{{ item.desc1 }}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="section" v-if="item.title2">
                      <div class="section-wrap container">
                        <div class="row">
                          <div class="col-xs-12 col-lg-6">
                            <div class="description">
                              <div class="title">{{ item.title2 }}</div>
                              <div class="text">
                                {{ item.desc2 }}
                              </div>
                            </div>
                          </div>
                          <div class="col-xs-12 col-lg-6">
                            <div class="images">
                              <img :src="item.img2" alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="section" v-if="item.title3">
                      <div class="section-wrap container">
                        <div class="row">
                          <div class="col-xs-12 col-lg-6">
                            <div class="images">
                              <img :src="item.img3" alt="" />
                            </div>
                          </div>
                          <div class="col-xs-12 col-lg-6">
                            <div class="description">
                              <div class="title">{{ item.title3 }}</div>
                              <div class="text">
                                {{ item.desc3 }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="section" v-if="item.title4">
                      <div class="section-wrap container">
                        <div class="row">
                          <div class="col-xs-12 col-lg-6">
                            <div class="description">
                              <div class="title">{{ item.title4 }}</div>
                              <div class="text">
                                {{ item.desc4 }}
                              </div>
                            </div>
                          </div>
                          <div class="col-xs-12 col-lg-6">
                            <div class="images">
                              <img :style="{ width: item.img42 ? '50%' : '' }" :src="item.img4" alt="" />
                              <img v-if="item.img42" style="width: 50%" :src="item.img42" alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="section">
                      <div class="section-wrap container">
                        <div class="value-title">{{ item.title5 }}</div>
                        <div class="row">
                          <div class="value-content">
                            <div class="col-xs-12 col-lg-3" v-for="(keys, indexk) in item.desc5" :key="indexk">
                              <div class="value-item">
                                <div class="value-item-img" :style="{ background: keys.color }">
                                  <!-- :style="{ height: keys.isImg1 ? '35%' : '' }" -->
                                  <img :src="keys.img1" alt="" />
                                </div>
                                <div class="value-item-title">{{ keys.title1 }}</div>
                                <div class="value-item-sub-title">
                                  {{ keys.desc1 }}<span>{{ keys.descC }}</span>{{ keys.desc2 }}
                                </div>
                                <div class="value-item-text" v-for="(des, indexD) in keys.descS" :key="indexD">
                                  · {{ des.item1 }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </div>

    <hysc-dia v-if="visibleD" :visible.sync="visibleD" @getClickCancel="getClickClose"></hysc-dia>
  </div>
</template>

<script>
import HyscDia from '../Dialog/dialog.vue'
import { website } from '@/api/gw.js'

export default {
  data() {
    return {
      visibleD: false,
      activeName: 'second',
      tabPosition: 'top',
      activeId: 0,
      list: [
        {
          name: '风险管理',
          val: 'riskingPc',
          valTel: 'riskingTel',
          icon: require('@/assets/application/nav-icon/nav-icon-5-a.png'),
          icon2: require('@/assets/application/nav-icon/nav-icon-5.png'),
          title1: '全面风险合规检测',
          desc1:
            '风险管理系统通过多维度的风险筛查、合规提示、评估机制等功能，全面保障企业用工、发展等各个环节的法规合规性，为企业建立高效的风险管理机制提供有力支持。',
          img1: require('@/assets/application/fengxian-img-1.png'),
          isImg1: true,
          title2: '提供风险合规提示',
          desc2:
            '风险合规提示功能通过随时走查，实时告警风险等级，为企业提供全方位的合规风险提示与专业建议，帮助及时发现并应对潜在的用工风险，确保组织用工活动合法合规。',
          img2: require('@/assets/application/fengxian-img-2.png'),
          title5: '风险管理服务的价值',
          desc5: [
            {
              isImg1: true,
              img1: require('@/assets/application/value-img15.png'),
              title1: '风险可视',
              desc1: '提供风险数据',
              descC: '可视化',
              desc2: '解决方案',
              color: '#1b78ff',
              descS: [
                { item1: '多维风险自动筛查' },
                { item1: '整合企业146个风险点' },
              ],
            },
            {
              img1: require('@/assets/application/value-img16.png'),
              title1: '合规专业',
              desc1: '提供风险点',
              descC: '可执行',
              color: '#FFA514',
              desc2: '解决方案',
              descS: [
                {
                  item1: '行业法务专业支持',
                },
                {
                  item1: '快速执行避免合规风险',
                },
              ],
            },
            {
              img1: require('@/assets/application/value-img3.png'),
              title1: '提示及时',
              desc1: '提供风险提示',
              descC: '及时性',
              color: '#1BA8FF',
              desc2: '解决方案',
              descS: [
                {
                  item1: '各流程风险点提示',
                },
                {
                  item1: '提供风险解决方案',
                },
              ],
            },
            {
              img1: require('@/assets/application/value-img17.png'),
              title1: '风险把控',
              desc1: '提供风险管理',
              descC: '安全性',
              color: '#7ABD54',
              desc2: '解决方案',
              descS: [
                { item1: '团队写作优化和自查' },
                { item1: '降低用工和效能风险' },
              ],
            },
          ],
        },
        {
          name: '战略管理',
          val: '',
          valTel: '',
          icon: require('@/assets/application/nav-icon/nav-icon-7-a.png'),
          icon2: require('@/assets/application/nav-icon/nav-icon-7.png'),
          title1: '商业画布',
          desc1: '企业通过商业画布可以清晰定义和理解其商业模式，发现新机会和优化现有模式，提升团队对齐和沟通效率，支持战略决策，快速适应市场变化，增强透明度和运营效率，从而在竞争激烈的环境中保持敏捷和创新。',
          img1: require('@/assets/application/zhanlue-img-1.png'),
          isImg1: true,
          title2: '发展大事记',
          desc2: '企业大事记作为记录企业关键历史时刻的工具，不仅有助于塑造和强化企业形象，促进内部沟通与团队凝聚力，还能作为对外宣传和教育新员工的资料，同时为企业决策提供历史参考，增强员工的归属感和自豪感，是企业文化传承和风险管理的重要载体。',
          img2: require('@/assets/application/zhanlue-img-2.png'),
          title3: '经营分析',
          desc3: '了解公司业务运营的各项数据和分析报告，结合公司的经营状况和各部门的绩效，，识别关键问题和潜在的人才缺口。这种综合分析为精准招聘和针对性培训提供了依据，帮助企业构建与业务需求相匹配的人才队伍。同时，这些数据还支持人力资源部门优化绩效评估体系，制定合理的改进措施，提高员工的工作动力和整体业绩，从而推动企业在市场中的竞争力和持续发展。',
          img3: require('@/assets/application/zhanlue-img-3.png'),
          title4: '解读报告',
          desc4: '依据企业商业画布、发展大事记、经营分析生成的AI解读报告，为企业提供了全面的自我评估和市场定位分析，帮助企业在战略规划、资源配置、风险管理、绩效评估、文化建设、创新驱动和决策支持等方面做出更明智的选择，为持续改进和可持续发展奠定了坚实的基础。',
          img4: require('@/assets/application/zhanlue-img-4.png'),
          title5: '战略管理服务的价值',
          desc5: [
            {
              isImg1: true,
              img1: require('@/assets/application/value-img9.png'),
              title1: '结构化分析',
              desc1: '提供商业画布',
              descC: '结构化',
              desc2: '解决方案',
              color: '#1b78ff',
              descS: [{ item1: '结构化设计企业商业模式' }, { item1: '商业画布解析' }],
            },
            {
              img1: require('@/assets/application/value-img21.png'),
              title1: '企业文化建设',
              desc1: '提供发展大事记',
              descC: '可视化',
              color: '#FFA514',
              desc2: '解决方案',
              descS: [{ item1: '发展大事记汇总' }, { item1: '事件解读分析' }],
            },
            {
              img1: require('@/assets/application/value-img5.png'),
              title1: '识别和管理风险',
              desc1: '提供经营分析',
              descC: '准确性',
              color: '#1BA8FF',
              desc2: '解决方案',
              descS: [{ item1: '全图形数字化经营数据', }, { item1: '经营分析解读' },
              ],
            },
            {
              img1: require('@/assets/application/value-img22.png'),
              title1: '数据驱动决策',
              desc1: '提供企业运营',
              descC: '数智化',
              color: '#7ABD54',
              desc2: '解决方案',
              descS: [{ item1: '商业画布解析' }, { item1: '经营分析解读' }],
            },
          ],
        },
        {
          name: '智能招聘',
          val: 'smarterPc',
          valTel: 'smarterTel',
          icon: require('@/assets/application/nav-icon/nav-icon-1-a.png'),
          icon2: require('@/assets/application/nav-icon/nav-icon-1.png'),
          title1: 'AI生成职位描述',
          desc1: '职位描述的精准程度直接影响招聘质量。我们的系统可以根据你的职位分类、年限要求等信息，为你提供一套定制的专业职位描述内容。如果你有补充或修改，也可以直接编辑。',
          img1: require('@/assets/application/zhaopin-img-1.png'),
          title2: '招聘一键管理',
          desc2: '一键管理可以方便地管理招聘流程中的各种操作，包括但不限于一键发布职位、一键收录简历、一键同步职位和一键入职等。可以大大提高招聘流程的效率和速度，减少重复的操作和繁琐的工作，从而更加专注于核心的招聘任务。',
          img2: require('@/assets/application/zhaopin-img-2.png'),
          title3: '智能分析简历',
          desc3: '我们把最核心的、HR最想看的部分从复杂的简历中提炼出来，展示候选人的能力特质等分析结果。这样，你就可以更快速地筛选出最合适的候选人。如果你想查看原始简历，也可以在下方的查看原始简历中查看。',
          img3: require('@/assets/application/zhaopin-img-3.png'),
          title4: '招聘数据支持',
          desc4: '招聘数据支持包括但不限，智慧话术：能精准挖掘候选人的上闪光点和薄弱点。到岗几率：多维度数据分析，科学判断到岗几率。',
          img4: require('@/assets/application/zhaopin-img-4.png'),
          img42: require('@/assets/application/zhaopin-img-5.png'),
          title5: '智能招聘服务的价值',
          desc5: [{
            img1: require('@/assets/application/value-img7.png'),
            title1: '简历要多',
            desc1: '招聘',
            descC: '多渠道',
            desc2: '解决方案',
            color: '#1b78ff',
            descS: [
              { item1: '多渠道一键收录简历' },
              { item1: '校招线下扫码收简历' },
            ],
          },
          {
            img1: require('@/assets/application/value-img2.png'),
            title1: '流程要快',
            desc1: '招聘流程',
            descC: '执行力',
            color: '#FFA514',
            desc2: '解决方案',
            descS: [
              { item1: '招聘一键管理提高效率', },
              { item1: '面试管理流程清晰可见', },
            ],
          },
          {
            img1: require('@/assets/application/value-img5.png'),
            title1: '招人要准',
            desc1: '招聘数据',
            descC: '准确性',
            color: '#1BA8FF',
            desc2: '解决方案',
            descS: [
              { item1: '多维度简历分析' },
              { item1: '能力特质测评帮助参考' },
              { item1: '综合匹配度简历筛选' },
            ],
          },
          {
            img1: require('@/assets/application/value-img6.png'),
            title1: '用钱要省',
            desc1: '招聘',
            descC: '经济性',
            color: '#7ABD54',
            desc2: '解决方案',
            descS: [
              { item1: '帮助节省招聘成本' },
              { item1: '帮助节省人工成本' },
            ],
          },
          ],
        },
        {
          name: '绩效管理',
          val: 'performancePc',
          valTel: 'performanceTel',
          icon: require('@/assets/application/nav-icon/nav-icon-3-a.png'),
          icon2: require('@/assets/application/nav-icon/nav-icon-3.png'),
          title1: '360全面环评',
          desc1:
            '360全面环评功能拥有自评、同时评、领导评、指定人评等多维度评价方式，结合AI智能模型和多场景绩效方案，全面提升绩效评估的精确性和实用性。',
          img1: require('@/assets/application/jixiao-img-1.png'),
          title2: '绩效流程追踪',
          desc2:
            '绩效流程追踪功能包括公示期、执行期、申诉期、考核确认等多个阶段，提供绩效全程追踪和短消息流程提醒，同时为绩效流程执行力提供解决方案，确保流程的高效执行和全面监控。',
          img2: require('@/assets/application/jixiao-img-2.png'),
          title3: '员工反馈通道',
          desc3:
            '员工反馈通道功能涵盖了高效的移动端协同、便捷的申诉流程，提供员工与管理端之间的及时沟通。',
          img3: require('@/assets/application/jixiao-img-3.png'),
          title4: '全面绩效报告',
          desc4:
            '全面绩效报告功能提供全面的数据展示，通过签名机制避免后续争执，确保绩效报告的可靠性，为企业提供可信赖的绩效评估解决方案。',
          img4: require('@/assets/application/jixiao-img-4.png'),
          title5: '绩效考核服务的价值',
          desc5: [
            {
              img1: require('@/assets/application/value-img8.png'),
              title1: '考核全面',
              desc1: '提供绩效考核',
              descC: '科学性',
              desc2: '解决方案',
              color: '#1b78ff',
              descS: [{ item1: '360全面环评' }, { item1: '个性化考评设置' }],
            },
            {
              img1: require('@/assets/application/value-img9.png'),
              title1: '流程清晰',
              desc1: '提供绩效流程',
              descC: '执行力',
              color: '#FFA514',
              desc2: '解决方案',
              descS: [
                {
                  item1: '绩效全程追踪',
                },
                {
                  item1: '短消息流程提醒',
                },
              ],
            },
            {
              img1: require('@/assets/application/value-img10.png'),
              title1: '反馈要有',
              desc1: '提供绩效流程',
              descC: '执行力',
              color: '#1BA8FF',
              desc2: '解决方案',
              descS: [
                {
                  item1: '移动端员工协同',
                },
                {
                  item1: '申诉流程便于反馈',
                },
                {
                  item1: '管理端及时回应员工',
                },
              ],
            },
            {
              img1: require('@/assets/application/value-img11.png'),
              title1: '数据全面',
              desc1: '提供绩效考核',
              descC: '可靠性',
              color: '#7ABD54',
              desc2: '解决方案',
              descS: [{ item1: '全面绩效报告' }, { item1: '签名机制避免争执' }],
            },
          ],
        },
        {
          name: '人才盘点',
          val: 'talentsPc',
          valTel: 'talentsTel',
          icon: require('@/assets/application/nav-icon/nav-icon-4-a.png'),
          icon2: require('@/assets/application/nav-icon/nav-icon-4.png'),
          title1: '人才盘点',
          desc1:
            '人才盘点系统通过AI生成式模型、自定义设计方案、数字化看板分析等功能，为企业提供全面、智能、灵活的人才盘点服务。',
          img1: require('@/assets/application/pandian-img-1.png'),
          title2: '人才对比',
          desc2:
            '人才对比功能支持双人或多人模式的人才价值比对，为企业提供灵活的竞聘上岗和同岗选拔解决方案，帮助精准评估员工潜力与价值。',
          img2: require('@/assets/application/pandian-img-2.png'),
          title3: '人才地图',
          desc3:
            '用一张图描绘整个企业的人才价值情况，更方便地找出价值最高且进步明显的人才类别。',
          img3: require('@/assets/application/pandian-img-3.png'),
          title4: '人才报告',
          desc4:
            '我们为企业员工提供一份关注人员价值情况的报告。我们通过各方评价以及自身的变化综合评定，更加精准地展示员工在企业中的价值及变化。',
          img4: require('@/assets/application/pandian-img-4.png'),
          title5: '人才盘点服务的价值',
          desc5: [
            {
              img1: require('@/assets/application/value-img12.png'),
              title1: '盘点高效',
              desc1: '提供人才盘点',
              descC: '灵活性',
              desc2: '解决方案',
              color: '#1b78ff',
              descS: [{ item1: '多维数据盘点' }, { item1: '个性化盘点设置' }],
            },
            {
              img1: require('@/assets/application/value-img13.png'),
              title1: '优化依据',
              desc1: '提供人才对比',
              descC: '科学性',
              color: '#FFA514',
              desc2: '解决方案',
              descS: [
                {
                  item1: '全面数据人才对比',
                },
                {
                  item1: '手动数据标识',
                },
              ],
            },
            {
              img1: require('@/assets/application/value-img10.png'),
              title1: '阶段反馈',
              desc1: '提供人才地图',
              descC: '及时性',
              color: '#1BA8FF',
              desc2: '解决方案',
              descS: [
                {
                  item1: '人才地图一览图',
                },
                {
                  item1: '进步评级数据支持',
                },
                {
                  item1: '员工TOP榜',
                },
              ],
            },
            {
              img1: require('@/assets/application/value-img14.png'),
              title1: '报告分析',
              desc1: '提供人才报告',
              descC: '数据化',
              color: '#7ABD54',
              desc2: '解决方案',
              descS: [
                { item1: '人才深度分析报告' },
                { item1: '深度数据解析概括' },
              ],
            },
          ],
        },
        {
          name: '组织管理',
          val: 'managementPc',
          valTel: 'managementTel',
          icon: require('@/assets/application/nav-icon/nav-icon-2-a.png'),
          icon2: require('@/assets/application/nav-icon/nav-icon-2.png'),
          title1: '组织管理',
          desc1:
            '组织管理系统通过支持组织架构的创建与维护功能，提供一览组织架构图等功能，助力企业灵活管理人力资源，实现组织结构的科学化和优化。',
          img1: require('@/assets/application/zuzhi-img-1.png'),
          title2: '员工信息管理',
          desc2:
            '花名册通过支持批量导入、二维码加入和一键生成企业员工花名册等功能，旨在为企业提供一套智能、便捷、安全的员工信息管理系统，助力企业高效管理人力资源，提升工作效率。',
          img2: require('@/assets/application/zuzhi-img-2.png'),
          title3: '人事异动追踪',
          desc3:
            '人事异动追踪通过记录员工入转调离等关键流程节点，实时跟踪员工信息变动，为企业提供了全面、智能、高效的人力资源管理工具，助力企业灵活应对组织变化。',
          img3: require('@/assets/application/zuzhi-img-3.png'),
          title4: '人事权限管理',
          desc4:
            '人事权限管理功能通过灵活配置角色和权限，确保员工访问和操作符合职能要求，提高了信息安全和管理效率。',
          img4: require('@/assets/application/zuzhi-img-4.png'),
          title5: '组织管理服务的价值',
          desc5: [
            {
              img1: require('@/assets/application/value-img1.png'),
              title1: '效率提升',
              desc1: '提供组织管理',
              descC: '数据化',
              desc2: '解决方案',
              color: '#1b78ff',
              descS: [{ item1: '数字化档案管理' }, { item1: '一览组织架构图' }],
            },
            {
              img1: require('@/assets/application/value-img2.png'),
              title1: '优化分配',
              desc1: '提供人事管理',
              descC: '灵活性',
              color: '#FFA514',
              desc2: '解决方案',
              descS: [
                {
                  item1: '职位/职级创建维护',
                },
                {
                  item1: '极速人事入转调离',
                },
              ],
            },
            {
              img1: require('@/assets/application/value-img3.png'),
              title1: '实时信息',
              desc1: '提供人事追踪',
              descC: '及时性',
              color: '#1BA8FF',
              desc2: '解决方案',
              descS: [
                {
                  item1: '人事异动追踪',
                },
                {
                  item1: '企业花名册',
                },
                {
                  item1: '员工全生命周期管理',
                },
              ],
            },
            {
              img1: require('@/assets/application/value-img4.png'),
              title1: '可控权限',
              desc1: '提供组织权限',
              descC: '安全性',
              color: '#7ABD54',
              desc2: '解决方案',
              descS: [
                { item1: '人事权限管理' },
                { item1: '身份验证和访问控制' },
              ],
            },
          ],
        },
        {
          name: '价值体系',
          val: '',
          valTel: '',
          icon: require('@/assets/application/nav-icon/nav-icon-8-a.png'),
          icon2: require('@/assets/application/nav-icon/nav-icon-8.png'),
          title1: '企业价值分',
          desc1: '慧眼识才最核心的功能体系之一，企业可借助慧眼识才企业价值体系实现企业在当前市场环境下的综合评价，以及对企业的整体状况进行诊断，包括运营效能、合规和风控、市场竞争力等。',
          img1: require('@/assets/application/jiazhi-img-1.png'),
          title2: '人才价值分',
          desc2: '慧眼识才最核心的功能体系之一，企业可借助慧眼识才人才价值体系实现人才在组织中贡献和评级量化，并对人才进行整体评价分析，了解人才各项能力素质。',
          img2: require('@/assets/application/jiazhi-img-2.png'),
          title5: '价值体系服务的价值',
          desc5: [
            {
              img1: require('@/assets/application/value-img23.png'),
              title1: '薄弱点强化',
              desc1: '基于企业价值的',
              descC: '针对性',
              desc2: '解决方案',
              color: '#1b78ff',
              descS: [{ item1: '企业价值分数解读' }, { item1: '企业价值成长曲线' }],
            },
            {
              img1: require('@/assets/application/value-img24.png'),
              title1: '竞争力提升',
              desc1: '基于企业价值分的',
              descC: '全面性',
              color: '#FFA514',
              desc2: '解决方案',
              descS: [{ item1: '挖掘企业核心竞争力' }, { item1: '利用数据驱动策略' }],
            },
            {
              img1: require('@/assets/application/value-img25.png'),
              title1: '人才潜力挖掘',
              desc1: '提供人事追踪',
              descC: '及时性',
              color: '#1BA8FF',
              desc2: '解决方案',
              descS: [{ item1: '人才潜力挖掘' }, { item1: '人才价值分数解读' }],
            },
            {
              img1: require('@/assets/application/value-img26.png'),
              title1: '人才能力素质量化',
              desc1: '基于人才价值分的',
              descC: '数智性',
              color: '#7ABD54',
              desc2: '解决方案',
              descS: [{ item1: '能力素质进行量化' }, { item1: '数据支持人才培养和发展' }],
            },
          ],
        },
        {
          name: '数字看板',
          val: 'signagePc',
          valTel: 'signageTel',
          icon: require('@/assets/application/nav-icon/nav-icon-6-a.png'),
          icon2: require('@/assets/application/nav-icon/nav-icon-6.png'),
          title1: '待办事项提醒',
          desc1: '待办事项提醒功能允许用户自由添加个性化日程事项，并提供系统实时提醒，帮助用户有效管理工作任务，确保不会遗漏重要事项，提高工作效率。',
          img1: require('@/assets/application/kanban-img-1.png'),
          title2: '人事数据分析报告',
          desc2: '人事数据分析报告功能提供灵活的时间维度，包括月度、季度和年度人事报告，帮助用户自主进行个人和团队绩效总结，同时为上级管理层提供全面的人才分析数据，助力更有针对性的决策制定。',
          img2: require('@/assets/application/kanban-img-2.png'),
          title3: '招聘渠道分析',
          desc3: '招聘渠道分析功能不仅仅提供招聘渠道的统计分析，还能深入挖掘招聘情况，为企业提供详尽的招聘数据，助力优化招聘策略和资源分配。',
          img3: require('@/assets/application/kanban-img-3.png'),
          title4: '任务进度分析',
          desc4: '任务进度分析功能不仅能够展示任务进度，还提供及时调整招聘策略的数据支持，帮助企业更灵活地应对招聘任务的变化和挑战。',
          img4: require('@/assets/application/kanban-img-4.png'),
          title5: '数字看板服务的价值',
          desc5: [{
            img1: require('@/assets/application/value-img2.png'),
            title1: '业务协同',
            desc1: '提供业务数据',
            descC: '协同性',
            desc2: '解决方案',
            color: '#1b78ff',
            descS: [
              { item1: '全业务数据展示' },
              { item1: '提供待办事项提醒' },
            ]
          },
          {
            img1: require('@/assets/application/value-img18.png'),
            title1: '向上汇报',
            desc1: '提供数据展示',
            descC: '可行性',
            color: '#FFA514',
            desc2: '解决方案',
            descS: [
              { item1: '人事数据分析报告' },
              { item1: '企业人事数据展示' },
            ]
          },
          {
            img1: require('@/assets/application/value-img19.png'),
            title1: '优化降本',
            desc1: '提供招聘渠道',
            descC: '经济性',
            color: '#1BA8FF',
            desc2: '解决方案',
            descS: [
              {
                item1: '招聘渠道分析',
              },
              {
                item1: '招聘数据分析',
              },
            ],
          },
          {
            img1: require('@/assets/application/value-img20.png'),
            title1: '决策效率',
            desc1: '提供任务进度',
            descC: '精简化',
            color: '#7ABD54',
            desc2: '解决方案',
            descS: [{ item1: '任务进度分析' }, { item1: '及时调整任务策略' }],
          },
          ],
        },
      ],
    }
  },
  components: {
    HyscDia,
  },
  methods: {
    // 点击做记录
    getClick(val, type) {
      website({ type: val, name: type }).then((res) => {
        console.log(res, 'rrr');
      });
    },

    tabClick(e) {
      const val = this.list[e.index].val
      this.getClick(val, 'pc')
    },

    tabClickTel(e) {
      const val = this.list[e.index].valTel
      this.getClick(val, 'tel')
    },

    isDialog(val, type) {
      this.getClick(val, type)
      this.visibleD = true;
    },

    getClickClose() {
      this.visibleD = false;
    },
  },
};
</script>

<!-- tel -->
<style scoped>
.pc {
  display: block !important;
}

.tel {
  display: none !important;
}

@media (max-width: 1200px) {
  :deep(.el-tabs__nav-wrap) {
    margin-bottom: 15px;
  }

  :deep(.tel .elTab) {
    margin: 0 15px;
  }

  :deep(.tel .elTab .el-tabs__nav-scroll) {
    padding: 0;
    border-radius: 40px;
    background: #f1f8ff;
    padding: 6px 0;
    box-shadow: initial;
  }

  :deep(.tel .elTab .el-tabs__nav-scroll::-webkit-scrollbar) {
    display: none
  }

  :deep(.tel .elTab .el-tabs__nav .is-active) {
    background: linear-gradient(90deg, #52aaff 0%, #186fff 98%) !important;
    color: #fff;
  }

  :deep(.tel .elTab .el-tabs__item) {
    height: 32px;
    line-height: 32px;
    width: 100px;
  }

  .tel .elTab {
    padding-top: 340px;
  }

  .tel .page-content .section {
    align-items: center;
    padding: 30px 0;
    background: #f9fbff;
    margin: 20px 0;
    box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.1476);
    border-radius: 8px;
  }

  .tel .page-content {
    margin: 0 0 50px 0;
  }

  .tel .page-content .section .section-wrap .images {
    background: #fff;
    height: 300px;
    margin-top: 15px;
  }

  .tel .page-content .section:nth-child(odd) .section-wrap .description {
    margin-left: 0px;
    letter-spacing: 0.82px;
    color: #666666;
  }

  .tel .page-content .section .section-wrap .description {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .tel .page-wrap {
    margin-top: 30px;
  }

  .tel .page-content .section:nth-child(even) .section-wrap .images {
    margin-left: 0px;
  }

  .tel .page-content .section:nth-child(even) .section-wrap .images img {
    padding: 20px;
  }

  .tel .page-content .section .section-wrap .description .title {
    color: #4d5565;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .tel .page-content .section .section-wrap .description .text {
    margin-top: 15px;
  }

  .tel .noDis {
    width: 50%;
  }

  .tel .noDis .value-item-sub-title {
    font-size: 12px !important;
  }

  .tel .title {
    color: #4d5565;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
  }

  :deep(.tel .el-tabs__content) {
    background: none;
    box-shadow: initial;
  }

  :deep(.el-tabs__item) {
    margin: 0;
  }

  .pc {
    display: none !important;
  }

  .tel {
    display: block !important;
  }

  :deep(.el-tabs__nav-scroll) {
    width: 100%;
    overflow-x: scroll;
  }

  :deep(.el-tabs__nav-wrap span) {
    display: none;
  }

  :deep(.el-tabs__nav-wrap.is-scrollable) {
    padding: 0 0;
  }

  .tel .banner {
    background: url(../../assets/telbg2.png) no-repeat;
    background-size: 100%;
    height: 400px;
    position: absolute;
    top: 0px;
    width: 100%;
  }

  .tel .teldesc {
    position: absolute;
    left: 0%;
    right: 50%;
    top: 30%;
    width: 100%;
    text-align: center;
    color: #FFFFFF;
  }

  .tel .teldesc .teld1 {
    font-size: 40px;
    font-weight: bold;
    color: #FFFFFF;
    letter-spacing: 1.82px;
  }

  .teld2 {
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 1.82px;
  }

  .telTop {
    margin-top: 20px;
  }

  .teld3 {
    font-size: 16px;
    padding: 0 30px;
  }

  .tel .zhaopinBG {
    background: linear-gradient(310deg, #186fff 12%, #32a9f7 101%);
    border-radius: 13px 13px 0px 0px;
    text-align: center;
  }

  .tel .zhaopinBG img {
    width: 90%;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  :deep(.el-tabs__content) {
    width: 100% !important;
    background: #ffffff;
    border-radius: 13px;
    box-shadow: 0px 6px 10px 0px rgba(29, 67, 102, 0.373);
  }

  .tel .profuile {
    padding-top: 320px;
  }

  .tel .zhaopinDesc {
    padding: 20px 20px 0 20px;
  }

  .tel .zhaopinDesc .zhaoPinTitle {
    font-size: 20px;
    color: #4d5565;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .tel .zhaopinDesc img {
    width: 15px;
    height: 15px;
    margin-top: 0;
    margin-right: 10px;
    margin-bottom: 0;
  }

  .tel .zhaopinDesc .zhaopinDis {
    display: flex;
    align-items: center;
    margin-bottom: 38px;
    color: #333;
    letter-spacing: 1.35px;
  }

  .tel .zhaopinDesc .infoR {
    justify-content: center;
    color: #186fff;
    font-weight: 500;
  }

  .tel .zhaopinDesc .zhaopinDis .zhaopinR {
    width: 15px;
    height: 11px;
    margin-left: 6px;
  }

  /* 解决方案 */
  :deep(.solution .el-tabs__nav-scroll) {
    padding: 0 !important;
  }

  .tel .solution {
    background-color: initial;
  }

  .tel .solutionBG {
    background-size: 100% 100%;
    height: 366px;
    color: #fff;
  }

  .tel .soluDis {
    justify-content: center;
    display: flex;
    position: relative;
  }

  .tel .soluDis .soluDL {
    margin-left: 20px;
    position: relative;
  }

  .tel .soluDis div::before {
    content: '';
    width: 4px;
    height: 4px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: 9px;
    left: -10px;
  }

  .tel .soluDes {
    margin: 0 20px;
    padding: 15px 20px 10px 20px;
  }

  .tel .soluBorder {
    border-top: 1px dotted #fff;
    border-bottom: 1px dotted #fff;
  }

  .tel .soluDes .soluDt {
    font-size: 20px;
  }

  .tel .soluDes .soluDd {
    font-size: 14px;
    margin-top: 10px;
  }

  .tel .soluF {
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1.35px;
    margin-top: 5px;
  }

  .tel .soluF img {
    width: 15px;
    height: 11px;
    margin-top: 0;
    margin-left: 10px;
  }

  .tel .monBg {
    box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.1476);
    text-align: center;
    padding-bottom: 22px;
    border-radius: 12px;
    background: #f7faff;
    margin-top: 22px;
  }

  .tel .monBg .monImg {
    width: 80%;
    margin-top: 22px;
  }

  .tel .monBg .part {
    width: 90%;
    height: 124px;
    margin: 22px auto;
    border-radius: 8px;
    border: 1px solid #d3e4ff;
    border-left: 9px solid #186fff;
    background: linear-gradient(111deg, #ffffff 0%, #eaf1ff 100%);
  }

  .tel .monBg .part .bg-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 23px;
    gap: 9px;
    text-align: left;
  }

  .tel .monBg .part .bg-wrap.bg1 {
    background: url(../../assets/home/monitor-bg1.png) no-repeat;
    background-size: 43%;
    background-position: 120% -40%;
    background-origin: content-box;
  }

  .tel .monBg .part .bg-wrap.bg2 {
    background: url(../../assets/home/monitor-bg2.png) no-repeat;
    background-size: 43%;
    background-position: 120% -40%;
    background-origin: content-box;
  }

  .tel .monBg .part .bg-wrap.bg3 {
    background: url(../../assets/home/monitor-bg3.png) no-repeat;
    background-size: 43%;
    background-position: 120% -40%;
    background-origin: content-box;
  }

  .tel .monBg .part .bg-wrap.bg4 {
    background: url(../../assets/home/monitor-bg4.png) no-repeat;
    background-size: 43%;
    background-position: 120% -40%;
    background-origin: content-box;
  }

  .tel .monBg .part .text {
    color: #333;
    font-size: 20px;
    font-weight: bold;
  }

  .tel .monBg .part .sub-text {
    color: #666;
    font-size: 15px;
  }

  .tel .change {
    background: none;
  }

  .tel .change .container .row .flex-8 .flex-width {
    width: 48%;
  }

  .tel .change .container .row .flex-8 .flex-width img {
    margin-top: 0;
  }

  /* 为什么 */
  .tel .change .container .row .flex-8 .flex-width .change_desc1 {
    font-size: 12px;
    margin-bottom: 23px;
  }

  .tel .change .container .row .flex-8 .flex-width .change_desc2 {
    font-size: 10px;
    color: #666;
    letter-spacing: 0.77px;
    line-height: 20px;
  }

  .tel .achievement .ach .flex-width {
    width: 48%;
    font-size: 12px;
    margin-top: 50px;
    color: #666;
    padding-left: 0%;
    margin-left: 0;
  }

  .tel .profuile .container .row .flex-8 .flex-width .details {
    display: none;
    -webkit-transition: All 0.4s;
  }

  .tel .profuile .container .row .flex-8 .flex-width .details .details_flex {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: white;
  }

  .tel .profuile .container .row .flex-8 .flex-width .details .details_flex img {
    width: 14px;
    height: 14px;
    margin-left: 5px;
  }

  .tel .profuile .container .row .flex-8 .flex-width:hover {
    background: linear-gradient(134deg, #00aaff -2%, #0085ff 60%);
    box-shadow: 0px 5px 12px 0px #ced3da;
    -webkit-transform: translate(0, -10px);
  }

  .tel .profuile .container .row .flex-8 .flex-width:hover .change_desc1 {
    color: white;
  }

  .tel .profuile .container .row .flex-8 .flex-width:hover .change_desc2 {
    color: white;
  }

  .tel .profuile .container .row .flex-8 .flex-width:hover .title {
    color: white;
  }

  .tel .profuile .container .row .flex-8 .flex-width:hover .desc {
    display: none;
  }

  .tel .achievement .ach .flex-width .flex-color {
    color: #186fff;
    font-weight: 500;
    font-size: 20px;
    display: flex;
  }

  .tel .noleft {
    width: 48% !important;
  }

  .tel .isleft {
    width: 48% !important;
    /* margin-left: 14%; */
  }

  .tel .isleft2 {
    width: 48% !important;
    /* margin-left: 12%; */
  }

  .tel .achievement .need {
    margin-top: -50px;
  }

  .tel .need .pcNeed {
    display: none;
  }

  .tel .need .telNeed {
    display: block;
    display: flex;
    flex-wrap: wrap;
  }

  .tel .need .telNeed img {
    width: 25%;
    box-sizing: border-box;
    border: 1px solid #d4d4d4;
    margin-top: 0;
  }
}
</style>

<style scoped>
:deep(.el-tabs__header) {
  margin: auto;
}

:deep(.el-tabs__nav-wrap) {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  overflow: initial;
}

:deep(.el-tabs__nav-scroll) {
  z-index: 22;
  position: absolute;
  top: -70px;
  /* width: 45%; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 36px 0;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
}

@media (min-width: 1200px) {
  :deep(.el-tabs__nav-scroll) {
    width: 1170px;
  }
}

:deep(.el-tabs__nav) {
  float: initial;
}

:deep(.el-tabs__active-bar) {
  display: none;
}

:deep(.el-tabs__item) {
  padding: 0 70px;
}

:deep(.el-tabs__nav-wrap::after) {
  background-color: initial;
}

:deep(.el-tab-pane .container) {
  padding-left: 0;
  padding-right: 0;
}

:deep(.el-tabs__nav .is-active) {
  color: #186fff;
  font-weight: bold;
  border-radius: 80px;
  height: 48px;
  width: 124px;
  line-height: 46px;
  padding: 0;
}

:deep(.el-tabs__item) {
  color: #666;
  border-radius: 80px;
  height: 48px;
  width: 124px;
  line-height: 46px;
  display: inline-block;
  padding: 0;
  margin-left: 15px;
  cursor: pointer;
}

.row {
  margin-right: 0px;
  margin-left: 0px;
}

.navbar-collapse {
  padding-right: 0;
  padding-left: 0;
}

.freeNav li {
  margin-right: 0 !important;
}

.gjianjie .banner_po {
  position: relative;
}

.gjianjie .banner_po .banner_ab {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #666666;
}

.gjianjie .banner_po .banner_ab .app_title {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333333;
}

.gjianjie .banner_po .banner_ab .app_desc1 {
  font-size: 18px;
  margin-bottom: 5px;
}

.gjianjie .banner_po .banner_ab .reservation {
  width: 170px;
  height: 48px;
  margin: 0 auto;
  line-height: 46px;
  background: #186FFF;
  color: #FFFFFF;
  font-size: 18px;
  border-radius: 25px;
  margin-top: 10px;
  cursor: pointer;
  border: none;
  font-weight: 500;
}

.page-container .nav-bar {
  position: relative;
}

.page-container .nav-bar .nav-bar-list {
  position: absolute;
  top: -70px;
  left: 0;
  width: 100%;
  padding: 34px 0;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
}

.page-container .nav-bar .nav-bar-list .nav-item {
  text-align: center;
  cursor: pointer;
}

.page-container .nav-bar .nav-bar-list .nav-item.active div a {
  color: #186fff;
  font-weight: bold;
}

.page-container .nav-bar .nav-bar-list .nav-item img {
  width: 36px;
  height: 36px;
}

.nav-item img {
  width: 36px;
  height: 36px;
}

.page-container .nav-bar .nav-bar-list .nav-item div a {
  font-size: 15px;
  color: #666;
  margin-top: 6px;
}

.page-wrap {
  margin-top: 60px;
}

.page-content.hide-item {
  display: none;
}

.page-content .section {
  display: flex;
  align-items: center;
  padding: 80px 0;
}

.page-content .section:nth-child(even) {
  background: #f9fbff;
}

.page-content .section:nth-child(even) .section-wrap .images {
  margin-left: 60px;
}

.page-content .section:nth-child(odd) .section-wrap .description {
  margin-left: 60px;
}

.page-content .section .section-wrap .images {
  height: 420px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-content .section .section-wrap .images img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.page-content .section .section-wrap .description {
  height: 420px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.page-content .section .section-wrap .description .title {
  color: #000;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
}

.page-content .section .section-wrap .description .text {
  font-size: 14px;
  color: #666;
  line-height: 2;
}

.page-content .section .section-wrap .value-title {
  color: #000;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
}

.page-content .section .section-wrap .value-content {
  padding: 35px 0;
}

.page-content .section .section-wrap .value-content .value-item {
  display: flex;
  margin: 30px 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.page-content .section .section-wrap .value-content .value-item .value-item-img {
  width: 85px;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: red;
  border-radius: 50%;
  margin-bottom: 40px;
}

.page-content .section .section-wrap .value-content .value-item .value-item-img img {
  width: 50%;
  height: 50%;
}

.page-content .section .section-wrap .value-content .value-item .value-item-title {
  color: #333;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 9px;
}

.page-content .section .section-wrap .value-content .value-item .value-item-sub-title {
  color: #666;
  font-size: 16px;
  padding-bottom: 12px;
  border-bottom: 1px dashed #d8d8d8;
}

.page-content .section .section-wrap .value-content .value-item .value-item-sub-title span {
  color: #ffa514;
}

.page-content .section .section-wrap .value-content .value-item .value-item-text {
  color: #666;
  font-size: 13px;
  width: 168px;
  height: 24px;
  line-height: 24px;
  text-align: left;
  margin-top: 12px;
  padding-left: 8px;
  border-radius: 999px;
  background-color: #edeef0;
}
</style>