<template>
  <div>
    <div class="tel">
      <div class="posiHeight">
        <div class="banner">
          <div class="teldesc">
            <div class="teld1">关于我们</div>
            <div class="teld3">聚焦AI技术和大数据技术的研发与应用</div>
            <div class="teld3">致力于帮助企业满足高效招到人才的需求</div>
          </div>
        </div>
        <!-- 公司简介 -->
        <div class="mTop">
          <div>
            <div class="title">公司简介</div>
            <div class="line"></div>
          </div>
          <div class="desc">
            创壹（上海）信息科技有限公司于2015年在上海成立。公司多年深耕于AI技术和大数据技术领域，致力于在企业服务行业与教育的技术研究与应用。“慧眼识才能力识别平台”便是以此基础搭建的人才能力识别与提升训练平台。通过多年自主研发的CreatoneOS引擎实现底层技术支撑，帮助企业解决招人难，识人难的关键需求。
          </div>
          <img src="@/assets/about/about_1.png" />
        </div>
        <div class="yj">
          <img src="@/assets/about/yj.png" />
          <div class="yj-dis">
            <div class="title">愿景</div>
            <div>科技创新改变工作</div>
          </div>
        </div>
        <div class="yj sm">
          <img src="@/assets/about/sm.png" />
          <div class="yj-dis">
            <div class="title">使命</div>
            <div>简单工作的缔造者</div>
          </div>
        </div>
      </div>
      <!-- 发展历程 -->
      <div class="developmentT">
        <div class="title">发展历程</div>
        <el-tabs v-model="activeName">
          <el-tab-pane label="2017" name="2017">
            <div class="deve_bg">
              <div class="deve_title_bg">
                <span class="dot"></span>
                <span>软件产品证书</span>
              </div>
              <div class="deve_title_bg">
                <span class="dot"></span>
                <span>创壹大数据分析管理软件著作权登记证书</span>
              </div>
              <div class="deve_title_bg">
                <span class="dot"></span>
                <span>创壹大数据分析管理软件著作权登记证书</span>
              </div>
            </div>
            <!-- <div class="timeline2">
              <div
                id="myCarousel"
                class="carousel slide"
                data-ride=""
                data-interval="300000"
              >
                <div class="po_re_over">
                  <div class="carousel-indicators">
                    <div class="po_re">
                      <div class="po_ab_l">2017</div>
                      <li data-target="#myCarousel" data-slide-to="0"></li>
                      <div data-slide-to="0" class="po_ab_r">软件产品证书</div>
                    </div>
                    <div class="po_re">
                      <div class="po_ab_l">2017</div>
                      <li data-target="#myCarousel" data-slide-to="1"></li>
                      <div data-slide-to="1" class="po_ab_r">
                        创壹大数据分析管理软件著作权登记证书
                      </div>
                    </div>
                    <div class="po_re">
                      <div class="po_ab_l">2017</div>
                      <li data-target="#myCarousel" data-slide-to="2"></li>
                      <div class="po_ab_r">
                        创壹大数据分析管理软件著作权登记证书
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </el-tab-pane>
          <el-tab-pane label="2018" name="2018">
            <div class="deve_bg">
              <div class="deve_title_bg">
                <span class="dot"></span>
                <span>软件企业证书 </span>
              </div>
              <div class="deve_title_bg">
                <span class="dot"></span>
                <span>专业人才技能培训中心 </span>
              </div>
              <div class="deve_title_bg">
                <span class="dot"></span>
                <span>创壹报告管理系统软件著作权登记证书 </span>
              </div>
            </div>
            <!-- <div class="timeline2">
              <div
                id="myCarousel"
                class="carousel slide"
                data-ride=""
                data-interval="300000"
              >
               
                <div class="po_re_over">
                  <div class="carousel-indicators">
                    <div class="po_re">
                      <div class="po_ab_l">2018</div>
                      <li data-target="#myCarousel" data-slide-to="3"></li>
                      <div class="po_ab_r">软件企业证书</div>
                    </div>
                    <div class="po_re">
                      <div class="po_ab_l">2018</div>
                      <li data-target="#myCarousel" data-slide-to="4"></li>
                      <div class="po_ab_r">专业人才技能培训中心</div>
                    </div>
                    <div class="po_re">
                      <div class="po_ab_l">2018</div>
                      <li data-target="#myCarousel" data-slide-to="5"></li>
                      <div class="po_ab_r">
                        创壹报告管理系统软件著作权登记证书
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </el-tab-pane>
          <el-tab-pane label="2019" name="2019">
            <div class="deve_bg">
              <div class="deve_title_bg">
                <span class="dot"></span>
                <span>高新技术企业证书 </span>
              </div>
              <div class="deve_title_bg">
                <span class="dot"></span>
                <span>电脑的图形用户界面专利证书 </span>
              </div>
            </div>
            <!-- <div class="timeline2">
              <div
                id="myCarousel"
                class="carousel slide"
                data-ride=""
                data-interval="300000"
              >
                <div class="po_re_over">
                  <div class="carousel-indicators">
                    <div class="po_re">
                      <div class="po_ab_l">2019</div>
                      <li data-target="#myCarousel" data-slide-to="6"></li>
                      <div class="po_ab_r">高新技术企业证书</div>
                    </div>
                    <div class="po_re">
                      <div class="po_ab_l">2019</div>
                      <li data-target="#myCarousel" data-slide-to="7"></li>
                      <div class="po_ab_r">电脑的图形用户界面专利证书</div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </el-tab-pane>
          <el-tab-pane label="2020" name="2020">
            <div class="deve_bg">
              <div class="deve_title_bg">
                <span class="dot"></span>
                <span>创壹学习能力人工智能著作权登记证书 </span>
              </div>
              <div class="deve_title_bg">
                <span class="dot"></span>
                <span>创壹学习能力大数据分析引擎软件著作权登记证书 </span>
              </div>
            </div>
            <!-- <div class="timeline2">
              <div
                id="myCarousel"
                class="carousel slide"
                data-ride=""
                data-interval="300000"
              >
               
                <div class="po_re_over">
                  <div class="carousel-indicators">
                    <div class="po_re">
                      <div class="po_ab_l">2020</div>
                      <li data-target="#myCarousel" data-slide-to="7"></li>
                      <div class="po_ab_r">
                        创壹学习能力人工智能著作权登记证书
                      </div>
                    </div>
                    <div class="po_re">
                      <div class="po_ab_l">2020</div>
                      <li data-target="#myCarousel" data-slide-to="7"></li>
                      <div class="po_ab_r">
                        创壹学习能力大数据分析引擎软件著作权登记证书
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </el-tab-pane>
          <el-tab-pane label="2021" name="2021">
            <div class="deve_bg">
              <div class="deve_title_bg">
                <span class="dot"></span>
                <span>新型视频会议服务器专利证书 </span>
              </div>
              <div class="deve_title_bg">
                <span class="dot"></span>
                <span>新型路由器交换机专利证书 </span>
              </div>
              <div class="deve_title_bg">
                <span class="dot"></span>
                <span>上海市信息服务业行业协会会员单位 </span>
              </div>
            </div>
            <!-- <div class="timeline2">
              <div
                id="myCarousel"
                class="carousel slide"
                data-ride=""
                data-interval="300000"
              >
                
                <div class="po_re_over">
                  <div class="carousel-indicators">
                    <div class="po_re">
                      <div class="po_ab_l">2021</div>
                      <li data-target="#myCarousel" data-slide-to="7"></li>
                      <div class="po_ab_r">新型视频会议服务器专利证书</div>
                    </div>
                    <div class="po_re">
                      <div class="po_ab_l">2021</div>
                      <li data-target="#myCarousel" data-slide-to="7"></li>
                      <div class="po_ab_r">新型路由器交换机专利证书</div>
                    </div>
                    <div class="po_re">
                      <div class="po_ab_l">2021</div>
                      <li data-target="#myCarousel" data-slide-to="7"></li>
                      <div class="po_ab_r">
                        上海市信息服务业行业协会会员单位
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </el-tab-pane>
          <el-tab-pane label="2022" name="2022">
            <div class="deve_bg">
              <div class="deve_title_bg">
                <span class="dot"></span>
                <span>创壹慧眼识才识别软件著作权登记证书 </span>
              </div>
            </div>
            <!-- <div class="timeline2">
              <div
                id="myCarousel"
                class="carousel slide"
                data-ride=""
                data-interval="300000"
              >
                <div class="po_re_over">
                  <div class="carousel-indicators">
                    <div class="po_re">
                      <div class="po_ab_l">2022</div>
                      <li data-target="#myCarousel" data-slide-to="7"></li>
                      <div class="po_ab_r">
                        创壹慧眼识才识别软件著作权登记证书
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </el-tab-pane>
          <el-tab-pane label="2023" name="2023">
            <div class="deve_bg">
              <div class="deve_title_bg">
                <span class="dot"></span>
                <span>软件产品证书 </span>
              </div>
            </div>
            <!-- <div class="timeline2">
              <div
                id="myCarousel"
                class="carousel slide"
                data-ride=""
                data-interval="300"
              >
               
                <div class="po_re_over">
                  <div class="carousel-indicators">
                    <div class="po_re">
                      <div class="po_ab_l">2023</div>
                      <li data-target="#myCarousel" data-slide-to="7"></li>
                      <div class="po_ab_r">软件产品证书</div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- 获得荣誉 -->
      <div class="honor">
        <div class="honor_title">获得荣誉</div>
        <div class="honor_desc">
          <el-tabs v-model="activeName2">
            <el-tab-pane v-for="(item, index) in honorArr" :key="index" :label="item.yearH" :name="item.keyH">
              <div class="ry_bg">
                <div class="title">{{ item.title }}</div>
                <div class="img_bg">
                  <img :src="item.imgH" />
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <!-- 联系我们 -->
      <div class="addres">
        <div class="title">联系我们</div>
        <div class="addressInfo">
          <div class="dz_dis">
            <img src="@/assets/about/tel_about_address_icon.png" alt="" />
            <div>公司地址</div>
          </div>
          <div>上海杨浦区军工路100号运河湾知识产权园18栋07室</div>
        </div>
        <div class="dz">
          <div class="addressInfo">
            <div class="dz_dis">
              <img src="@/assets/about/tel_about_email_icon.png" alt="" />
              <div>公司邮箱</div>
            </div>
            <div class="phoneMini">services@chuangyios.com</div>
          </div>
          <div class="addressInfo">
            <div class="dz_dis">
              <img src="@/assets/about/tel_about_tel_icon.png" alt="" />
              <div>在线服务电话</div>
            </div>
            <div>400-0055-965</div>
          </div>
        </div>
        <div class="mapOverflow">
          <div id="containerTel"></div>
        </div>
      </div>
    </div>
    <div class="pc">
      <div class="fbwh banner_img banner_po">
        <img src="@/assets/about/about-banner.png" alt="" />
        <div class="banner_ab">
          <div class="app_title">关于我们</div>
          <div class="app_desc1">聚焦AI技术和大数据技术的研发与应用，</div>
          <div class="app_desc1">
            帮助企业更加智能化地进行人才招聘、管理、合规和发展
          </div>
          <button class="reservation" id="show2" @click="isDialog('aboutFree', 'pc')">
            申请试用
          </button>
        </div>
      </div>
      <!-- 公司介绍 -->
      <div class="about_bgcom">
        <div class="container about">
          <div class="row">
            <div class="company-title">公司介绍</div>
            <div class="about-flex">
              <div class="col-md-6 about-flex-top">
                创壹（上海）信息科技有限公司于2015年在上海成立。公司多年深耕于AI技术和大数据技术领域，致力于在企业服务行业与教育的技术研究与应用。“慧眼识才能力识别平台”便是以此基础搭建的人才能力识别与提升训练平台。通过多年自主研发的CreatoneOS引擎实现底层技术支撑，帮助企业解决招人难，识人难的关键需求。
              </div>
              <div class="col-md-6 imgtext">
                <img src="@/assets/about/about_1.png" alt="" srcset="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 使命/愿景 -->
      <div class="container about">
        <div class="row">
          <div class="about-two">
            <div class="about_po">
              <img src="@/assets/about/about2.png" alt="" />
              <div class="about-ab">
                <div>使命</div>
                <div class="about-line"></div>
                <div>科技创新改变工作</div>
              </div>
            </div>
            <div class="about_po">
              <img src="@/assets/about/about3.png" alt="" />
              <div class="about-ab">
                <div>愿景</div>
                <div class="about-line2"></div>
                <div>简单工作的缔造者</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 企业优势 -->
      <div class="container about advantage">
        <div class="row">
          <div id="shuzi" class="company-title">企业优势</div>
          <div class="ach-flex">
            <div class="flex-width">
              <div class="flex-color">
                <div id="counter">50</div>
                0+
              </div>
              <div class="flex-text">大中型企业的选择</div>
            </div>
            <div class="flex-width noleft">
              <div class="flex-color">
                <div id="counter1">100</div>
                0000+
              </div>
              <div class="flex-text">累计服务人数</div>
            </div>
            <div class="flex-width isleft2">
              <div class="flex-color">
                <div id="counter2">10</div>
                +
              </div>
              <div class="flex-text">多年研发积累</div>
            </div>
            <div class="flex-width isleft">
              <div class="flex-color">
                <div id="counter3">100</div>
                +
              </div>
              <div class="flex-text">相关知识产权</div>
            </div>
            <div class="flex-width">
              <div class="flex-color">
                <div id="counter4">26</div>
                +
              </div>
              <div class="flex-text">关键行业场景覆盖</div>
            </div>
            <div class="flex-width noleft">
              <div class="flex-color">人工智能</div>
              <div class="flex-text">创新变革的数字化技术</div>
            </div>
            <div class="flex-width isleft2">
              <div class="flex-color">专业团队</div>
              <div class="flex-text">团队成员深耕技术研发</div>
            </div>
            <div class="flex-width isleft">
              <div class="flex-color">
                <div id="counter5">9</div>
                +
              </div>
              <div class="flex-text">多年资深服务经验</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 发展历程 -->
      <div class="development">
        <div class="container history">
          <div class="row">
            <div class="title_history">发展历程</div>
            <section class="cd-horizontal-timeline" ref="timeline">
              <div class="timeline">
                <div class="events-wrapper">
                  <div class="events">
                    <ol>
                      <li>
                        <a href="#0" data-date="01/01/2031" class="selected">2023</a>
                      </li>
                      <li><a href="#0" data-date="01/01/2029">2022</a></li>
                      <li><a href="#0" data-date="01/01/2027">2021</a></li>
                      <li><a href="#0" data-date="01/01/2025">2020</a></li>
                      <li><a href="#0" data-date="01/01/2023">2019</a></li>
                      <li><a href="#0" data-date="01/01/2021">2018</a></li>
                      <li><a href="#0" data-date="01/01/2019">2017</a></li>
                      <li><a href="#0" data-date="01/01/2017">2016</a></li>
                      <li><a href="#0" data-date="01/01/2015">2015</a></li>
                    </ol>

                    <span class="filling-line" aria-hidden="true"></span>
                  </div>
                  <!-- .events -->
                </div>
                <!-- .events-wrapper -->

                <div class="cd-timeline-navigation">
                  <div id="isHover">
                    <a id="isImg" href="#0" class="prev inactive">
                      <img src="@/assets/about/aboutlinel.png" alt="" srcset="" />
                    </a>
                  </div>
                  <div id="isHover2">
                    <a id="isImg2" href="#0" class="next">
                      <img src="@/assets/about/aboutliner.png" alt="" srcset="" />
                    </a>
                  </div>
                </div>
                <!-- .cd-timeline-navigation -->
              </div>
              <!-- .timeline -->
              <div class="events-content">
                <ol>
                  <li class="selected" data-date="01/01/2031">
                    <p class="p1">2023</p>
                    <p class="p2">发布并升级CreatoneOS v3.0系统</p>
                  </li>

                  <li data-date="01/01/2029">
                    <p class="p1">2022</p>
                    <p class="p2">发布多款多终端产品</p>
                  </li>

                  <li data-date="01/01/2027">
                    <p class="p1">2021</p>
                    <p class="p2">发布并升级CreatoneOS v2.0系统</p>
                  </li>

                  <li data-date="01/01/2025">
                    <p class="p1">2020</p>
                    <p class="p2">启动科教兴国战略计划</p>
                  </li>

                  <li data-date="01/01/2023">
                    <p class="p1">2019</p>
                    <p class="p2">获得高新技术产业证书</p>
                  </li>

                  <li data-date="01/01/2021">
                    <p class="p1">2018</p>
                    <p class="p2">发布数字教育系统</p>
                  </li>

                  <li data-date="01/01/2019">
                    <p class="p1">2017</p>
                    <p class="p2">获得种子轮投资</p>
                    <p class="p3">获得创业公益基金</p>
                  </li>

                  <li data-date="01/01/2017">
                    <p class="p1">2016</p>
                    <p class="p2">发布主动式技术服务方案</p>
                  </li>
                  <li data-date="01/01/2015">
                    <p class="p1">2015</p>
                    <p class="p2">公司正式成立</p>
                  </li>
                </ol>
              </div>
            </section>
          </div>
        </div>
      </div>
      <!-- 获得荣誉 -->
      <div class="honor honorbg">
        <div class="container history">
          <div class="row">
            <div class="title_history">获得荣誉</div>
            <div class="timeline2">
              <div id="myCarousel" class="carousel slide" data-ride="" data-interval="300000">
                <!-- 轮播（Carousel）指标 -->
                <div class="po_re_over">
                  <div class="carousel-indicators">
                    <div class="po_re active">
                      <div class="po_ab_l">2017</div>
                      <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
                      <div data-slide-to="0" class="po_ab_r">
                        软件产品证书
                      </div>
                    </div>
                    <div class="po_re">
                      <div class="po_ab_l">2017</div>
                      <li data-target="#myCarousel" data-slide-to="1"></li>
                      <div data-slide-to="1" class="po_ab_r">
                        创壹大数据分析管理软件著作权登记证书
                      </div>
                    </div>
                    <div class="po_re">
                      <div class="po_ab_l">2017</div>
                      <li data-target="#myCarousel" data-slide-to="2"></li>
                      <div class="po_ab_r">
                        创壹大数据分析管理软件著作权登记证书
                      </div>
                    </div>
                    <div class="po_re">
                      <div class="po_ab_l">2018</div>
                      <li data-target="#myCarousel" data-slide-to="3"></li>
                      <div class="po_ab_r">软件企业证书</div>
                    </div>
                    <div class="po_re">
                      <div class="po_ab_l">2018</div>
                      <li data-target="#myCarousel" data-slide-to="4"></li>
                      <div class="po_ab_r">专业人才技能培训中心</div>
                    </div>
                    <div class="po_re">
                      <div class="po_ab_l">2018</div>
                      <li data-target="#myCarousel" data-slide-to="5"></li>
                      <div class="po_ab_r">
                        创壹报告管理系统软件著作权登记证书
                      </div>
                    </div>
                    <div class="po_re">
                      <div class="po_ab_l">2019</div>
                      <li data-target="#myCarousel" data-slide-to="6"></li>
                      <div class="po_ab_r">高新技术企业证书</div>
                    </div>
                    <div class="po_re">
                      <div class="po_ab_l">2019</div>
                      <li data-target="#myCarousel" data-slide-to="7"></li>
                      <div class="po_ab_r">电脑的图形用户界面专利证书</div>
                    </div>
                    <div class="po_re">
                      <div class="po_ab_l">2020</div>
                      <li data-target="#myCarousel" data-slide-to="7"></li>
                      <div class="po_ab_r">
                        创壹学习能力人工智能著作权登记证书
                      </div>
                    </div>
                    <div class="po_re">
                      <div class="po_ab_l">2020</div>
                      <li data-target="#myCarousel" data-slide-to="7"></li>
                      <div class="po_ab_r">
                        创壹学习能力大数据分析引擎软件著作权登记证书
                      </div>
                    </div>
                    <div class="po_re">
                      <div class="po_ab_l">2021</div>
                      <li data-target="#myCarousel" data-slide-to="7"></li>
                      <div class="po_ab_r">新型视频会议服务器专利证书</div>
                    </div>
                    <div class="po_re">
                      <div class="po_ab_l">2021</div>
                      <li data-target="#myCarousel" data-slide-to="7"></li>
                      <div class="po_ab_r">新型路由器交换机专利证书</div>
                    </div>
                    <div class="po_re">
                      <div class="po_ab_l">2021</div>
                      <li data-target="#myCarousel" data-slide-to="7"></li>
                      <div class="po_ab_r">
                        上海市信息服务业行业协会会员单位
                      </div>
                    </div>
                    <div class="po_re">
                      <div class="po_ab_l">2022</div>
                      <li data-target="#myCarousel" data-slide-to="7"></li>
                      <div class="po_ab_r">
                        创壹慧眼识才识别软件著作权登记证书
                      </div>
                    </div>
                    <div class="po_re">
                      <div class="po_ab_l">2023</div>
                      <li data-target="#myCarousel" data-slide-to="7"></li>
                      <div class="po_ab_r">软件产品证书</div>
                    </div>
                  </div>
                </div>
                <!-- 轮播（Carousel）项目 -->
                <div class="imgab">
                  <div id="isHover3">
                    <a id="isImg3" class="left carousel-control" href="#myCarousel" role="button" data-slide="prev">
                      <img class="glyphicon glyphicon-chevron-left" aria-hidden="true"
                        src="@/assets/about/aboutlinel.png" alt="" srcset="" />
                    </a>
                  </div>

                  <div class="carousel-inner">
                    <div class="item active">
                      <img src="@/assets/about/honor1.png" alt="First slide" />
                    </div>
                    <div class="item">
                      <img src="@/assets/about/honoy2.png" alt="First slide" />
                    </div>
                    <div class="item">
                      <img src="@/assets/about/honoy3.png" alt="First slide" />
                    </div>
                    <div class="item">
                      <img src="@/assets/about/honoy4.png" alt="First slide" />
                    </div>
                    <div class="item">
                      <img src="@/assets/about/honoy5.png" alt="First slide" />
                    </div>
                    <div class="item">
                      <img src="@/assets/about/honoy6.png" alt="First slide" />
                    </div>
                    <div class="item">
                      <img src="@/assets/about/honoy7.png" alt="First slide" />
                    </div>
                    <div class="item">
                      <img src="@/assets/about/honoy8.png" alt="First slide" />
                    </div>
                    <div class="item">
                      <img src="@/assets/about/honoy9.png" alt="First slide" />
                    </div>
                    <div class="item">
                      <img src="@/assets/about/honoy10.png" alt="First slide" />
                    </div>
                    <div class="item">
                      <img src="@/assets/about/honoy11.png" alt="First slide" />
                    </div>
                    <div class="item">
                      <img src="@/assets/about/honoy12.png" alt="First slide" />
                    </div>
                    <div class="item">
                      <img src="@/assets/about/honoy13.png" alt="First slide" />
                    </div>
                    <div class="item">
                      <img src="@/assets/about/honoy14.png" alt="First slide" />
                    </div>
                    <div class="item">
                      <img src="@/assets/about/honoy15.png" alt="First slide" />
                    </div>
                    <div class="item">
                      <img src="@/assets/about/honoy16.png" alt="First slide" />
                    </div>
                    <div class="item">
                      <img src="@/assets/about/honoy17.png" alt="First slide" />
                    </div>
                  </div>
                  <div id="isHover4">
                    <a id="isImg4" class="right carousel-control" href="#myCarousel" role="button" data-slide="next">
                      <img class="glyphicon glyphicon-chevron-right" aria-hidden="true"
                        src="@/assets/about/aboutliner.png" alt="" srcset="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="about_bg">
        <div class="container about address about_bg">
          <div class="row">
            <div class="company-title">联系我们</div>
            <div class="company-city">
              <div id="shActive" class="sh shActive">
                <div>上海(总部)</div>
              </div>
            </div>
            <!-- 上海 -->
            <div id="shMap" class="isShActive">
              <div class="about_border_us">
                <a class="col-md-6">
                  <div id="container"></div>
                </a>
                <div class="col-md-6">
                  <ul>
                    <li class="about_title">
                      <img src="@/assets/about/about_icon.png" alt="" />
                      <div>上海（总部）</div>
                    </li>
                    <li class="about_top">
                      <p>地址：上海杨浦区军工路100号运河湾知识产权园18栋07室</p>
                      <p>产品咨询热线：400-0055-965</p>
                      <p>客户建议及合作：services@chuangyios.com</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hysc-dia v-if="visibleD" :visible.sync="visibleD" @getClickCancel="getClickClose"></hysc-dia>
  </div>
</template>

<script>
import HyscDia from '../Dialog/dialog';
import AMapLoader from '@amap/amap-jsapi-loader';
import { website } from '@/api/gw.js';

export default {
  name: 'About',
  data() {
    return {
      visibleD: false,
      honorArr: [
        {
          title: '软件产品证书',
          imgH: require('@/assets/about/honor1.png'),
          yearH: '2017',
          keyH: '1',
        },
        {
          title: '创壹大数据分析管理软件著作权登记证书',
          imgH: require('@/assets/about/honoy2.png'),
          yearH: '2017',
          keyH: '2',
        },
        {
          title: '创壹大数据分析管理软件著作权登记证书',
          imgH: require('@/assets/about/honoy3.png'),
          yearH: '2017',
          keyH: '3',
        },
        {
          title: '软件企业证书',
          imgH: require('@/assets/about/honoy4.png'),
          yearH: '2018',
          keyH: '4',
        },
        {
          title: '专业人才技能培训中心',
          imgH: require('@/assets/about/honoy5.png'),
          yearH: '2018',
          keyH: '5',
        },
        {
          title: '创壹报告管理系统软件著作权登记证书',
          imgH: require('@/assets/about/honoy6.png'),
          yearH: '2018',
          keyH: '6',
        },
        {
          title: '高新技术企业证书',
          imgH: require('@/assets/about/honoy7.png'),
          yearH: '2019',
          keyH: '7',
        },
        {
          title: '电脑的图形用户界面专利证书',
          imgH: require('@/assets/about/honoy8.png'),
          yearH: '2019',
          keyH: '8',
        },
        {
          title: '创壹学习能力人工智能著作权登记证书',
          imgH: require('@/assets/about/honoy9.png'),
          yearH: '2020',
          keyH: '9',
        },
        {
          title: '创壹学习能力大数据分析引擎软件著作权登记证书',
          imgH: require('@/assets/about/honoy10.png'),
          yearH: '2020',
          keyH: '10',
        },
        {
          title: '新型视频会议服务器专利证书',
          imgH: require('@/assets/about/honoy11.png'),
          yearH: '2021',
          keyH: '11',
        },
        {
          title: '新型路由器交换机专利证书',
          imgH: require('@/assets/about/honoy12.png'),
          yearH: '2021',
          keyH: '12',
        },
        {
          title: '上海市信息服务业行业协会会员单位',
          imgH: require('@/assets/about/honoy13.png'),
          yearH: '2021',
          keyH: '13',
        },
        {
          title: '创壹慧眼识才识别软件著作权登记证书',
          imgH: require('@/assets/about/honoy14.png'),
          yearH: '2022',
          keyH: '14',
        },
        {
          title: '软件产品证书',
          imgH: require('@/assets/about/honoy15.png'),
          yearH: '2023',
          keyH: '15',
        },
      ],
      activeName: '2017',
      activeName2: '1',
    };
  },
  components: {
    HyscDia,
  },

  mounted() {
    // Create a <script> element
    const script = document.createElement('script');
    script.src = 'https://hysc.creatoneos.com/static/line.js';

    // Append the <script> element to the document body
    document.body.appendChild(script);
    this.initAMap();
    this.initAMapTel();
  },
  methods: {
    // 点击做记录
    getClick(val, type) {
      website({ type: val, name: type }).then((res) => {
        console.log(res, 'rrr');
      });
    },
    initAMapTel() {
      AMapLoader.load({
        key: 'f148246a65836c73e5aab1af4cf083bc', // 申请好的Web端开发者Key，首次调用 load 时必填
        // version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          'AMap.Scale',
          'AMap.ToolBar',
          'AMap.ControlBar',
          'AMap.Geocoder',
          'AMap.Marker',
          'AMap.CitySearch',
          'AMap.Geolocation',
          'AMap.AutoComplete',
          'AMap.InfoWindow',
        ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          var map = new AMap.Map('containerTel', {
            resizeEnable: true,
            zoom: 10,
          });
          var lnglats = [[121.558782, 31.282001]];
          var infoWindow = new AMap.InfoWindow({
            offset: new AMap.Pixel(0, -30),
          });
          function markerClick(e) {
            infoWindow.setContent(e.target.content);
            infoWindow.open(map, e.target.getPosition());
          }
          for (var i = 0; i < lnglats.length; i++) {
            var marker = new AMap.Marker({
              position: lnglats[i],
              map: map,
            });
            marker.content = '<div>运河湾知识产权园18栋</div>';
            marker.on('click', markerClick);
            marker.emit('click', { target: marker });
          }

          map.setFitView();
          var circle = new AMap.Circle({
            center: [121.420636, 31.026329],
            radius: 10,
            strokeColor: '#186FFF',
            // strokeOpacity: 1,
            strokeOpacity: 0.5,
            fillOpacity: 0.2,
            fillColor: '#186FFF',
          });
          circle.setMap(map);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    initAMap() {
      AMapLoader.load({
        key: 'f148246a65836c73e5aab1af4cf083bc', // 申请好的Web端开发者Key，首次调用 load 时必填
        // version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          'AMap.Scale',
          'AMap.ToolBar',
          'AMap.ControlBar',
          'AMap.Geocoder',
          'AMap.Marker',
          'AMap.CitySearch',
          'AMap.Geolocation',
          'AMap.AutoComplete',
          'AMap.InfoWindow',
        ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          var map = new AMap.Map('container', {
            resizeEnable: true,
            zoom: 10,
          });
          var lnglats = [[121.558782, 31.282001]];
          var infoWindow = new AMap.InfoWindow({
            offset: new AMap.Pixel(0, -30),
          });
          function markerClick(e) {
            infoWindow.setContent(e.target.content);
            infoWindow.open(map, e.target.getPosition());
          }
          for (var i = 0; i < lnglats.length; i++) {
            var marker = new AMap.Marker({
              position: lnglats[i],
              map: map,
            })
            marker.content = '<div>运河湾知识产权园18栋</div>'
            marker.on('click', markerClick);
            marker.emit('click', { target: marker })
          }
          // for (var i = 0, marker; i < lnglats.length; i++) {
          //     var marker = new AMap.Marker({
          //         position: lnglats[i],
          //         map: map,
          //     });
          //     marker.content = '<div>云境未来</div>';
          //     marker.on('click', markerClick);
          //     marker.emit('click', { target: marker });
          // }
          // function markerClick (e) {
          //     infoWindow.setContent(e.target.content);
          //     infoWindow.open(map, e.target.getPosition());
          // }
          map.setFitView();
          var circle = new AMap.Circle({
            center: [121.420636, 31.026329],
            radius: 10,
            strokeColor: '#186FFF',
            // strokeOpacity: 1,
            strokeOpacity: 0.5,
            fillOpacity: 0.2,
            fillColor: '#186FFF',
          });
          circle.setMap(map);
          // 获取到作为地图容器的DOM元素，创建地图实例
          // this.map = new AMap.Map("amapcontainer", { //设置地图容器id
          //   resizeEnable: true,
          //   zoom: this.zoom, // 地图显示的缩放级别
          //   viewMode: "3D", // 使用3D视图
          //   zoomEnable: true, // 地图是否可缩放，默认值为true
          //   dragEnable: true, // 地图是否可通过鼠标拖拽平移，默认为true
          //   doubleClickZoom: true, // 地图是否可通过双击鼠标放大地图，默认为true
          //   zoom: 11, //初始化地图级别
          //   center: [113.370824, 23.131265], // 初始化中心点坐标 广州
          //   // mapStyle: "amap://styles/darkblue", // 设置颜色底层
          // })
        }).catch((e) => {
          console.log(e)
        })
    },

    isDialog(val, type) {
      this.getClick(val, type)
      this.visibleD = true
      // this.$router.push('/Purchase')
    },

    getClickClose() {
      this.visibleD = false
    },
  },
};
</script>
<!-- 发展历程 -->

<!-- tel -->
<style scoped>
.pc {
  display: block !important;
}

.tel {
  display: none !important;
}

@media (max-width: 1200px) {
  :deep(.el-tabs__active-bar) {
    display: none;
  }

  :deep(.el-tabs__nav-wrap::after) {
    width: 0 !important;
  }

  :deep(.tel .el-tabs--card > .el-tabs__header .el-tabs__nav) {
    border: none;
  }

  :deep(.el-tabs__nav) {
    border: none;
  }

  :deep(.tel .el-tabs--card > .el-tabs__header) {
    border: none;
  }

  :deep(.tel .el-tabs__item) {
    height: 32px;
    box-sizing: border-box;
    line-height: 32px;
  }

  :deep(.tel .el-tabs__item) {
    margin: 0px 0;
  }

  :deep(.el-tabs--card > .el-tabs__header .el-tabs__item) {
    border: none;
  }

  :deep(.tel .el-tabs__item.is-active) {
    background: linear-gradient(90deg, #52aaff 0%, #186fff 98%) !important;
    color: #fff;
    font-weight: bold;
    border-radius: 40px;
    padding: 0 20px;
  }

  :deep(.el-tabs__nav-wrap span) {
    display: none;
  }

  :deep(.el-tabs__nav-wrap.is-scrollable) {
    padding: 0 0;
  }

  :deep(.el-tabs__nav-scroll) {
    width: 100%;
    overflow-x: scroll;
    background: #f1f8ff;
    border-radius: 40px;
    padding: 6px;
  }

  :deep(.el-tabs__nav-scroll::-webkit-scrollbar) {
    display: none;
  }

  .tel .addres .title {
    margin-bottom: 15px;
  }

  .tel #containerTel {
    width: 500px;
    height: 200px;
    display: block;
  }

  .tel .mapOverflow {
    overflow: hidden;
  }

  .tel .addres .dz .addressInfo {
    width: 48%;
    margin: 15px 0;
  }

  .tel .addres .dz {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .tel .addres .dz .dz_dis {
    display: flex;
    align-items: center;
  }

  .tel .addres .dz .phoneMini {
    word-wrap: break-word;
    /* 支持旧版本的浏览器 */
    overflow-wrap: break-word;
    /* 兼容性更好的新属性 */
  }

  .tel .addres .addressInfo .dz_dis img,
  .tel .addres .dz .dz_dis img {
    width: 15px;
    margin-right: 5px;
  }

  .tel .addres .addressInfo .dz_dis {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
  }

  .tel .addres .addressInfo {
    background: #fff;
    color: #333;
    padding: 10px;
    text-align: center;
    box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.1476);
  }

  .tel .honor_desc .ry_bg .img_bg {
    text-align: center;
    padding: 15px;
    display: flex;
    overflow: hidden;
    align-items: center;
  }

  .tel .honor_desc .ry_bg .img_bg img {
    width: 100%;
  }

  .tel .honor_desc .ry_bg .title {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    padding-top: 20px;
    text-align: center;
  }

  .tel .honor_desc .ry_bg {
    border-radius: 8px;
    box-shadow: 0px 6px 31px 0px rgba(29, 67, 132, 0.373);
    background: linear-gradient(90deg, #34baef 0%, #1e75ff 98%);
  }

  .tel .honor_title,
  .tel .addres .title {
    font-size: 28px;
    color: #4d5565;
    font-weight: 600;
    text-align: center;
    margin-bottom: 15px;
  }

  .tel .honor,
  .tel .addres {
    margin: 15px;
  }

  .tel .yj .yj-dis {
    font-size: 14px;
    margin-left: 10px;
    color: #666;
  }

  .tel .yj .yj-dis .title {
    font-weight: 600;
    text-align: initial;
    font-size: 18px;
    color: #333;
  }

  .tel .yj img {
    width: 40px !important;
  }

  .tel .sm {
    margin: 850px 15px 15px 15px !important;
  }

  .tel .yj {
    position: absolute;
    margin: 750px 15px 15px 15px;
    display: flex;
    align-items: center;
    background: #fff;
    width: 93%;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.1476);
  }

  .tel .mTop .desc {
    margin-top: 15px;
    line-height: 1.8;
    letter-spacing: 0.62px;
    color: #666666;
    padding-bottom: 10px;
  }

  .tel .mTop .line {
    width: 38px;
    height: 6px;
    background: #517bfc;
    margin: 0 auto;
    border-radius: 8px;
  }

  .tel .mTop .title {
    font-size: 28px;
    color: #4d5565;
    font-weight: 600;
    text-align: center;
  }

  .tel .mTop {
    background: #fff;
    margin: 250px 15px 15px 15px;
    padding: 15px;
    position: absolute;
    z-index: 22;
    border-radius: 8px;
    box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.1476);
  }

  .tel .mTop img {
    width: 100%;
  }

  .tel .posiHeight {
    height: 950px;
  }

  .pc {
    display: none !important;
  }

  .tel {
    display: block !important;
  }

  .teld2,
  .teld3 {
    color: #fff;
  }

  .tel .banner {
    background: url(../../assets/telbg5.png) no-repeat;
    background-size: 100%;
    height: 400px;
    position: absolute;
    top: 0px;
    width: 100%;
  }

  .tel .teldesc {
    position: absolute;
    left: 0%;
    right: 50%;
    top: 30%;
    width: 100%;
    text-align: center;
    letter-spacing: 1.2px;
    line-height: 1.5;
    color: #333333;
  }

  .tel .teldesc .teld1 {
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 1.82px;
    color: #FFFFFF;
  }

  .tel .teldesc .teld2,
  .teld3 {
    color: #FFFFFF;
  }

  .teld2 {
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 1.82px;
  }

  .telTop {
    margin-top: 20px;
  }

  .teld3 {
    font-size: 16px;
    padding: 0 30px;
  }
}
</style>
<style scoped>
@media screen and (max-width: 600px) {
  #main {
    height: 400px !important;
  }
}
</style>

<style scoped>
.row {
  margin-right: 0px;
  margin-left: 0px;
}

.container #container {
  height: 300px;
}

.col-md-6 ul {
  margin-left: 20px;
}

:deep(.amap-info-content) {
  background: #186fff;
  padding: 10px 27px;
  box-shadow: 0px 3px 9px 0px rgba(22, 100, 230, 0.66);
}

:deep(.amap-info-content div) {
  font-size: 12px;

  color: white;
}

:deep(.amap-logo) {
  display: none;
  opacity: 0 !important;
}

:deep(.amap-copyright) {
  opacity: 0;
}

:deep(.bottom-center .amap-info-sharp) {
  border-top: 8px solid #186fff;
}

:deep(.amap-info-close) {
  display: none;
}

.banner_po {
  position: relative;
  /* margin-top: 71px; */
}

.banner_po .banner_ab {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #666666;
}

.banner_po .banner_ab .app_title {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333333;
}

.banner_po .banner_ab .app_desc1 {
  font-size: 18px;
  margin-bottom: 10px;
}

.banner_po .banner_ab .reservation {
  width: 170px;
  height: 48px;
  margin: 0 auto;
  line-height: 46px;
  background: #186FFF;
  color: #fff;
  font-size: 18px;
  border-radius: 25px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
}

.about .company-title {
  text-align: center;
  font-size: 33px;
  color: #000;
  font-weight: 500;
}

.about .about_border_us {
  border: 1px solid #dfe2eb;
  padding: 10px;
  display: flex;
}

.about .about-flex {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
}

.about .about-flex .about-flex-top {
  padding: 20px 0px 20px 0px;
  line-height: 34px;
  font-size: 16px;
  color: #666;
  width: 50%;
}

.about .about-flex .imgtext {
  text-align: right;
}

.about .row .about-two {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin: -100px 0 55px 0;
}

.about .row .about-two .about_po {
  width: 40%;
  position: relative;
}

.about .row .about-two .about_po .about-ab {
  position: absolute;
  top: 20%;
  left: 8%;
  color: #fff;
  width: 80%;
  font-size: 16px;
}

.about .row .about-two .about_po .about-ab .about-line {
  border: 1px solid #b7cfff;
  margin: 18px 0;
}

.about .row .about-two .about_po .about-ab .about-line2 {
  border: 1px solid #93e1ff;
  margin: 18px 0;
}

.about .row .about-two .about_po img {
  width: 100%;
}

.about .row .ach-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.about .row .flex-width {
  width: 25%;
  font-size: 18px;
  margin-top: 50px;
  color: #666;
  /* padding-left: 7%; */
}

.about .row .flex-width .flex-color {
  color: #186fff;
  font-weight: 500;
  font-size: 38px;
  display: flex;
}

.about .about_title {
  margin-top: 50px;
  background: inherit;
  color: #101622;
  display: flex;
  align-items: center;
  font-size: 24px;
}

.about .company-city {
  display: flex;
  align-items: center;
  /* border: 1px solid #DFE2EB; */
  margin-bottom: 23px;
  border-radius: 4px 0px 0px 4px;
  margin-top: 60px;
}

.about .company-city .sh {
  width: 114px;
  height: 53px;
  text-align: center;
  justify-content: center;
  font-size: 18px;
  border-radius: 4px 0px 0px 4px;
  display: flex;
  align-items: center;
}

.about .company-city .shActive {
  background: #186fff;
  color: #fff !important;
}

.about .company-city .sy {
  width: 114px;
  height: 53px;
  text-align: center;
  justify-content: center;
  color: #758296;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.about .company-city .sy div {
  height: 25px;
  width: 100%;
  border-right: 1px solid #dfe2eb;
}

.about .company-city .sh div {
  height: 25px;
  width: 100%;
}

.about .col-md-6 {
  float: inherit;
  padding: 0;
}

.about .about_title div {
  margin-left: 10px;
  font-weight: 500;
}

.about_top {
  margin-top: 50px;
}

.address {
  margin-top: 5%;
}

.about_bgcom {
  background: #f9fbff;
  width: 100%;
  padding-bottom: 100px;
}

.honorbg {
  background: #f9fbff;
  width: 100%;
  padding-top: 5%;
  height: 660px;
}

.developmentT {
  margin: 15px;
}

.developmentT .title {
  font-size: 28px;
  color: #4d5565;
  font-weight: 600;
  text-align: center;
  margin-bottom: 15px;
}

.developmentT .deve_bg {
  border-radius: 6px;
  background: linear-gradient(90deg, #80bef4 0%, #4081ff 100%);
  box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.1476);
  /* box-shadow: 0px 0.8px 0.41px 0px rgba(29, 67, 132, 0.37),
    0px 1px 6px 0px #96bbff; */
  padding: 0.52rem 0.23rem 0.25rem 0.23rem;
}

.developmentT .deve_bg .deve_title_bg {
  background: #ffffff;
  border-radius: 4px;
  min-height: 0.81rem;
  margin: 10px 6px;
  display: flex;
  align-items: center;
  padding: 4px;
}

.developmentT .deve_bg .deve_title_bg .dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #96bbff;
  margin-right: 0.3rem;
  margin-left: 0.2rem;
  display: block;
  margin-right: 6px;
}

.honor .title_history {
  color: #333 !important;
  font-weight: 500;
}

.honor .left {
  left: -40px;
  top: 20px;
}

.honor .right {
  right: -40px;
  top: 20px;
}

.noleft {
  width: 18% !important;
}

.isleft {
  width: 14% !important;
  margin-left: 14%;
}

.isleft2 {
  width: 16% !important;
  margin-left: 12%;
}
</style>

<!-- 时间线 -->
<style scoped>
a {
  color: #7b9d6f;
  text-decoration: none;
}

/* -------------------------------- 

Main Components 

-------------------------------- */
.cd-horizontal-timeline {
  opacity: 0;
  margin: 2em auto;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.cd-horizontal-timeline::before {
  /* never visible - this is used in jQuery to check the current MQ */
  content: 'mobile';
  display: none;
}

.cd-horizontal-timeline.loaded {
  /* show the timeline after events position has been set (using JavaScript) */
  opacity: 1;
}

.cd-horizontal-timeline .timeline {
  position: relative;
  height: 100px;
  /* width: 90%; */
  /* max-width: 800px; */
  margin: 0 auto;
}

.cd-horizontal-timeline .events-wrapper {
  position: relative;
  height: 100%;
  /* margin: 0 40px; */
  overflow: hidden;
}

.cd-horizontal-timeline .events-wrapper::after,
.cd-horizontal-timeline .events-wrapper::before {
  /* these are used to create a shadow effect at the sides of the timeline */
  content: '';
  position: absolute;
  z-index: 2;
  top: 0;
  height: 100%;
  width: 20px;
}

/* .cd-horizontal-timeline .events-wrapper::before {
    left: 0;
    background-image: -webkit-linear-gradient(left, #f8f8f8, rgba(248, 248, 248, 0));
    background-image: linear-gradient(to right, #f8f8f8, rgba(248, 248, 248, 0));
} */

/* .cd-horizontal-timeline .events-wrapper::after {
    right: 0;
    background-image: -webkit-linear-gradient(right, #f8f8f8, rgba(248, 248, 248, 0));
    background-image: linear-gradient(to left, #f8f8f8, rgba(248, 248, 248, 0));
} */

.cd-horizontal-timeline .events {
  /* this is the grey line/timeline */
  position: absolute;
  z-index: 1;
  left: 0;
  top: 49px;
  height: 2px;
  /* width will be set using JavaScript */
  /* background: #dfdfdf;
     */
  background: #388aff;
  -webkit-transition: -webkit-transform 0.4s;
  -moz-transition: -moz-transform 0.4s;
  transition: transform 0.4s;
}

.cd-horizontal-timeline .filling-line {
  /* this is used to create the green line filling the timeline */
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -o-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
}

.cd-horizontal-timeline .events ol li a {
  position: absolute;
  bottom: 0;
  z-index: 2;
  text-align: center;
  font-size: 1.3rem;
  padding-bottom: 15px;
  color: #fff;
  /* fix bug on Safari - text flickering while timeline translates */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}

.cd-horizontal-timeline .events a::after {
  /* this is used to create the event spot */
  content: '';
  position: absolute;
  left: 50%;
  right: auto;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -5px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 2px solid #dfdfdf;
  background-color: #f8f8f8;
  -webkit-transition: background-color 0.3s, border-color 0.3s;
  -moz-transition: background-color 0.3s, border-color 0.3s;
  transition: background-color 0.3s, border-color 0.3s;
}

.no-touch .cd-horizontal-timeline .events a:hover::after {
  background-color: #97beff;
  border-color: #388aff;
}

.cd-horizontal-timeline .events a.selected {
  pointer-events: none;
}

.cd-horizontal-timeline .events a.selected::after {
  background-color: #97beff;
  border-color: #388aff;
}

.cd-horizontal-timeline .events a.older-event::after {
  border-color: #388aff;
}

@media only screen and (min-width: 1100px) {
  .cd-horizontal-timeline {
    margin: 2em auto;
  }

  .cd-horizontal-timeline::before {
    /* never visible - this is used in jQuery to check the current MQ */
    content: 'desktop';
  }
}

.cd-timeline-navigation a {
  /* these are the left/right arrows to navigate the timeline */
  position: absolute;
  z-index: 22;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 34px;
  width: 34px;
  border-radius: 50%;
  /* border: 2px solid #dfdfdf; */
  /* replace text with an icon */
  overflow: hidden;
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
  -webkit-transition: border-color 0.3s;
  -moz-transition: border-color 0.3s;
  transition: border-color 0.3s;
}

.cd-timeline-navigation a::after {
  /* arrow icon */
  content: '';
  position: absolute;
  height: 16px;
  width: 16px;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  /* background: url(../img/cd-arrow.svg) no-repeat 0 0; */
}

.cd-timeline-navigation a.prev {
  left: -8px;
  /* -webkit-transform: translateY(-50%) rotate(180deg);
    -moz-transform: translateY(-50%) rotate(180deg);
    -ms-transform: translateY(-50%) rotate(180deg);
    -o-transform: translateY(-50%) rotate(180deg);
    transform: translateY(-50%) rotate(180deg); */
}

.cd-timeline-navigation a.next {
  right: -8px;
}

.cd-timeline-navigation img {
  width: 36px;
  height: 36px;
  display: inherit;
}

.no-touch .cd-timeline-navigation a:hover {
  border-color: #7b9d6f;
}

.cd-timeline-navigation a.inactive {
  cursor: not-allowed;
}

.cd-timeline-navigation a.inactive::after {
  background-position: 0 -16px;
}

.no-touch .cd-timeline-navigation a.inactive:hover {
  border-color: #dfdfdf;
}

.cd-horizontal-timeline .events-content {
  position: relative;
  width: 100%;
  margin: 0 0;
  overflow: hidden;
  -webkit-transition: height 0.4s;
  -moz-transition: height 0.4s;
  transition: height 0.4s;
}

.cd-horizontal-timeline .events-content ol {
  padding-left: 0;
}

.cd-horizontal-timeline .events-content li {
  position: absolute;
  z-index: 1;
  border-radius: 7px;
  /* width: 93%; */
  height: 170px;
  /* left: 2%; */
  top: -2%;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  /* padding: 0 5%; */
  opacity: 0;
  /* -webkit-animation-duration: 0.4s;
    -moz-animation-duration: 0.4s;
    animation-duration: 0.4s; */
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  /* background: #fff; */
  background: url('../../assets/about/historyBg.png') no-repeat;
  background-size: 100% 100%;
}

.cd-horizontal-timeline .events-content li .p1 {
  position: absolute;
  z-index: 1;
  left: 15%;
  top: 25%;
  font-size: 28px;
  color: #1c68fd;
}

.cd-horizontal-timeline .events-content li .p2 {
  position: absolute;
  z-index: 1;
  left: 15%;
  top: 50%;
  font-size: 15px;
  color: #333333;
  font-weight: 500;
}

.cd-horizontal-timeline .events-content li .p3 {
  position: absolute;
  z-index: 1;
  left: 15%;
  top: 65%;
  font-size: 15px;
  color: #333333;
}

.cd-horizontal-timeline .events-content li.selected {
  /* visible event content */
  position: relative;
  z-index: 2;
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

.cd-horizontal-timeline .events-content li.enter-right,
.cd-horizontal-timeline .events-content li.leave-right {
  -webkit-animation-name: cd-enter-right;
  -moz-animation-name: cd-enter-right;
  animation-name: cd-enter-right;
}

.cd-horizontal-timeline .events-content li.enter-left,
.cd-horizontal-timeline .events-content li.leave-left {
  -webkit-animation-name: cd-enter-left;
  -moz-animation-name: cd-enter-left;
  animation-name: cd-enter-left;
}

.cd-horizontal-timeline .events-content li.leave-right,
.cd-horizontal-timeline .events-content li.leave-left {
  -webkit-animation-direction: reverse;
  -moz-animation-direction: reverse;
  animation-direction: reverse;
}

.cd-horizontal-timeline .events-content li>* {
  max-width: 800px;
  margin: 0 auto;
}

.cd-horizontal-timeline .events-content h2 {
  font-weight: bold;
  font-size: 2.6rem;
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  line-height: 1.2;
}

.cd-horizontal-timeline .events-content em {
  display: block;
  font-style: italic;
  margin: 10px auto;
}

.cd-horizontal-timeline .events-content em::before {
  content: '- ';
}

.cd-horizontal-timeline .events-content p {
  font-size: 1.4rem;
  color: #959595;
}

.cd-horizontal-timeline .events-content em,
.cd-horizontal-timeline .events-content p {
  line-height: 1.6;
}

@media only screen and (min-width: 768px) {
  .cd-horizontal-timeline .events-content h2 {
    font-size: 7rem;
  }

  .cd-horizontal-timeline .events-content em {
    font-size: 2rem;
  }

  .cd-horizontal-timeline .events-content p {
    font-size: 1.8rem;
  }
}

@-webkit-keyframes cd-enter-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
  }
}

@-moz-keyframes cd-enter-right {
  0% {
    opacity: 0;
    -moz-transform: translateX(100%);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0%);
  }
}

@keyframes cd-enter-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@-webkit-keyframes cd-enter-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
  }
}

@-moz-keyframes cd-enter-left {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0%);
  }
}

@keyframes cd-enter-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}
</style>
<!-- linetwo -->
<style scoped>
.timeline2 .cd-horizontal-timeline.loaded {
  display: flex;
}

.timeline2 .cd-horizontal-timeline .timeline {
  -webkit-transform: translateY(-50%) rotate(90deg);
  -moz-transform: translateY(-50%) rotate(90deg);
  -ms-transform: translateY(-50%) rotate(90deg);
  -o-transform: translateY(-50%) rotate(90deg);
  transform: translateY(-50%) rotate(90deg);
}

.timeline2 #lr {
  -webkit-transform: translateY(-50%) rotate(0deg);
  -moz-transform: translateY(-50%) rotate(0deg);
  -ms-transform: translateY(-50%) rotate(0deg);
  -o-transform: translateY(-50%) rotate(0deg);
  transform: translateY(-50%) rotate(0deg);
}

.timeline2 .cd-horizontal-timeline .events-wrapper {
  margin: 0px 0px;
}
</style>
<style scoped>
.timeline2 .carousel-indicators li {
  display: inherit;
  /* border-color: red; */
  background: #1c68ff;
  margin-left: -4px;
  margin-bottom: 20px;
}

.timeline2 .carousel-indicators .active {
  background-color: #1c68ff;
  border: 2px solid rgba(28, 104, 255, 0.65);
  margin-left: -7px;
  height: auto;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  font-weight: 500;
}

.timeline2 .carousel-indicators .active li {
  background: inherit;
  display: none;
}

.timeline2 .imgab {
  position: absolute;
  width: 350px;
  height: 260px;
  right: 0;
  top: 20px;
}

.timeline2 .carousel-control {
  z-index: 22;
  background-image: none;
}

.timeline2 .carousel-inner>.item>img {
  width: 350px;
  height: 260px;
}

.timeline2 .carousel-inner>.item {
  -webkit-transition: 0s ease-in-out left;
  -o-transition: 0s ease-in-out left;
  transition: 0s ease-in-out left;
}

.timeline2 .carousel-indicators li:nth-child(1) {
  margin-top: 40px;
}

.timeline2 .carousel-indicators {
  bottom: 20px;
  top: 0px;
  background: linear-gradient(180deg,
      rgba(60, 161, 255, 0) 4%,
      #1c68ff 28%,
      #267aff 75%,
      rgba(47, 138, 255, 0) 100%,
      #1c68ff 100%);
  height: 500px;
  padding: 20px 0;
  width: 2px;
}

.timeline2 .carousel-indicators .po_re {
  position: relative;
}

.timeline2 .carousel-indicators .po_re .po_ab_l {
  position: absolute;
  top: -4px;
  left: -60px;
}

.timeline2 .carousel-indicators .po_re .po_ab_r {
  position: absolute;
  right: -341px;
  top: -4px;
  text-align: left;
  width: 320px;
}

.timeline2 .carousel-indicators .po_re {
  margin-bottom: 20px;
}

.timeline2 .carousel-indicators li {
  border: none;
}

.carousel-indicators .po_re:first-child {
  margin-top: 20px;
}

.po_re_over::-webkit-scrollbar {
  width: 0em;
  /* 宽度 */
}

.po_re_over::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  /* 背景颜色 */
}

.po_re_over::-webkit-scrollbar-thumb {
  background-color: #ccc;
  /* 滑块颜色 */
}

.po_re_over {
  height: 200px;
  overflow: auto;
  position: relative;
}

.advantage {
  padding-top: 0 !important;
}
</style>
<style scoped>
html,
body {
  margin: 0;
  padding: 0;
}

.iw_poi_title {
  color: #cc5522;
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  padding-right: 13px;
  white-space: nowrap;
}

.iw_poi_content {
  font: 12px arial, sans-serif;
  overflow: visible;
  padding-top: 4px;
  white-space: -moz-pre-wrap;
  word-wrap: break-word;
}

.about li {
  padding-left: 10px;
}

.about_top p {
  padding-bottom: 10px;
}

.development {
  width: 100%;
  height: 324px;
  background: #186fff;
}

.history .title_history {
  text-align: center;
  font-size: 33px;
  color: #ffffff;
  margin-top: 58px;
  margin-bottom: 50px;
}
</style>
