<template>
  <el-dialog :modal="true" title="感谢您关注慧眼识才" height="610px" width="93%" :visible.sync="visibleNew" @close="closeClick">
    <div id="dialoag-form" class="center-dialog hideform">
      <div class="form-wrap">
        <div class="form-item-title-b">版本选择</div>
        <div class="form-padding">
          <div class="version-change" @touchstart="handleTouchStart" @touchmove="handleTouchMove"
            @touchend="handleTouchEnd">

            <div class="version-item version-right">
              <label ref="anotherBox2" @click="getVersion(98)"
                :class="{ 'slide-right': isRight, 'slide-left': isLeft }">
                <input type="radio" name="version" id="version" :value="version" class="hide required" />
                <div class="version-top">
                  <div class="left">
                    <img class="version-img" src="@/assets/purchase/newVersion/ver98.png" alt="" />
                    <div>智能管理版</div>
                  </div>
                  <div class="right">
                    <span style="color: #004BA8;">￥</span>
                    <b style="color: #004BA8;">3,980</b>
                    <span style="color: #004BA8;">/年</span>
                  </div>
                </div>
                <div class="version-middle">
                  <div>·<span style="color: #004BA8;">智能管理版</span>为特别版本</div>
                  <div>·人员组织管理，提高效能</div>
                  <div>·可持续发展的合规风险监测服务</div>
                </div>
                <div class="version-bottom">企业员工规模≤30（支持扩展）</div>
              </label>
            </div>

            <div class="version-item version-right">
              <label ref="anotherBox2" @click="getVersion(97)"
                :class="{ 'slide-right': isRight, 'slide-left': isLeft }">
                <input type="radio" name="version" id="version" :value="version" class="hide required" />
                <div class="version-top">
                  <div class="left">
                    <img class="version-img" src="@/assets/purchase/newVersion/ver97.png" alt="" />
                    <div>智慧人事版</div>
                  </div>
                  <div class="right">
                    <span style="color: #82BB85;">￥</span>
                    <b style="color: #82BB85;">3,980</b>
                    <span style="color: #82BB85;">/年</span>
                  </div>
                </div>
                <div class="version-middle">
                  <div>·<span style="color: #82BB85;">智慧人事版</span>为特别版本</div>
                  <div>·员工全生命周期管理</div>
                  <div>·人才盘点，提升团队效能</div>
                </div>
                <div class="version-bottom">企业员工规模≤50（支持扩展）</div>
              </label>
            </div>

            <div class="version-item version-right">
              <label ref="anotherBox2" @click="getVersion(89)"
                :class="{ 'slide-right': isRight, 'slide-left': isLeft }">
                <input type="radio" name="version" id="version" :value="version" class="hide required" />
                <div class="version-top">
                  <div class="left">
                    <img class="version-img" src="@/assets/purchase/start-type.png" alt="" />
                    <div>启航版</div>
                  </div>
                  <div class="right">
                    <span style="color: #25ADFF;">￥</span>
                    <b style="color: #25ADFF;">29,800</b>
                    <span style="color: #25ADFF;">/年</span>
                  </div>
                </div>
                <div class="version-middle">
                  <div>·打通招聘网站，多渠道简历聚合</div>
                  <div>·员工全生命周期管理</div>
                  <div>·人员组织管理，提高效能</div>
                </div>
                <div class="version-bottom">100≥企业员工规模≥10</div>
              </label>
            </div>

            <div class="version-item version-right">
              <label ref="anotherBox2" @click="getVersion(90)"
                :class="{ 'slide-right': isRight, 'slide-left': isLeft }">
                <input type="radio" name="version" id="version" :value="version" checked class="hide required" />
                <div class="version-top">
                  <div class="left">
                    <img class="version-img" src="@/assets/purchase/lead-type.png" alt="" />
                    <div>领航版</div>
                  </div>
                  <div class="right">
                    <span style="color: #5B65FA;">￥</span>
                    <b style="color: #5B65FA;">58,000</b>
                    <span style="color: #5B65FA;">/年</span>
                  </div>
                </div>
                <div class="version-middle">
                  <div>·<span style="color: #5B65FA;">领航版</span>包含<span style="color: #25ADFF;">启航版</span>全部功能</div>
                  <div>·人才盘点，提升团队效能</div>
                  <div>·可持续发展的合规风险监测服务</div>
                </div>
                <div class="version-bottom">100≥企业员工规模≥10</div>
              </label>
            </div>

            <div class="version-item">
              <label ref="anotherBox" @click="getVersion(91)" :class="{ 'slide-right': isRight, 'slide-left': isLeft }">
                <input type="radio" name="version" id="version" :value="version" class="hide required" />
                <div class="version-top">
                  <div class="left">
                    <img class="version-img scale-little" src="@/assets/purchase/enterprise-img.png" alt="" />
                    <div>旗舰版</div>
                  </div>
                  <div class="right">
                    <span style="color: #FFA93A;">￥</span>
                    <b style="color: #FFA93A;">168,000</b>
                    <span style="color: #FFA93A;">/年</span>
                  </div>
                </div>
                <div class="version-middle">
                  <div>·<span style="color:  #FFA93A;">旗舰版</span>包含<span style="color: #5B65FA;">领航版</span>全部功能</div>
                  <div>·多场景生成式AI模型调用服务</div>
                  <div>·企业战略管理：商业画布、经营分析...</div>
                </div>
                <div class="version-bottom">企业员工规模≥100名，管理员≥5名</div>
              </label>
            </div>

            <div class="version-item version-right">
              <label ref="anotherBox2" @click="getVersion(95)"
                :class="{ 'slide-right': isRight, 'slide-left': isLeft }">
                <input type="radio" name="version" id="version" :value="version" class="hide required" />
                <div class="version-top">
                  <div class="left">
                    <img class="version-img" src="@/assets/purchase/newVersion/ver95.png" alt="" />
                    <div>合规经营版</div>
                  </div>
                  <div class="right">
                    <span style="color: #5BC0EB;">￥</span>
                    <b style="color: #5BC0EB;">998</b>
                    <span style="color: #5BC0EB;">/年</span>
                  </div>
                </div>
                <div class="version-middle">
                  <div>·<span style="color: #5BC0EB;">合规经营版</span>为特别版本</div>
                  <div>·企业人力成本支出把控</div>
                  <div>·可持续发展的合规风险监测服务</div>
                </div>
                <div class="version-bottom">企业员工规模≤30（支持扩展）</div>
              </label>
            </div>


            <div class="version-item version-right">
              <label ref="anotherBox2" @click="getVersion(96)"
                :class="{ 'slide-right': isRight, 'slide-left': isLeft }">
                <input type="radio" name="version" id="version" :value="version" class="hide required" />
                <div class="version-top">
                  <div class="left">
                    <img class="version-img" src="@/assets/purchase/newVersion/ver96.png" alt="" />
                    <div>智慧招聘版</div>
                  </div>
                  <div class="right">
                    <span style="color: #386641;">￥</span>
                    <b style="color: #386641;">998</b>
                    <span style="color: #386641;">/年</span>
                  </div>
                </div>
                <div class="version-middle">
                  <div>·<span style="color: #386641;">智慧招聘版</span>为特别版本</div>
                  <div>·打通招聘网站，多渠道简历聚合</div>
                  <div>·企业招聘全流程一键式管理</div>
                </div>
                <div class="version-bottom">企业员工规模≤30（支持扩展）</div>
              </label>
            </div>
          </div>
        </div>

        <el-form :model="form" :rules="rules">
          <div class="register-wrap">
            <div class="form-item-title">注册信息</div>
            <div class="one flex-content
            ">
              <el-form-item label="版本选择" prop="version" :label-width="formLabelWidth">
                <el-select v-model="form.version" placeholder="请选择版本">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="one">
              <el-form-item label="姓名" prop="name" :label-width="formLabelWidth">
                <el-input placeholder="请输入您的姓名" v-model="form.name" autocomplete="off"></el-input>
              </el-form-item>
            </div>
            <div class="two">
              <el-form-item label="手机号" prop="phone" :label-width="formLabelWidth">
                <el-input placeholder="请输入您的手机号" v-model="form.phone" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="验证码" prop="code" :label-width="formLabelWidth">
                <el-input placeholder="请输入您的验证码" v-model="form.code" autocomplete="off">
                  <template v-slot:append v-if="!isCodeM">
                    <span class="getNum" @click="handleGetCode()">获取验证码</span>
                  </template>
                  <template v-slot:append v-else>
                    <span class="codeNum">{{ codeM }}秒</span>
                  </template>
                </el-input>
              </el-form-item>
            </div>
            <div class="one">
              <el-form-item label="企业名称" prop="company" :label-width="formLabelWidth">
                <el-input placeholder="请输入企业的全称" v-model="form.company" autocomplete="off"></el-input>
              </el-form-item>
            </div>
            <div class="two">
              <el-form-item label="职位" :label-width="formLabelWidth">
                <el-input placeholder="请输入您的职位" v-model="form.postType" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="工作邮箱" :label-width="formLabelWidth">
                <el-input placeholder="请输入您的工作邮箱" v-model="form.email" autocomplete="off"></el-input>
              </el-form-item>
            </div>
          </div>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button class="footer-button" type="primary" @click="putForm()">提交
          </el-button>
          <div class="footer-yonghu">
            提交表单视为您已同意
            <span @click="goYongHu()"><a href="">《用户协议与隐私政策》</a></span>
          </div>
        </div>
        <div class="codeH" v-if="codeH">
          <slide-verify :l="42" :r="10" :w="310" :h="155" slider-text="向右滑动" @success="onSuccess" @fail="onFail"
            @refresh="onRefresh"></slide-verify>
          <div>{{ msg }}</div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { postForm, websiteCode } from '@/api/gw.js';
export default {
  props: {
    // 带有title 的弹窗
    visible: {
      type: Boolean, // 接收的类型为数值
      defaule: false, // 默认值
      require: true, // 属性必须传递
    },
  },
  data() {
    return {
      isTouching: false, // 是否正在触摸
      startX: 0, // 触摸开始的X坐标
      startY: 0, // 触摸开始的Y坐标
      moveX: 0, // 触摸移动的X距离
      moveY: 0, // 触摸移动的Y距离
      threshold: 50, // 触发滑动的阈值
      isAct: true,
      isRight: false,
      isLeft: false,
      codeM: 60,
      isCodeM: false,
      codeH: false,
      msg: '',
      // dialogFormVisible: true,
      form: {
        name: '',
        phone: '',
        company: '',
        postType: '',
        email: '',
        version: '90',
        code: '',
      },
      version: '90',
      options: [
        { label: '启航版', value: '89' },
        { label: '领航版', value: '90' },
        { label: '旗舰版', value: '91' },
        { label: '智慧人事版', value: '97' },
        { label: '智能管理版', value: '98' },
        { label: '合规经营版', value: '95' },
        { label: '智慧招聘版', value: '96' }
      ],
      rules: {
        version: [{ required: true, message: '请选择版本', trigger: 'blur' }],
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入联系电话', trigger: 'blur' }],
        company: [
          { required: true, message: '请输入企业名称', trigger: 'blur' },
        ],
      },
      formLabelWidth: '120px',
      visibleNew: this.visible,
      versionList: [
        {
          icon: require('@/assets/purchase/basic-img.png'),
          title: '基础班',
          bg: '',
          bgTitle: '免费',
          desc: [
            {
              d: '·打通招聘网站，多渠道简历聚合',
            },
            {
              d: '·员工全生命周期管理',
            },
            {
              d: '·人员组织管理，提高效能',
            },
          ],
          footerT: '适用灵活高效的小团队（20人以下）',
        },
        {
          icon: require('@/assets/purchase/enterprise-img.png'),
          title: '企业版',
          bg: '',
          bgTitle: '免费',
          desc: [
            {
              d: '·管理者帐号+2个HR帐号',
            },
            {
              d: '·可持续发展的合规风险监测服务',
            },
            {
              d: '·尊享基础版所有特权',
            },
          ],
          footerT: '适用扩张中的创业公司（20人及以上）',
        },
      ],
      autoplay: false,
      startPoint: 0,
      stopPoint: 0,
    };
  },
  mounted() { },
  methods: {
    // 触摸开始事件处理程序
    handleTouchStart(event) {
      this.isTouching = true;

      this.startX = event.touches[0].clientX;
      this.startY = event.touches[0].clientY;
    },
    // 触摸移动事件处理程序
    handleTouchMove(event) {
      if (!this.isTouching) return;
      this.moveX = event.touches[0].clientX - this.startX;
      this.moveY = event.touches[0].clientY - this.startY;
    },
    // 触摸结束事件处理程序
    handleTouchEnd() {
      if (!this.isTouching) return;
      this.isTouching = false;
      // 横向移动距离大于阈值，且横向移动距离大于纵向移动距离时，认为是横向滑动
      if (
        Math.abs(this.moveX) > this.threshold &&
        Math.abs(this.moveX) > Math.abs(this.moveY)
      ) {
        if (this.moveX > 0) {
          // 向右滑动
          this.isRight = true;
          this.isLeft = false;
          this.getBox2(1);
        } else {
          // 向左滑动
          this.isRight = false;
          this.isLeft = true;
          this.getBox2(2);
        }
      }
      // 重置移动距离
      this.moveX = 0;
      this.moveY = 0;
    },
    goYongHu() {
      window.open('https://hysc.creatoneos.com/privacy/index.html', '_blank');
    },
    getCode() {
      websiteCode({ phone: this.form.phone }).then((res) => {
        console.log(res, 'rrr');
      });
    },
    getCodeM() {
      if (!this.isRunning) {
        this.isRunning = true;
        const timerId = setInterval(() => {
          if (this.codeM <= 1) {
            clearInterval(timerId);
            this.reset();
          } else {
            this.codeM--;
          }
        }, 1000);
      }
    },
    reset() {
      this.isCodeM = false;
      this.codeM = 60;
    },
    handleGetCode() {
      console.log(this.form.phone, 'this.form.phone');
      if (this.form.phone) {
        this.codeH = true;
      } else {
        this.$message({
          showClose: true,
          message: '请输入手机号',
          type: 'error',
        });
      }
    },
    onSuccess() {
      this.codeH = false;
      this.isCodeM = true;
      this.getCodeM();
      this.getCode();
    },
    onFail() {
      this.onRefresh();
      this.msg = '';
    },
    onRefresh() {
      this.msg = '';
    },
    getVersion(val) {
      this.version = String(val)
      this.form.version = this.version
      // if (Number(val) == ) {
      //   this.isRight = true;
      //   this.isLeft = false;
      // } else {
      //   this.isLeft = true;
      //   this.isRight = false;
      // }
      // if (this.isRight) {
      //   this.isRight = false;
      // } else {
      //   this.isRight = true;
      // }
      // this.isLeft = !this.isRight;
      // this.version = val;
      // if (val == 1) {
      //   this.isAct = true;
      //   // this.$refs.anotherBox.click();
      // } else {
      //   this.isAct = false;
      //   // this.$refs.anotherBox2.click();
      // }
    },
    getBox(val) {
      this.getVersion(val);
      if (val == 1) {
        const versionItems = document.querySelectorAll('.version-change');
        console.log(versionItems, 'elements');
        if (versionItems) {
          versionItems.forEach((item) => {
            item.style.left = '0px';
          });
        }
        this.$refs.anotherBox2.click();
      } else {
        const versionItems = document.querySelectorAll('.version-change');
        console.log(versionItems, 'elements');
        if (versionItems) {
          versionItems.forEach((item) => {
            item.style.left = '-230px';
          });
        }
        this.$refs.anotherBox.click();
      }
    },
    getBox2(val) {
      this.getVersion(val);
      if (val == 1) {
        this.$refs.anotherBox2.click();
      } else {
        this.$refs.anotherBox.click();
      }
    },
    putForm() {
      this.form.version = String(this.version);
      postForm(this.form).then((res) => {
        if (res.code != 200) {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error',
          });
        }
      });
    },
    close() {
      // this.$emit('getClickCancel');
    },
    closeClick() {
      this.$emit('getClickCancel');
    },
  },
  watch: {},
};
</script>

<style scoped>
@media (max-width: 1200px) {
  :deep(.form-wrap .form-padding) {
    padding: 3px 20px 15px 20px !important;
  }
}

:deep(.flex-content .el-form-item__content) {
  flex-grow: 1;
}

.el-select {
  width: 100% !important;
}

:deep(.el-input__icon) {
  line-height: 30px !important;
}

.codeH {
  left: 11%;
}

:deep(.el-form .register-wrap) {
  background: initial;
}

:deep(.isActC) {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
}

:deep(.isActC .active) {
  width: 38px;
  height: 14px;
  border-radius: 79px;
  opacity: 1;
  margin-right: 10px;
  margin-left: 10px;
  background: #186fff;
}

:deep(.isActC .noActive) {
  width: 14px;
  height: 14px;
  border-radius: 79px;
  opacity: 1;

  background: #90baff;
}

:deep(.el-dialog__body) {
  overflow: hidden;
}

:deep(.version-change) {
  max-width: 585px;
  width: 100%;
  position: relative;
}

/* form */
:deep(.el-form-item__error) {
  padding-top: 0px;
  top: 80%;
  display: none;
}

:deep(.el-dialog__body) {
  padding: 0;
}

:deep(.el-dialog) {
  border-radius: 13px;
}

:deep(.el-form-item__label) {
  float: inherit;
}

:deep(.el-form-item__content) {
  margin-left: initial !important;
}

.two {
  display: block;
}

.slide-right {
  transform: translateX(0px);
}

.slide-left {
  /* transform: translateX(-230px); */
}

:deep(.name .el-input__inner) {
  width: 50%;
}

:deep(.two .el-input-group__append) {
  border: none;
  padding: 0 5px;
  background: initial;
}

:deep(.two .el-input-group__append span) {
  color: #186fff;
  font-weight: 500;
}

.getNum {
  position: absolute;
  top: 7px;
  right: -55px;
  font-size: 12px;
}

.codeNum {
  color: #333 !important;
  position: absolute;
  top: 7px;
  font-size: 13px;
  right: 0px;
}

:deep(.el-input__inner:focus) {
  border: none;
  box-shadow: initial;
}

.form-padding::-webkit-scrollbar {
  display: none;
}

:deep(.el-form-item) {
  margin-bottom: initial;
  padding: 8px 0;
}

:deep(.el-form-item__label) {
  line-height: 18px;
  font-size: 13px;
  color: #666;
  font-weight: 400;
  text-align: inherit;
  padding: 0;
  margin-bottom: 0;
}

:deep(.el-input__inner) {
  height: 32px;
  line-height: 32px;
}

:deep(.el-input__inner::-webkit-input-placeholder) {
  font-size: 12px;
}

.label {
  margin-bottom: initial;
}

:deep(.two .el-input) {
  width: 90% !important;
}

:deep(.two .el-form-item) {
  width: 50%;
}

:deep(.one .el-input) {
  width: 95% !important;
}

:deep(.el-form-item) {
  width: 100% !important;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
}

:deep(.el-input__inner) {
  border: initial;
}

:deep(.el-form-item__content) {
  line-height: 0;
}

:deep(.router-link-exact-active) {
  background: #fff;
  color: #2074ff;
  border-bottom: solid #2074ff 2px;
}

:deep(.router-link-active :focus) {
  background-color: initial;
}

:deep(.center-dialog .dialog-footer) {
  padding: 25px 23px;
  display: block;
  text-align: center;
}

.form-wrap .form-padding {
  padding: 15px 20px;
  overflow: hidden;
  overflow-x: scroll;
}

.form-wrap .form-item-title-b {
  padding: 0px 20px;
  color: #222a41;
  font-size: 14px;
  font-weight: bold;
}

.footer-button {
  width: 98%;
  background: #e0e5f0;
  font-size: 16px;
  text-align: center;
  color: #8994aa;
  border: initial;
}

.footer-yonghu {
  width: 100%;
  text-align: center;
  color: #979797;
  font-size: 12px;
  margin-top: 20px;
}

.footer-yonghu a {
  color: #1c70f1;
}
</style>
