import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
//Swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'
// import style (>= Swiper 6.x)

// import 'swiper/swiper-bundle.css'
// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css'
//百度地图
import BMap from 'vue-baidu-map'
//引入echarts地图
import echarts from 'echarts'


// 引入组件
import Index from './components/Index/Index'
import Application from './components/Application/application'
import Customer from './components/Customer/customer'
import Purchase from './components/Purchase/purchase'
import About from './components/About/About'
// 滑块验证
import SlideVerify from 'vue-monoplasty-slide-verify';

Vue.use(SlideVerify);
Vue.use(Router)
Vue.use(Element)
Vue.use(BMap, {
  ak: 'MCCI9HFLMRiP8SW7DI0CWOuuRyfUSjoH'
})

Vue.prototype.$echarts = echarts
Vue.use(echarts)
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)


const routes = [
  { path: '/', component: Index },
  { path: '/application', component: Application },
  { path: '/customer', component: Customer },
  { path: '/purchase', component: Purchase },
  { path: '/About', component: About },
]

const router = new Router({
  routes,
  mode: 'history'
})

router.afterEach(() => {
  window.scrollTo(0, 0)
})

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
